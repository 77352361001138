export const environment = {
  appVersion: '2.0.0',
  production: false,
  region: 'us-east-1',
  userPoolId: 'us-east-1_9G6fsjaf8',
  userPoolWebClientId: '5gu37onhkhu47duggqln0hg2f2',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  gwApiUri: '/api/v1',
  legacyUri: 'https://steppay.com/esvc/partner/tranapi.cfm',
  domain: 'partner.staging.dev.steppay.com',
}

// This is for "real" production.
// export const environment = {
//   production: true,
//   region: 'us-east-1',
//   userPoolId: 'us-east-1_SyWpQQbIL',
//   userPoolWebClientId: '3g9h3ohshtlpqghqrcfm07ko41',
//   authenticationFlowType: 'USER_PASSWORD_AUTH',
//   gwApiUri: '/api/v1',
// }