<!--<a *ngIf="session.roles['superuser']" routerLink="/crm" closeSidebar class="btn btn-outline-secondary btn-block mt-2"><i class="fas fa-home pr-3"></i>STEPpay Home</a>-->
<!--<a *ngIf="session.roles['merchant']" routerLink="/merchant" closeSidebar class="btn btn-outline-secondary btn-block mt-2"><i class="fas fa-home pr-3"></i>STEPpay Home</a>-->
<a *ngIf="session.roles['superuser']" routerLink="/crm" class="btn btn-outline-secondary btn-block mt-2"><i class="fas fa-home pr-3"></i>Home</a>
<a *ngIf="session.roles['merchant']" routerLink="/merchant" class="btn btn-outline-secondary btn-block mt-2"><i class="fas fa-home pr-3"></i>Home</a>

<app-superuser-menu *ngIf="session.roles['superuser']" [session]="session"></app-superuser-menu>  
<app-affiliate-menu *ngIf="session.roles['affiliate']" [session]="session"></app-affiliate-menu>
<app-merchant-menu *ngIf="session.roles['merchant']" [session]="session"></app-merchant-menu>

<div class="card mt-2">
  <ul class="list-group list-group-flush">
    <a href="https://steppay.com/" class="list-group-item list-group-item-dark list-group-item-action" target="_blank" rel="noopener">
      <i class="fas fa-globe pr-2"></i>
      Return to STEPpay.com
    </a>
  </ul>
</div>

<a href="mailto:support@coalesceservices.com?subject=Requesting help with the STEPpay Partner Portal" class="btn btn-outline-secondary btn-block mt-2"><i class="fas fa-envelope pr-3"></i>Help</a>
