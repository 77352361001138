<ng-container [ngSwitch]="resourceAlias">
  <app-user-form #userForm
    *ngSwitchCase="'user'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-user-form>
    
  <app-superuser-form #superuserForm
    *ngSwitchCase="'superuser'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-superuser-form>
  
  <app-affiliate-form #affiliateForm
    *ngSwitchCase="'affiliate'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-affiliate-form>
  
  <app-merchant-form #merchantForm
    *ngSwitchCase="'merchant'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-merchant-form>
  
  <app-route-form #routeForm
    *ngSwitchCase="'route'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-route-form>
  
  <app-office-form #officeForm
    *ngSwitchCase="'office'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-office-form>
  
  <app-mid-group-form #midGroupForm
    *ngSwitchCase="'mid-group'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-mid-group-form>
  
  <app-mid-form #midForm
    *ngSwitchCase="'mid'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-mid-form>
  
  <app-payment-type-form #paymentTypeForm
    *ngSwitchCase="'payment-type'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-payment-type-form>
  
  <app-status-form #statusForm
    *ngSwitchCase="'status'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-status-form>
  
  <app-agency-form #agencyForm
    *ngSwitchCase="'agency'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-agency-form>
  
  <app-processor-form #processorForm
    *ngSwitchCase="'processor'"
    [formGroup]="formGroup" [roles]="roles" [fields]="fields">
  </app-processor-form>
  
  <ng-template ngSwitchDefault>
    <div>Requested resource type, {{ resourceAlias }}, was not recognized.</div>
  </ng-template>
  
</ng-container>