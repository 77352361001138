import { Component, OnInit, Input } from '@angular/core'

import { SessionStateModel } from '@app/ngxs'

@Component({
  selector: 'app-affiliate-menu',
  templateUrl: './affiliate-menu.component.html',
  styleUrls: ['./affiliate-menu.component.css']
})
export class AffiliateMenuComponent implements OnInit {
  @Input()
  session: SessionStateModel

  constructor() { }

  ngOnInit() {
  }

}
