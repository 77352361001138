<ng-template #loader>

  <div class="ph-item">
    <div class="ph-col-12"></div>
  </div>

</ng-template>

<ng-template [ngIf]="config" [ngIfElse]="loader">
  <ng-container [formGroup]="group">
    
    <div class="form-group form-row mb-0" formArrayName="{{ config.name + 'Meta' }}">
      <div class="col-sm-3 text-sm-right">
        <label class="col-form-label" [for]="config.name">{{ config.label }}</label>
      </div>
      <div class="col-sm-9">
        <div class="d-flex">
          <input
            [ngClass]="(config?.options?.readonly) ? 'form-control-plaintext' : 'form-control'"
            type="password"
            [id]="config.name"
            [name]="config.name"
            formControlName="0"
            [placeholder]="config.placeholder"
            [readonly]="config?.options?.readonly">
          <button
            type="button"
            class="btn ml-1"
            placement="right"
            ngbPopover="{{ config.helpText }}">
            <i class="fas fa-question-circle"></i>
          </button>
        </div>
        <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="controlA.status !== 'INVALID'">
          <app-error-messages *ngIf="controlA.errors" [errors]="controlA.errors"></app-error-messages>
          &nbsp;
        </small>
      </div>
    </div>
    <div class="form-group form-row mb-0" formArrayName="{{ config.name + 'Meta' }}">
      <div class="col-sm-3 text-sm-right">
        <label class="col-form-label" [for]="config.name + 'Confirmation'">Confirm</label>
      </div>
      <div class="col-sm-9">
        <div class="d-flex">
          <input
            [ngClass]="(config?.options?.readonly) ? 'form-control-plaintext' : 'form-control'"
            type="password"
            [id]="config.name + 'Confirmation'"
            [name]="config.name + 'Confirmation'"
            formControlName="1"
            [placeholder]="'Confirm'"
            [readonly]="config?.options?.readonly">
          <div
            class="btn ml-1 invisible"
            placement="right"
            ngbPopover="{{ config.helpText }}">
            <i class="fas fa-question-circle"></i>
          </div>
        </div>
        <small id="{{config.name}}ConfirmHelpBlock" class="form-text text-danger" [class.invisible]="controlArray.status !== 'INVALID'">
          <app-error-messages *ngIf="controlArray.errors" [errors]="controlArray.errors"></app-error-messages>
          &nbsp;
        </small>
      </div>
    </div>
    
  </ng-container>
</ng-template>