import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle-text',
  templateUrl: './toggle-text.component.html',
  styleUrls: ['./toggle-text.component.css']
})
export class ToggleTextComponent implements OnInit {
  config;
  group: FormGroup;
  toggleVal: boolean;

  constructor() { }

  ngOnInit() {
    this.toggleVal = this.config.toggleValue;
    this.group.addControl(this.config.toggleName, new FormControl(this.toggleVal));
    this.group.addControl(this.config.textName, new FormControl({value: this.config.textValue, disabled: !this.toggleVal}, this.config.validators));
  }

  toggle(event: boolean): void {
    this.toggleVal = event;
    this.group.controls[this.config.textName][this.toggleVal ? 'enable' : 'disable']();
    this.group.patchValue({[this.config.toggleName]: event});
  }
  
  public reset() {
    this.toggleVal = this.config.toggleValue;
    this.group.patchValue({[this.config.textName]: this.config.textValue});
    this.group.controls[this.config.textName][this.toggleVal ? 'enable' : 'disable']();
  }
}
