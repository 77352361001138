import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.scss']
})
export class ErrorMessagesComponent {
  @Input()
  errors: any = {}
  
  symbol: string = '/[\\^\\$\\*\\.\\[\\]\\{\\}\\(\\)\\?\\-\\\"\\!\\@\\#\\%\\&\\/\\\\\\,\\>\\<\\\'\\:\\;\\|\\_\\~\\`]+/'
  cognito_username: string = '/^[A-Za-z0-9\\-_]+$/'

  constructor() { }
}
