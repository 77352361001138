<ng-template [ngIf]="_show">
  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text bg-info text-light border-success w-100">Username</label>
    </div>
    <input #usernameControl class="form-control" type="text" placeholder="Username" [(ngModel)]="username"/>
  </div>
  
  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text bg-info text-light border-success w-100">Password</label>
    </div>
    <input class="form-control" type="password" placeholder="Password" [(ngModel)]="password" autocomplete="off">
  </div>
  
  <div class="d-flex justify-content-center">
    <button type="submit" class="btn btn-success" (click)="onSignIn()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Sign In
    </button>
  </div>
  
  <!--<div class="d-flex justify-content-center">-->
  <!--  <button type="button" (click)="onForgotPassword()" class="btn">Forgot Password</button>-->
  <!--</div>-->
  
  <div class="alert alert-warning mt-2" [class.invisible]="!errorMessage">{{ errorMessage }}</div>
</ng-template>