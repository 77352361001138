import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  @ViewChildren('usernameControl, passwordControl, passwordForceChangeControl, serviceAccountControl, fullNameControl, emailControl, phonesControl, tierControl, clientIdControl, branchIdControl, reportOnlyControl, ipLockExemptControl, isAdminControl, allClientControl, allBranchControl, allowSbpsControl, allowUserControl, allowClientControl, allowBranchControl, allowTranControl, allowVoidControl, allowCreditControl, allowReverseControl, allowRetryControl, allowCopyControl, allowPlanControl, allowPlanTerminateControl, supOvrPinControl, extMerchClientControl, affiliateIdControl')
  controls: QueryList<any>
  
  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }
  
  public reset() {
    this.controls.forEach( control => control.reset() )
  }

}
