import { Component, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core'
// import { AmplifyService } from 'aws-amplify-angular'
import { Store } from '@ngxs/store'
import { CognitoCompleteNewPassword, CognitoRequireSignIn, CognitoSetError } from '@app/ngxs'

import { takeUntil, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';


// import Auth from '@aws-amplify/auth'
// import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'

@Component({
  selector: 'auth-require-new-password',
  templateUrl: './require-new-password.component.html'
})
export class RequireNewPasswordComponent {
  @ViewChild('password', { static: false })
  passwordControl: ElementRef
  
  _authState: any
  _show: boolean
  password: string
  errorMessage: string
  
  error$: Subscription
  
  destroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private store: Store,
    // private cognitoAuth: CognitoAuthService
    // private amplifyService: AmplifyService
  ) {
    this.error$ = this.store.select(state => state.session.auth)
      .pipe(
        distinctUntilChanged(),
        filter(s => s),
        takeUntil(this.destroyed$),
      )
      .subscribe(authState => {
        this.errorMessage = authState.errorMessage
      })
    
  }
  
  AfterViewInit() {
    this.passwordControl.nativeElement.focus()
  }
  
  @Input()
  set authState(authState: any) { // AuthState
    this._authState = authState
    this._show = authState.state === 'requireNewPassword'
  }

  setPassword(password: string) {
    this.password = password
  }

  onSubmit() {
    const { user } = this._authState
    this.store.dispatch(new CognitoCompleteNewPassword(user, this.password))
    
    
    // const { requiredAttributes } = user.challengeParam;
  
    // Auth.completeNewPassword(user, this.password, requiredAttributes)
    //   .then(() => {
    //     this.cognitoAuth.setAuthState({ state: 'signIn', user: user })
    //   })
    //   .catch(err => this._setError(err))
    
    // this.amplifyService.auth()
    //   .completeNewPassword(
    //     user,
    //     this.password,
    //     requiredAttributes
    //   )
    //   .then(() => {
    //     this.amplifyService.setAuthState({ state: 'signIn', user: user });
    //   })
    //   .catch(err => this._setError(err));
  }

  onSignIn() {
    this.store.dispatch(new CognitoRequireSignIn(null))
    // this.amplifyService.setAuthState({ state: 'signIn', user: null });
    
    // this.cognitoAuth.setAuthState({ state: 'signIn', user: null })
  }

  _setError(err) {
    this.store.dispatch(new CognitoSetError(err))
  }
}
