/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./about.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_AboutComponent = [i0.styles];
var RenderType_AboutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
export function View_AboutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header bg-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title text-capitalize text-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-info-circle text-light mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["About This Application"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close About Modal"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 13, "div", [["class", "modal-body pb-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Version "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "assets/CHANGELOG.md"], ["target", "_blank"], ["title", "view application changelog"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3rd Party Licenses "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["href", "/3rdpartylicenses.txt"], ["target", "_blank"], ["title", "3rd party licenses"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9 2010-2019 Coalesce Holdings, LLC or its subsidiaries. All rights reserved."])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 13, 0, currVal_0); }); }
export function View_AboutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i1.ɵdid(1, 114688, null, 0, i2.AboutComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AboutComponentNgFactory = i1.ɵccf("app-about", i2.AboutComponent, View_AboutComponent_Host_0, {}, {}, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
