/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./require-new-password.component";
import * as i3 from "@ngxs/store";
var styles_RequireNewPasswordComponent = [];
var RenderType_RequireNewPasswordComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RequireNewPasswordComponent, data: {} });
export { RenderType_RequireNewPasswordComponent as RenderType_RequireNewPasswordComponent };
function View_RequireNewPasswordComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_RequireNewPasswordComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Set A New Password"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Back to Sign In"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "label", [["class", "input-group-text w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Password"])), (_l()(), i0.ɵeld(10, 0, [[1, 0], ["password", 1]], null, 0, "input", [["class", "form-control"], ["placeholder", "Password"], ["type", "password"]], null, [[null, "keyup"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.setPassword(i0.ɵnov(_v, 10).value) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.onSubmit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RequireNewPasswordComponent_2)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 15, 0, currVal_0); }, null); }
export function View_RequireNewPasswordComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { passwordControl: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RequireNewPasswordComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RequireNewPasswordComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "auth-require-new-password", [], null, null, null, View_RequireNewPasswordComponent_0, RenderType_RequireNewPasswordComponent)), i0.ɵdid(1, 49152, null, 0, i2.RequireNewPasswordComponent, [i3.Store], null, null)], null, null); }
var RequireNewPasswordComponentNgFactory = i0.ɵccf("auth-require-new-password", i2.RequireNewPasswordComponent, View_RequireNewPasswordComponent_Host_0, { authState: "authState" }, {}, []);
export { RequireNewPasswordComponentNgFactory as RequireNewPasswordComponentNgFactory };
