/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./app-error.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngxs/store";
import * as i6 from "../../services/cognito-auth/cognito-auth.service";
import * as i7 from "../../errata/ngx-spinner/ngx-spinner.service";
var styles_AppErrorComponent = [i0.styles];
var RenderType_AppErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppErrorComponent, data: {} });
export { RenderType_AppErrorComponent as RenderType_AppErrorComponent };
export function View_AppErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "jumbotron-fluid mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "display-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-poo-storm mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Something has gone wrong. Check out our "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "http://status.coalescepayments.com/"], ["rel", "noopener"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["status page"])), (_l()(), i1.ɵted(-1, null, [" to see if there's been a service interruption. "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(12, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Return to Login Page"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 14).ngClassValid; var currVal_5 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AppErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-error", [], null, null, null, View_AppErrorComponent_0, RenderType_AppErrorComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppErrorComponent, [i4.Router, i5.Store, i6.CognitoAuthService, i7.NgxSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppErrorComponentNgFactory = i1.ɵccf("app-app-error", i3.AppErrorComponent, View_AppErrorComponent_Host_0, {}, {}, []);
export { AppErrorComponentNgFactory as AppErrorComponentNgFactory };
