<ng-template #loading>
  
<div class="row d-flex justify-content-center mt-2">
  <div class="col-lg-12 col-sm-12 flex-fill">
    <div class="d-flex justify-content-center">
      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
    </div>
  </div>
</div>

</ng-template>

<form *ngIf="tableFormGroup; else loading" [formGroup]="tableFormGroup">

<div class="row d-flex justify-content-center mb-3">
  <div class="col-lg-12 col-sm-12">
    
    <div class="card mt-2">
      <div class="card-body">
        <h5 class="card-title">
          <div class="form-group row mb-1">
            <label class="col-sm-3 col-form-label">Table Name:</label>
            <div class="col-sm-9">
              <input type="text" class="form-control-plaintext" readonly formControlName="tableName">
            </div>
          </div>
        </h5>
        <div class="card-text">
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Table Alias:</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="tableAlias">
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Key Fields:</label>
              <div class="col-sm-9">
                <input type="text" class="form-control-plaintext" readonly formControlName="keyFields">
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Soft-Delete Field:</label>
              <div class="col-sm-3">
                <select class="form-control" formControlName="softDeleteField">
                  <option value="">- Select -</option>
                  <option *ngFor="let field of manifest[tableFormGroup.controls['tableName'].value].columns" value="{{field.fieldName}}">{{field.fieldName}}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control" placeholder="Enabled value" formControlName="softDeleteOn">
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control" placeholder="Disabled value" formControlName="softDeleteOff">
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Class Name:</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="className">
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">UI Label:</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="uiLabel">
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">API Endpoint</label>
              <div class="col-sm-9 input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">/</span>
                </div>
                <input type="text" class="form-control" formControlName="apiEndpoint">
              </div>
            </div>
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Notes:</label>
              <div class="col-sm-9">
                <textarea class="form-control" placeholder="Say anything..." formControlName="notes"></textarea>
              </div>
            </div>
        </div>
      </div>
    </div>

<ng-container formArrayName="columns">

    <div *ngFor="let col of columns.controls; index as i" class="card mt-2 bg-secondary text-dark" formGroupName="{{i}}">
      <div class="card-body">
        <h5 class="card-title">
          <div class="form-group row mb-1">
            <label class="col-sm-3 col-form-label">Field Name:</label>
            <div class="col-sm-9">
              <input type="text" class="form-control-plaintext text-dark" readonly formControlName="fieldName">
            </div>
          </div>
        </h5>
        
        <div class="card-text">
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Column Definition:</label>
              <div class="col-sm-9">
                <input type="text" class="form-control-plaintext text-dark" readonly formControlName="columnDef">
              </div>
            </div>
              
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">JavaScript Type:</label>
              <div class="col-sm-9">
                <select class="form-control" formControlName="jsType">
                  <option value="">Select</option>
                  <option value="number">number</option>
                  <option value="boolean">boolean</option>
                  <option value="string">string</option>
                  <option value="object">object</option>
                </select>
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">OpenAPI Type:</label>
              <div class="col-sm-9">
                <select class="form-control" formControlName="oaType">
                  <option value="">Select</option>
                  <option value="string">string</option>
                  <option value="number">number</option>
                  <option value="integer">integer</option>
                  <option value="boolean">boolean</option>
                  <option value="array">array</option>
                  <option value="object">object</option>
                </select>
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-form-label col-sm-3">OpenAPI Format:</label>
              <div class="col-sm-9">
                <select class="form-control" formControlName="oaFormat">
                  <option value="">Select</option>
                  <option value="float">float</option>
                  <option value="double">double</option>
                  <option value="int32">int32</option>
                  <option value="int64">int64</option>
                  <option value="array">array</option>
                  <option value="object">object</option>
                </select>
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">API Name:</label>
              <div class="col-sm-9">
                <input class="form-control" type="text" formControlName="apiName">
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Form Label:</label>
              <div class="col-sm-9">
                <input class="form-control" type="text" formControlName="formLabel">
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Form Control:</label>
              <div class="col-sm-9">
                <select class="form-control" formControlName="formControl">
                  <option [ngValue]="null">- Select -</option>
                  <option value="button">button</option>
                  <option value="select">select</option>
                  <option value="text">text</option>
                  <option value="toggle">toggle</option>
                  <option value="toggle-text">toggle-text</option>
                  <option value="password">password</option>
                  <option value="hidden">hidden</option>
                  <option value="toggle-section">toggle-section</option>
                </select>
              </div>
            </div>
            
            <div class="form-group row mb-1" formGroupName="validators">
              <label class="col-sm-3 col-form-label">Validators:</label>
              <div class="col-sm-9">
                
                <ngb-tabset type="pills">
                  <ngb-tab title="Create">
                    <ng-template ngbTabContent>
                      <select class="form-control" formControlName="create" multiple>
                        <option [ngValue]="undefined" (click)="emptyArray(i, 'create')">- Select -</option>
                        <option value="required">Required</option>
                        <option value="nonZero">Non-Zero</option>
                        <option value="email">Email</option>
                        <option value="minlength-8">Min Length (8)</option>
                        <option value="maxlength-99">Max Length (45)</option>
                        <option value="maxlength-99">Max Length (99)</option>
                        <option value="lowercase-1">Lowercase (1)</option>
                        <option value="uppercase-1">Uppercase (1)</option>
                        <option value="symbol-1">Symbol (1)</option>
                        <option value="number-1">Number (1)</option>
                        <option value="cognito-username">Cognito Username</option>
                        <option value="unique (users/username)">Unique (users/username)</option>
                      </select>
                    </ng-template>
                  </ngb-tab>
                  <ngb-tab title="Update">
                    <ng-template ngbTabContent>
                      <select class="form-control" formControlName="update" multiple>
                        <option [ngValue]="undefined" (click)="emptyArray(i, 'update')">- Select -</option>
                        <option value="required">Required</option>
                        <option value="nonZero">Non-Zero</option>
                        <option value="email">Email</option>
                        <option value="minlength-8">Min Length (8)</option>
                        <option value="maxlength-99">Max Length (45)</option>
                        <option value="maxlength-99">Max Length (99)</option>
                        <option value="lowercase-1">Lowercase (1)</option>
                        <option value="uppercase-1">Uppercase (1)</option>
                        <option value="symbol-1">Symbol (1)</option>
                        <option value="number-1">Number (1)</option>
                        <option value="cognito-username">Cognito Username</option>
                        <option value="unique (users/username)">Unique (users/username)</option>
                      </select>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>
                
              </div>
            </div>
            
            <fieldset class="form-group mb-1">
              <div class="row">
                
                <legend class="col-form-label col-sm-3">Nullable?</legend>
                
                <div class="col-sm-9 pt-2">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="nullable">
                    <label class="form-check-label"></label>
                  </div>
                </div>
                
              </div>
            </fieldset>
            
            <fieldset class="form-group mb-1">
              <div class="row">
              
                <legend class="col-form-label col-sm-3">Optional?</legend>
              
                <div class="col-sm-9">
                  <div class="form-check pt-2">
                    <input type="checkbox" class="form-check-input" formControlName="optional">
                    <label class="form-check-label"></label>
                  </div>
                </div>
                
              </div>
            </fieldset>
            
            <div class="form-group row mb-1" formGroupName="foreignKey">
              <label class="col-sm-3 col-form-label">Foreign Key:</label>
              <div class="col-sm-3">
                <select class="form-control" formControlName="table">
                  <option value="">- Table -</option>
                  <option *ngFor="let table of manifest | keyvalue" value="{{table.key}}">{{table.key}}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <select class="form-control" formControlName="field">
                  <option value="">- Field -</option>
                  <ng-container *ngIf="tableFormGroup.controls['columns'].controls[i].controls['foreignKey'].controls['table'].value as fTable">
                    <option *ngFor="let field of manifest[fTable].columns" value="{{field.fieldName}}">{{field.fieldName}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-sm-3">
                <select class="form-control" formControlName="relationship">
                  <option value="">- Relationship -</option>
                  <option value=".">. => One and Only One</option>
                  <option value="?">? => Zero or One</option>
                  <option value="+">+ => One or More</option>
                  <option value="*">* => Zero or More</option>
                </select>
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Help Text:</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="helpText">
              </div>
            </div>
            
            <div class="form-group row mb-1">
              <label class="col-sm-3 col-form-label">Notes:</label>
              <div class="col-sm-9">
                <textarea class="form-control" placeholder="Say Anything..." formControlName="notes"></textarea>
              </div>
            </div>
        </div>
      </div>
    </div>
    
</ng-container>
  
    <div class="card mt-2">
      <div class="card-body">
        <h5 class="card-title">Output</h5>
        <div class="card-text">
          <button type="button" class="btn btn-secondary mr-1" (click)="sampleOutput()">Sample Output</button>
          <button type="button" class="btn btn-primary" (click)="save()">
            <span *ngIf="saving; else toSave" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <ng-template #toSave><i class="fas fa-save pr-2"></i></ng-template>
            Save
          </button>
          <pre>{{ tableFormGroupValues | json }}</pre>
        </div>
      </div>
    </div>
    
  </div>
</div>

</form>