import { Component, Input } from '@angular/core'
// import { AmplifyService } from 'aws-amplify-angular' // AuthState
// import Auth from '@aws-amplify/auth'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'


import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'

@Component({
  selector: 'authenticator-component',
  templateUrl: './authenticator.component.html'
})
export class AuthenticatorComponent {
  authState: any = {
    state: 'signIn',
    user: null
  }
  
  // auth: any = Auth
  
  destroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private cognitoAuth: CognitoAuthService
    // private amplifyService: AmplifyService
  ) {  }
  
  @Input()
  hide: string[] = []

  subscribe() {
    // console.log(JSON.stringify(this.auth))
    
    // this.amplifyService.authStateChange$
    //   .subscribe(state => {
    //     this.authState = state
    //   })
    this.cognitoAuth.authStateChanges$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe( state => this.authState = state )
  }
  
  ngOnInit() {
    this.subscribe()
  }
  
  shouldHide(comp) {
    let hidden = this.hide.filter(item => item === comp).length > 0
    console.log('hider: ', comp, hidden)
    return hidden
    // return this.hide.filter(item => item === comp)
    //   .length > 0;
  }
}
