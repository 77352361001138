/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./agency-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../s-form/hidden/s-hidden.component.ngfactory";
import * as i3 from "../../../../s-form/hidden/s-hidden.component";
import * as i4 from "../../../../s-form/text/s-text.component.ngfactory";
import * as i5 from "../../../../s-form/text/s-text.component";
import * as i6 from "./agency-form.component";
var styles_AgencyFormComponent = [i0.styles];
var RenderType_AgencyFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgencyFormComponent, data: {} });
export { RenderType_AgencyFormComponent as RenderType_AgencyFormComponent };
export function View_AgencyFormComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { controls: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-s-hidden", [], null, null, null, i2.View_SHiddenComponent_0, i2.RenderType_SHiddenComponent)), i1.ɵdid(2, 573440, [[1, 4], ["idControl", 4]], 0, i3.SHiddenComponent, [], { config: [0, "config"], group: [1, "group"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-s-text", [], null, null, null, i4.View_STextComponent_0, i4.RenderType_STextComponent)), i1.ɵdid(4, 573440, [[1, 4], ["nameControl", 4]], 0, i5.STextComponent, [], { config: [0, "config"], group: [1, "group"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fields["id"]; var currVal_1 = _co.formGroup; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.fields["name"]; var currVal_3 = _co.formGroup; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_AgencyFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-agency-form", [], null, null, null, View_AgencyFormComponent_0, RenderType_AgencyFormComponent)), i1.ɵdid(1, 49152, null, 0, i6.AgencyFormComponent, [], null, null)], null, null); }
var AgencyFormComponentNgFactory = i1.ɵccf("app-agency-form", i6.AgencyFormComponent, View_AgencyFormComponent_Host_0, { fields: "fields", roles: "roles", permissions: "permissions", formGroup: "formGroup" }, {}, []);
export { AgencyFormComponentNgFactory as AgencyFormComponentNgFactory };
