<div class="card mt-2">
  <ul class="list-group list-group-flush">
    <!--<a routerLink="/manage/affiliate/{{session.selected['affiliates']?.id}}" closeSidebar class="text-center font-weight-bold list-group-item list-group-item-action">-->
    <a routerLink="/manage/affiliate/{{session.selected['affiliates']?.id}}" class="text-center font-weight-bold list-group-item list-group-item-action">
      {{session.selected['affiliates']?.name || 'Affiliate Name'}}
    </a>

    <!--<a routerLink="/select/merchant" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <a routerLink="/select/merchant" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-shopping-cart pr-2"></i>
      Select Merchant
    </a>

  </ul>
</div>

<ng-container *ngIf="session.selected['merchants']">
  <app-merchant-menu [session]="session"></app-merchant-menu>  
</ng-container>