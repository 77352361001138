import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-processor-form',
  templateUrl: './processor-form.component.html',
  styleUrls: ['./processor-form.component.scss']
})
export class ProcessorFormComponent {
  @ViewChildren('idControl, nameControl, componentControl, threadQuantityControl')
  controls: QueryList<any>

  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }
  
  public reset() {
    this.controls.forEach( control => control.reset() )
  }
}
