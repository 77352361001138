import * as tslib_1 from "tslib";
import { State, Action, Selector } from '@ngxs/store';
import { patch, append } from '@ngxs/store/operators';
import { GatewayApiService } from '@app/services/gateway-api';
import { NgxsError } from '@app/classes/error/ngxs-error';
export class FetchAppErrorRecords {
    // constructor(public resource: GwResource) {}
    constructor() { }
}
FetchAppErrorRecords.type = '[App-Error] Fetch';
export class FetchAppErrorRecordsPage {
    constructor(page) {
        this.page = page;
    }
}
FetchAppErrorRecordsPage.type = '[Omni] Fetch Page';
export class CreateAppErrorRecord {
    constructor(payload) {
        this.payload = payload;
    }
}
CreateAppErrorRecord.type = '[App-Error] Create';
export class UpdateAppErrorRecord {
    constructor(payload, previous) {
        this.payload = payload;
        this.previous = previous;
    }
}
UpdateAppErrorRecord.type = '[App-Error] Update';
export class DisableAppErrorRecord {
    constructor(payload) {
        this.payload = payload;
    }
}
DisableAppErrorRecord.type = '[App-Error] Disable';
export class ClearAppErrorRecords {
    constructor() { }
}
ClearAppErrorRecords.type = '[App-Error] Clear';
// export class GwRecordSet {
//   loading: boolean
//   records: IApiResourceRecordModel[]
// }
// export type GwRecordSetIndex =
// {
//   [key in GwResource]: GwRecordSet
// }
export class AppErrorStateModel {
}
// @State<GwStateModel>({
//   name: 'gwState',
//   defaults: {
//     loading: true,
//     index: {} as GwRecordSetIndex
//   }
// })
let GwAppErrorState = class GwAppErrorState {
    constructor(api) {
        this.api = api;
    }
    static getRecords(state) {
        console.log('App-Error selector was called');
        return state;
    }
    fetch({ setState }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log(`fetching: ${resource}`)
            setState(patch({
                loading: true,
                records: [],
            }));
            let result = [];
            try {
                result = yield this.api.getAll('app-error');
            }
            catch (error) {
                console.info(`Could not fetch application error messages.`);
                throw new NgxsError('The records could not be retrieved.');
            }
            finally {
                setState(patch({
                    loading: false,
                    records: result
                }));
            }
        });
    }
    // @Action(FetchAppErrorRecordsPage)
    // async fetchPage({setState}: StateContext<GwStateModel>, { page }: FetchGwRecordsPage) {
    //   setState(
    //     patch<GwStateModel>({
    //       index: patch({ [resource]: { loading: true, records: [] } })
    //     })
    //   )
    //   let result: IApiResourceRecordModel[] = []
    //   try {
    //     result = await this.api.getAll<IApiResourceRecordModel>(resource)
    //   } catch (error) {
    //     console.info(`Could not fetch ${resource}.`)
    //     throw new NgxsError('The records could not be retrieved.')
    //   } finally {
    //     setState(
    //       patch<GwStateModel>({
    //         index: patch({ [resource]: { loading: false, records: result } })
    //       })
    //     )
    //   }
    // }
    create({ setState }, { payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result;
            try {
                result = yield this.api.create('app-error', payload);
                if (!result.success)
                    throw new NgxsError('API action completed, but result was not successful.');
                if (!result.id)
                    throw new NgxsError('API action completed successfully, but no ID was returned.');
            }
            catch (error) {
                console.info(error.message);
                throw new NgxsError('The record could not be created.');
            }
            payload.id = result.id; // FIXME - should use propery Primary Key
            setState(patch({
                records: append([payload])
            }));
        });
    }
    update({ getState, setState }, { payload, previous }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const state = getState();
            setState(patch({
                loading: true,
                records: [...state.records]
            }));
            const index = state.records.findIndex(i => i.id == payload.id);
            if (index === -1) {
                console.info(`Error: Attempt to update an application error messasge failed because the record could not be located locally.`);
                console.log('id', payload.id);
                setState(patch({
                    loading: false,
                    records: [...state.records]
                }));
                throw new NgxsError('This record may not be available anymore.');
            }
            let updatedRecords = state.records;
            try {
                const result = yield this.api.update('app-error', payload, previous);
                if (result.success) {
                    updatedRecords = state.records.filter(record => record.id != payload.id); // FIXME should not use 'id' here.
                    updatedRecords.push(payload);
                }
                else {
                    throw new NgxsError('API did not return success: true.');
                }
            }
            catch (error) {
                console.info(error.message);
                throw new NgxsError('The record could not be updated.');
            }
            finally {
                setState(patch({
                    loading: false,
                    records: [...updatedRecords]
                }));
            }
        });
    }
    disable({ getState, setState }, { payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const state = getState();
            setState(patch({
                loading: true,
                records: [...state.records]
            }));
            let updatedRecords = state.records;
            try {
                const result = yield this.api.disable('app-error', `${payload.id}`); // FIXME to not use ID.
                if (result.success)
                    updatedRecords = state.records.filter(record => record.id !== payload.id); // FIXME
                else
                    throw new NgxsError('API did not return success: true.');
            }
            catch (error) {
                console.info(error.message);
                throw new NgxsError('Could not delete the record.');
            }
            finally {
                setState(patch({
                    loading: false,
                    records: [...updatedRecords]
                }));
            }
        });
    }
    clear({ setState }) {
        setState(patch({
            loading: false,
            records: [],
        }));
    }
};
tslib_1.__decorate([
    Action(FetchAppErrorRecords),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Promise)
], GwAppErrorState.prototype, "fetch", null);
tslib_1.__decorate([
    Action(CreateAppErrorRecord),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateAppErrorRecord]),
    tslib_1.__metadata("design:returntype", Promise)
], GwAppErrorState.prototype, "create", null);
tslib_1.__decorate([
    Action(UpdateAppErrorRecord),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateAppErrorRecord]),
    tslib_1.__metadata("design:returntype", Promise)
], GwAppErrorState.prototype, "update", null);
tslib_1.__decorate([
    Action(DisableAppErrorRecord),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DisableAppErrorRecord]),
    tslib_1.__metadata("design:returntype", Promise)
], GwAppErrorState.prototype, "disable", null);
tslib_1.__decorate([
    Action(ClearAppErrorRecords),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], GwAppErrorState.prototype, "clear", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [AppErrorStateModel]),
    tslib_1.__metadata("design:returntype", Object)
], GwAppErrorState, "getRecords", null);
GwAppErrorState = tslib_1.__decorate([
    State({
        name: 'appError',
        defaults: {
            loading: true,
            records: [],
        }
    }),
    tslib_1.__metadata("design:paramtypes", [GatewayApiService])
], GwAppErrorState);
export { GwAppErrorState };
