<ng-template [ngIf]="_show">
  <div class="d-flex justify-content-center">
    <button type="button" (click)="onSignIn()" class="btn">Back to Sign In</button>
  </div>

  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text w-100">Code</label>
    </div>
    <input #code (keyup)="setCode(code.value)" (keyup.enter)="onConfirm()" class="form-control" type="text" placeholder="Code"/>
  </div>
  
  <div class="d-flex justify-content-center">
    <button (click)="onConfirm()">Confirm</button>
  </div>
  
  <div *ngIf="errorMessage">{{ errorMessage }}</div>
</ng-template>