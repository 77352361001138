<span *ngIf="errors?.required">This field is required. </span>
<span *ngIf="errors?.nonZero">This field must not be zero. </span>
<span *ngIf="errors?.minlength">Must be at least {{ errors.minlength.requiredLength }} characters. </span>
<span *ngIf="errors?.maxlength">Must be less than {{ errors.maxlength.requiredLength }} characters. </span>
<span *ngIf="errors?.pattern && errors.pattern.requiredPattern === '/[a-z]+/'">Must contain at least one lowercase letter. </span>
<span *ngIf="errors?.pattern && errors.pattern.requiredPattern === '/[A-Z]+/'">Must contain at least one uppercase letter. </span>
<span *ngIf="errors?.pattern && errors.pattern.requiredPattern === symbol">Must contain one of: ^ $ * . [ ] {{ '{' }} '}' ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` </span>
<span *ngIf="errors?.pattern && errors.pattern.requiredPattern === '/[0-9]+/'">Must contain at least one number. </span>
<span *ngIf="errors?.pattern && errors.pattern.requiredPattern === cognito_username">Letters, numbers, dashes, and underscores only. </span>
<span *ngIf="errors?.email">Email address appears to be invalid. </span>
<span *ngIf="errors?.passwordMismatch">Passwords do not match. </span>