import * as tslib_1 from "tslib";
import { filter, distinctUntilKeyChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store, State, Action, Selector } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import Auth from '@aws-amplify/auth';
import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service';
import { GatewayApiService } from '@app/services/gateway-api';
import { FetchAppErrorRecords } from '@app/ngxs/gateway-api/app-error.ngxs';
import { NgxsError } from '@app/classes/error/ngxs-error';
import { FetchGwRecords, RecordLogin } from '@app/ngxs/gateway-api';
import { ToastInfo } from '@app/ngxs/toaster.ngxs';
export class BeginSession {
    constructor(username) {
        this.username = username;
    }
}
BeginSession.type = '[Session] Begin Session';
export class ClearCurrentUser {
    constructor() { }
}
ClearCurrentUser.type = '[Session] Clear Current User';
export class ClearSession {
    constructor() { }
}
ClearSession.type = '[Session] Clear Session';
export class SetSelectedResource {
    constructor(resource, id) {
        this.resource = resource;
        this.id = id;
    }
}
SetSelectedResource.type = '[Session] Set Selected Resource';
export class ClearSelectedResource {
    constructor(resource) {
        this.resource = resource;
    }
}
ClearSelectedResource.type = '[Session] Clear Selected Resource';
export class IdleSetState {
    constructor(stateName, idleTemplate) {
        this.stateName = stateName;
        this.idleTemplate = idleTemplate;
    }
}
IdleSetState.type = '[Session] Idle Set State';
export class InitializeIdle {
    constructor(idleTemplate) {
        this.idleTemplate = idleTemplate;
    }
}
InitializeIdle.type = '[Session] Initialize Idle';
export class CancelIdle {
    constructor() { }
}
CancelIdle.type = '[Session] Cancel Idle';
export class AppSignin {
    constructor() { }
}
AppSignin.type = '[Session] App Signin';
export class AppSignout {
    constructor() { }
}
AppSignout.type = '[Session] App Signout';
export class InitCredentials {
    constructor(idleTemplate) {
        this.idleTemplate = idleTemplate;
    }
}
InitCredentials.type = '[Session] Init Credentials';
export class CognitoSignIn {
    constructor(username, password) {
        this.username = username;
        this.password = password;
    }
}
CognitoSignIn.type = '[Session] Cognito Sign In';
export class CognitoForgotPass {
    constructor(username) {
        this.username = username;
    }
}
CognitoForgotPass.type = '[Session] Cognito Forgot Pass';
export class CognitoSignUp {
    constructor(username) {
        this.username = username;
    }
}
CognitoSignUp.type = '[Session] Cognito Sign Up';
export class CognitoSetError {
    constructor(err) {
        this.err = err;
    }
}
CognitoSetError.type = '[Session] Cognito Set Error';
export class CognitoForgotPassSubmit {
    constructor(username, code, password) {
        this.username = username;
        this.code = code;
        this.password = password;
    }
}
CognitoForgotPassSubmit.type = '[Session] Cognito Forgot Pass Submit';
export class CognitoSendCode {
    constructor(username) {
        this.username = username;
    }
}
CognitoSendCode.type = '[Session] Cognito Send Code';
export class CognitoRequireSignIn {
    constructor(user) {
        this.user = user;
    }
}
CognitoRequireSignIn.type = '[Session] Cognito Require Sign In';
export class CognitoCompleteNewPassword {
    constructor(user, password) {
        this.user = user;
        this.password = password;
    }
}
CognitoCompleteNewPassword.type = '[Session] Cognito Complete New Password';
export class CognitoConfirmSignIn {
    constructor(user, code, mfaType) {
        this.user = user;
        this.code = code;
        this.mfaType = mfaType;
    }
}
CognitoConfirmSignIn.type = '[Session] Cognito Confirm Sign In';
export class Authorizer {
}
export class SessionStateModel {
}
let SessionState = class SessionState {
    constructor(api, store, 
    // private idleService: IdleService,
    idle, 
    // private middlemanService: IdleMiddlemanService,
    modalService, cognitoAuth) {
        this.api = api;
        this.store = store;
        this.idle = idle;
        this.modalService = modalService;
        this.cognitoAuth = cognitoAuth;
        this.start = new Subscription();
        this.end = new Subscription();
        this.timeout = new Subscription();
    }
    static getCurrentUser(state) {
        return state.currentUser;
    }
    beginSession({ patchState, dispatch }, { username }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            patchState({
                loading: true,
                currentUser: null,
            });
            const session = new SessionStateModel();
            let result;
            const currentUserPayload = {
                username: {
                    op: '=',
                    val: username,
                }
            };
            try {
                result = yield this.api.getAll('users', currentUserPayload);
            }
            catch (error) {
                console.error(error.message);
                result = null;
                throw new NgxsError('Failed to fetch current user.');
            }
            if (!result || result.length !== 1) { // FIXME - if user information can't be found, a generic sign-out screen should be shown along with an error message
                patchState({
                    loading: false
                });
                throw new NgxsError('User account could not be located!');
            }
            session.currentUser = result[0];
            session.extendedPermissions = {};
            let extendedPermissions; // { PreferenceName, PreferenceValue }
            try {
                extendedPermissions = yield this.api.getAll('extended-permissions', {});
            }
            catch (error) {
                console.error(error.message);
                extendedPermissions = null;
            }
            if (extendedPermissions) {
                const singleEntryOnly = extendedPermissions.find(r => r.name === 'SingleEntryOnly');
                const singleEntryIfNotUserAdmin = extendedPermissions.find(r => r.name === 'SingleEntryIfNotUserAdmin');
                const singleEntryIfNotVoid = extendedPermissions.find(r => r.name === 'SingleEntryIfNotVoid');
                session.extendedPermissions = {
                    singleEntryOnly: singleEntryOnly ? singleEntryOnly.value : null,
                    singleEntryIfNotUserAdmin: singleEntryIfNotUserAdmin ? singleEntryIfNotUserAdmin.value : null,
                    singleEntryIfNotVoid: singleEntryIfNotVoid ? singleEntryIfNotVoid.value : null,
                };
                if ((singleEntryOnly && singleEntryOnly.value === 'true')
                    ||
                        (singleEntryIfNotUserAdmin && singleEntryIfNotUserAdmin.value === 'true' && !session.currentUser.allow_user)
                    ||
                        (singleEntryIfNotVoid && singleEntryIfNotVoid.value === 'true' && !session.currentUser.allow_void)
                    ||
                        (session.currentUser.report_only)) {
                    return dispatch([
                        new ToastInfo('Your account is not authorized for this service.', 'Signed Out'),
                        new AppSignout(),
                    ]);
                }
            }
            session.roles = {
                "superuser": false,
                "affiliate": false,
                "merchant": false,
            };
            if (session.currentUser.is_admin) {
                session.roles.superuser = true;
                return dispatch([
                    new RecordLogin(username),
                    new FetchGwRecords('api-manifest'),
                    new FetchGwRecords('statuses'),
                    new FetchGwRecords('mids'),
                    new FetchGwRecords('users'),
                    new FetchGwRecords('merchants'),
                    new FetchGwRecords('affiliates'),
                    new FetchGwRecords('offices'),
                    new FetchGwRecords('processors'),
                    new FetchGwRecords('agencies'),
                    new FetchGwRecords('mid-groups'),
                    new FetchGwRecords('routes'),
                    new FetchGwRecords('payment-types'),
                    new FetchAppErrorRecords(),
                ])
                    .subscribe(() => {
                    patchState(Object.assign({ loading: false }, session));
                });
            }
            else if (session.currentUser.affiliate_id !== 0) {
                session.roles.affiliate = true;
                return dispatch([
                    new RecordLogin(username),
                    new FetchGwRecords('api-manifest'),
                    new FetchGwRecords('statuses'),
                    new FetchGwRecords('merchants'),
                    new FetchGwRecords('affiliates'),
                    new FetchGwRecords('users'),
                    new FetchGwRecords('offices'),
                    new FetchGwRecords('mids'),
                    new FetchGwRecords('processors'),
                    new FetchGwRecords('routes'),
                    new FetchGwRecords('payment-types'),
                    new SetSelectedResource('affiliates', session.currentUser.affiliate_id)
                ])
                    .subscribe(() => {
                    patchState(Object.assign({ loading: false }, session));
                });
            }
            else if (session.currentUser.merchant_id !== 0) {
                session.roles.merchant = true;
                return dispatch([
                    new RecordLogin(username),
                    new FetchGwRecords('api-manifest'),
                    new FetchGwRecords('statuses'),
                    new FetchGwRecords('users'),
                    new FetchGwRecords('merchants'),
                    new FetchGwRecords('offices'),
                    // new FetchGwRecords('mids'),
                    // new FetchGwRecords('processors'),
                    // new FetchGwRecords('routes'),
                    // new FetchGwRecords('payment-types'),
                    new SetSelectedResource('merchants', session.currentUser.merchant_id),
                    new SetSelectedResource('office', session.currentUser.office_id),
                ])
                    .subscribe(() => {
                    patchState(Object.assign({ loading: false }, session));
                });
                // TODO - fetch their Merchant info from sbps_clnt
            }
        });
    }
    clearCurentUser({ patchState, getState }) {
        patchState(Object.assign({}, getState(), { currentUser: null }));
    }
    clearSession({ patchState }) {
        patchState({
            loading: true,
            roles: { 'superuser': false, 'merchant': false, 'affiliate': false },
            permissions: {},
            currentUser: null,
            selected: {},
            idle: {
                state: null,
                modalRef: null
            },
            auth: {
                state: {
                    state: 'signedOut',
                    user: null
                },
                subscription: null,
                loading: false,
                loadingCode: false,
                loadingForgot: false,
                errorMessage: null
            }
        });
    }
    setSelectedResource({ patchState, getState }, { resource, id }) {
        return this.store.select(s => s['gwState'].index[resource])
            .pipe(filter(s => s && s.records))
            .subscribe(state => {
            patchState({ selected: Object.assign({}, getState().selected, { [resource]: state.records.find(r => r.id === id) }) });
        });
    }
    clearSelectedResource({ patchState, getState }, { resource }) {
        return this.store.select(s => s['gwState'].index[resource])
            .pipe(filter(s => s && s.records))
            .subscribe(state => {
            patchState({ selected: Object.assign({}, getState().selected, { [resource]: undefined }) });
        });
    }
    idleSetState({ patchState, getState, dispatch }, { stateName, idleTemplate }) {
        let a = '';
        let b = false;
        if (stateName === 'start') {
            a = 'You will be logged out soon.';
            b = true;
        }
        else if (stateName === 'end') {
            a = 'No longer idle.';
        }
        else if (stateName === 'timeout') {
            a = 'Your session has timed out.';
            dispatch([new AppSignout()]);
        }
        const state = getState();
        patchState(Object.assign({}, state, { idle: Object.assign({}, state.idle, { state: a }) }));
    }
    initializeIdle({ patchState, getState }, { idleTemplate }) {
        this.idle.setIdle(720); // 720 = 12 minutes
        this.idle.setTimeout(180); // 180 = 3 minutes 
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.start = this.idle.onIdleStart.subscribe(() => {
            this.store.dispatch(new IdleSetState('start', idleTemplate));
        }),
            this.end = this.idle.onIdleEnd.subscribe(() => {
                this.store.dispatch(new IdleSetState('end'));
            }),
            this.timeout = this.idle.onTimeout.subscribe(() => {
                this.store.dispatch(new IdleSetState('timeout'));
            });
        const initialState = getState();
        patchState(Object.assign({}, initialState, { idle: Object.assign({}, initialState.idle, { state: `You will be logged out automatically if you go idle for too long.` }) }));
        this.idle.watch();
    }
    cancelIdle({ patchState, getState }) {
        if (this.start)
            this.start.unsubscribe();
        if (this.end)
            this.end.unsubscribe();
        if (this.timeout)
            this.timeout.unsubscribe();
        this.start = null;
        this.end = null;
        this.timeout = null;
    }
    static getAuthState(state) {
        return state.auth.state;
    }
    appSignin({}) {
        // do some stuff here
    }
    appSignout({ dispatch }) {
        Auth.signOut()
            .then(() => {
            this.cognitoAuth.setAuthState({ state: 'signedOut', user: null });
            dispatch([
                new CancelIdle(),
                new ClearSession(),
            ]);
            // this.router.navigate([``]) // FIXME re-enable for production
        })
            .catch(event => {
            console.error('bad signout', JSON.stringify(event));
            // this.router.navigate([``])
        }); // FIXME log error to database
        // this.amplifyService.auth().signOut()
        //   .then( () => {
        //     this.store.dispatch(new ClearSession())
        //     this.spinner.hide() // FIXME this shouldn't be needed but yet it is.
        //   })
        //   .catch( event => console.error('bad signout', JSON.stringify(event)) ) // FIXME log error
        // this.router.navigate([``]) // FIXME re-enable for production
    }
    initCredentials({ patchState, getState, dispatch }, { idleTemplate }) {
        // console.log('init credentials')
        const authSub = this.cognitoAuth.authStateChanges$
            .pipe(distinctUntilKeyChanged('state'))
            .subscribe(change => {
            const state = getState();
            patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { state: {
                        state: change.state,
                        user: null
                    } }) }));
            if (change.state === 'signedIn' && change.user) {
                dispatch([
                    new InitializeIdle(idleTemplate),
                    new BeginSession(change.user.username),
                ]);
            }
        });
    }
    cognitoSignIn({ patchState, getState, dispatch }, { username, password }) {
        const initialState = getState();
        // console.log('signing in cognito')
        patchState(Object.assign({}, initialState, { auth: Object.assign({}, initialState.auth, { loading: true }) }));
        return Auth.signIn(username, password)
            .then(user => {
            const state = getState();
            const authState = (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA')
                ? 'confirmSignIn'
                : (user.challengeName === 'NEW_PASSWORD_REQUIRED')
                    ? 'requireNewPassword'
                    : 'signedIn';
            this.cognitoAuth.setAuthState({ state: authState, user: user });
            patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { state: { state: authState, user: user.username }, loading: false }) }));
        })
            .catch(err => {
            const state = getState();
            patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { loading: false }) }));
            dispatch(new CognitoSetError(err));
        });
    }
    cognitoForgotPass({ patchState, getState }, { username }) {
        const state = getState();
        patchState(Object.assign({}, state, { loading: false }));
        this.cognitoAuth.setAuthState({ state: 'forgotPassword', user: username });
    }
    cognitoSignUp({ patchState, getState }, { username }) {
        this.cognitoAuth.setAuthState({ state: 'signUp', user: username });
    }
    cognitoSetError({ patchState, getState }, { err }) {
        const state = getState();
        patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { errorMessage: err ? this.wrapCognitoError(err, err.message) : null }) }));
    }
    cognitoForgotPassSubmit({ patchState, getState, dispatch }, { username, code, password }) {
        const initState = getState();
        patchState(Object.assign({}, initState, { auth: Object.assign({}, initState.auth, { loadingCode: true }) }));
        Auth.forgotPasswordSubmit(username, code, password)
            .then(() => {
            const user = { username: username };
            this.cognitoAuth.setAuthState({ state: 'signIn', user: user });
            patchState(Object.assign({}, initState, { auth: Object.assign({}, initState.auth, { loadingCode: false }) }));
        })
            .catch(err => {
            dispatch(new CognitoSetError(err));
            patchState(Object.assign({}, initState, { auth: Object.assign({}, initState.auth, { loadingCode: false }) }));
        });
    }
    cognitoSendCode({ patchState, getState, dispatch }, { username }) {
        let state = getState();
        patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { loadingForgot: true }) }));
        dispatch(new CognitoSetError(null));
        Auth.forgotPassword(username)
            .then(() => {
            patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { loadingForgot: false }) }));
        })
            .catch(err => {
            dispatch(new CognitoSetError(err));
            patchState(Object.assign({}, state, { auth: Object.assign({}, state.auth, { loadingForgot: false }) }));
        });
    }
    cognitoRequireSignIn({ patchState, getState }, { user }) {
        const state = getState();
        patchState(Object.assign({}, state, { loading: true }));
        this.cognitoAuth.setAuthState({ state: 'signIn', user: user });
    }
    cognitoCompleteNewPassword({ patchState, getState, dispatch }, { user, password }) {
        Auth.completeNewPassword(user, password, user.challengeParam)
            .then(() => {
            dispatch(new CognitoRequireSignIn(user));
        })
            .catch(err => {
            dispatch(new CognitoSetError(err));
        });
    }
    cognitoConfirmSignIn({ patchState, getState, dispatch }, { user, code, mfaType }) {
        Auth.confirmSignIn(user, code, mfaType)
            .then(() => this.cognitoAuth.setAuthState({ state: 'signedIn', user: user }))
            .catch(err => {
            dispatch(new CognitoSetError(err));
        });
    }
    wrapCognitoError(err, message) {
        // Valid username, blank password.
        if (message === 'null invocation failed due to configuration.')
            return 'Please provide both a username and a password.';
        if (message === 'null failed with error Generate callenges lambda cannot be called..')
            return 'Incorrect username or password.';
        // Invalid username, non-blank password.
        if (message === 'UserMigration failed with error : Login credentials were not recognized..')
            return 'Incorrect username or password.';
        // Invalid username, blank password
        if (message === 'User does not exist.')
            return 'Incorrect username or password.';
        if (message === 'Password attempts exceeded.')
            return 'Password attempts exceeded. Please try again in a few minutes.';
        if (message === 'UserMigration failed with error : Username could not be located..')
            return 'User not found.';
        if (message && message[message.length - 1] !== '.')
            return message += '.';
        if (!message && typeof err === "string" && err[err.length - 1] !== '.')
            return err += '.';
        return message || err;
    }
};
tslib_1.__decorate([
    Action(BeginSession),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, BeginSession]),
    tslib_1.__metadata("design:returntype", Promise)
], SessionState.prototype, "beginSession", null);
tslib_1.__decorate([
    Action(ClearCurrentUser),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "clearCurentUser", null);
tslib_1.__decorate([
    Action(ClearSession),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "clearSession", null);
tslib_1.__decorate([
    Action(SetSelectedResource),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetSelectedResource]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "setSelectedResource", null);
tslib_1.__decorate([
    Action(ClearSelectedResource),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetSelectedResource]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "clearSelectedResource", null);
tslib_1.__decorate([
    Action(IdleSetState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, IdleSetState]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "idleSetState", null);
tslib_1.__decorate([
    Action(InitializeIdle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, InitializeIdle]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "initializeIdle", null);
tslib_1.__decorate([
    Action(CancelIdle),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cancelIdle", null);
tslib_1.__decorate([
    Action(AppSignin),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "appSignin", null);
tslib_1.__decorate([
    Action(AppSignout),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "appSignout", null);
tslib_1.__decorate([
    Action(InitCredentials),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, InitCredentials]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "initCredentials", null);
tslib_1.__decorate([
    Action(CognitoSignIn),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoSignIn]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoSignIn", null);
tslib_1.__decorate([
    Action(CognitoForgotPass),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoForgotPass]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoForgotPass", null);
tslib_1.__decorate([
    Action(CognitoSignUp),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoSignUp]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoSignUp", null);
tslib_1.__decorate([
    Action(CognitoSetError),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoSetError]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoSetError", null);
tslib_1.__decorate([
    Action(CognitoForgotPassSubmit),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoForgotPassSubmit]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoForgotPassSubmit", null);
tslib_1.__decorate([
    Action(CognitoSendCode),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoSendCode]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoSendCode", null);
tslib_1.__decorate([
    Action(CognitoRequireSignIn),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoRequireSignIn]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoRequireSignIn", null);
tslib_1.__decorate([
    Action(CognitoCompleteNewPassword),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoCompleteNewPassword]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoCompleteNewPassword", null);
tslib_1.__decorate([
    Action(CognitoConfirmSignIn),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CognitoConfirmSignIn]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState.prototype, "cognitoConfirmSignIn", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [SessionStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState, "getCurrentUser", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [SessionStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], SessionState, "getAuthState", null);
SessionState = tslib_1.__decorate([
    State({
        name: 'session',
        defaults: {
            loading: true,
            roles: { 'superuser': false, 'merchant': false, 'affiliate': false },
            permissions: {},
            currentUser: null,
            extendedPermissions: null,
            selected: {},
            idle: {
                state: null,
                modalRef: null
            },
            auth: {
                state: {
                    state: 'signedOut',
                    user: null
                },
                subscription: null,
                loading: false,
                loadingCode: false,
                loadingForgot: false,
                errorMessage: null
            }
        }
    }),
    tslib_1.__metadata("design:paramtypes", [GatewayApiService,
        Store,
        Idle,
        NgbModal,
        CognitoAuthService])
], SessionState);
export { SessionState };
