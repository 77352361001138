<app-s-hidden #idControl [config]="fields['id']" [group]="formGroup"></app-s-hidden>
<app-s-select #merchantIdControl [config]="fields['merchant_id']" [group]="formGroup"></app-s-select>
<app-s-text #nameControl [config]="fields['name']" [group]="formGroup"></app-s-text>
<app-s-select #bankCardMidControl [config]="fields['bank_card_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achArcMidControl [config]="fields['ach_arc_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achCbrMidControl [config]="fields['ach_cbr_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achCcdMidControl [config]="fields['ach_ccd_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achCieMidControl [config]="fields['ach_cie_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achCorMidControl [config]="fields['ach_cor_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achCtxMidControl [config]="fields['ach_ctx_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achMteMidControl [config]="fields['ach_mte_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achPbrMidControl [config]="fields['ach_pbr_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achPopMidControl [config]="fields['ach_pop_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achPosMidControl [config]="fields['ach_pos_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achPpdMidControl [config]="fields['ach_ppd_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achRckMidControl [config]="fields['ach_rck_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achTelMidControl [config]="fields['ach_tel_mid']" [group]="formGroup"></app-s-select>
<app-s-select #achWebMidControl [config]="fields['ach_web_mid']" [group]="formGroup"></app-s-select>
<app-s-toggle #statusIdControl [config]="fields['status_id']" [group]="formGroup"></app-s-toggle>