import { State, Action, StateContext, Selector } from '@ngxs/store'
import { patch, append } from '@ngxs/store/operators'

import { GatewayApiService } from '@app/services/gateway-api'
import { GwResource } from '@app/models/gateway-api/gw-resource.type'
import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'
import { ToastSuccess, ToastError, ToastWarning, ToastInfo } from '@app/ngxs/toaster.ngxs'
import { NgxsError } from '@app/classes/error/ngxs-error'
import { environment } from '../../../environments/environment'
// import { ReloadPage } from '@app/ngxs/session.ngxs'

export class TestToast {
    static readonly type = '[Omni] Test Toast'
    
    constructor(public message: string = '') {}
}

export class FetchGwRecords {
    static readonly type = '[Omni] Fetch'
    
    constructor(public resource: GwResource, public payload?: IApiResourceRecordModel) {}
}

export class FetchGwRecordsPage {
    static readonly type = '[Omni] Fetch Page'
    
    constructor(public resource: GwResource, public page: number) {}
}

export class CreateGwRecord {
    static readonly type = '[Omni] Create'
    
    constructor(public resource: GwResource, public payload: IApiResourceRecordModel) {}
}

export class UpdateGwRecord {
    static readonly type = '[Omni] Update'
    
    constructor(public resource: GwResource, public payload: IApiResourceRecordModel, public previous: IApiResourceRecordModel) {}
}

export class DisableGwRecord {
    static readonly type = '[Omni] Disable'
    
    constructor(public resource: GwResource, public payload: IApiResourceRecordModel) {}
}

export class ClearGwRecords {
    static readonly type = '[Omni] Clear'
    
    constructor(public resource: GwResource) {}
}

export class RecordLogin {
  static readonly type = '[Omni] Record Login'
  
  constructor(public assumedUsername: string) {}
}

export class ReloadPage {
  static readonly type = '[Omni] Reload Page'
  
  constructor() {}
}

export class GwRecordSet {
  loading: boolean
  expiration: number
  records: IApiResourceRecordModel[]
}

export type GwRecordSetIndex =
{
  [key in GwResource]: GwRecordSet
}

export class GwStateModel {
  loading: boolean
  index: Partial<GwRecordSetIndex>
}

@State<GwStateModel>({
  name: 'gwState',
  defaults: {
    loading: true,
    index: {} as GwRecordSetIndex
  }
})
export class GwState {
  
  constructor(
    private api: GatewayApiService,
  ) {}
  
  @Selector()
  static getRecords(resource: GwResource, state: GwStateModel): Partial<GwRecordSet> {
    console.log('GwState @Selector was called')
    return state.index && state.index[resource]
  }

  @Action(FetchGwRecords)
  async fetch({setState, dispatch}: StateContext<GwStateModel>, { resource, payload }: FetchGwRecords) {
    setState(
      patch<GwStateModel>({
        index: patch({ [resource]: { loading: true, expiration: null, records: [] } })
      })
    )
    
    let result: IApiResourceRecordModel[] = []
    
    try {
      result = await this.api.getAll<IApiResourceRecordModel>(resource, payload)
    } catch (error) {
      console.info(`Could not fetch ${resource}.`)
      dispatch( new ToastInfo(`Could not fetch ${resource}.`))
      
      // throw new NgxsError('The records could not be retrieved.')
    } finally {
      setState(
        patch<GwStateModel>({
          index: patch({ [resource]: { loading: false, expiration: Date.now() + 300000, records: result } })
        })
      )
    }
  }
  
  @Action(FetchGwRecordsPage)
  async fetchPage({setState, dispatch}: StateContext<GwStateModel>, { resource, page }: FetchGwRecordsPage) {
    setState(
      patch<GwStateModel>({
        index: patch({ [resource]: { loading: true, records: [] } })
      })
    )
    
    let result: IApiResourceRecordModel[] = []
    
    try {
      result = await this.api.getAll<IApiResourceRecordModel>(resource)
    } catch (error) {
      console.info(`Could not fetch ${resource}.`)
      dispatch( new ToastInfo(`Could not fetch ${resource}.`))
    } finally {
      setState(
        patch<GwStateModel>({
          index: patch({ [resource]: { loading: false, expiration: null, records: result } })
        })
      )
    }
  }
  
  @Action(CreateGwRecord)
  async create({setState, dispatch}: StateContext<GwStateModel>, { resource, payload }: CreateGwRecord) {
    let result
    
    try {
      result = await this.api.create<IApiResourceRecordModel>(resource, payload)
      
      if (!result.success || !result.id) {
        dispatch( new ToastError('There was a problem creating the record.') )
        return
      }
       // throw new NgxsError('API action completed, but result was not successful.')
        
      // if (!result.id)
      //   throw new NgxsError('API action completed successfully, but no ID was returned.')
    } catch (error) {
      console.info(error.message)
      dispatch( new ToastError('API communication error.') )
      throw new NgxsError(`API communication error: ${error.message}.`)
    }
    
    payload.id = result.id // FIXME - should use propery Primary Key
    
    setState(
      patch<GwStateModel>({
        index: patch({
          [resource]: patch({ records: append([ payload ]) })
        })
      })
    )
    
    dispatch( new ToastSuccess('Record created!') )
  }
  
  @Action(UpdateGwRecord)
  async update({getState, setState, dispatch}: StateContext<GwStateModel>, { resource, payload, previous }: UpdateGwRecord) {
    const state = getState()
    
    setState(
      patch<GwStateModel>({
        index: patch({ [resource]: { loading: true, records: [...state.index[resource].records] } })
      })
    )
    
    const index = state.index[resource].records.findIndex(i => i.id == payload['id'].val) // FIXME
    
    if (index === -1) {
      console.info(`Error: Attempt to update a ${resource} failed because the record could not be located locally.`)
      
      setState(
        patch<GwStateModel>({
          index: patch({ [resource]: { loading: false, records: [...state.index[resource].records] } })
        })
      )
      
      dispatch( new ToastInfo('This record may not be available anymore.') )
      return
    }
    
    let updatedRecords = state.index[resource].records
      
    try {
      const result = await this.api.update<IApiResourceRecordModel>(resource, payload, previous)
      
      if (result.success) {
        updatedRecords = state.index[resource].records.filter( record => record.id != payload['id'].val) // FIXME should not use 'id' here.
        updatedRecords.push( Object.keys(payload).reduce( (acc, key) => {
          acc[key] = payload[key].val
          return acc
        }, {}) )
        
        dispatch( new ToastSuccess('Record saved!') )
      } else if (result.clobbered) {
        dispatch( new ToastWarning('The record you are editing is stale - another user may have edited it.', 'Problem Updating Record') )
        return
      } else {
        dispatch( new ToastError('The record could not be updated.') )
      }
    } catch (error) {
      console.info(error.message)
      
      // throw new NgxsError('The record could not be updated.')
      dispatch( new ToastError('API communication error.') )
    } finally {
      setState(
        patch<GwStateModel>({
          index: patch({ [resource]: { loading: false, records: [...updatedRecords] } })
        })
      )
    }
  }
  
  @Action(DisableGwRecord)
  async disable({getState, setState, dispatch}: StateContext<GwStateModel>, { resource, payload }: DisableGwRecord) {
    const state = getState()
    
    setState(
      patch<GwStateModel>({
        index: patch({ [resource]: { loading: true, records: [...state.index[resource].records] } })
      })
    )
    
    let updatedRecords = state.index[resource].records
    
    try {
      const result = await this.api.disable(resource, `${payload.id}`) // FIXME not use ID
      
      if (result.success)
        updatedRecords = state.index[resource].records.filter( record => record.id !== payload.id)
      else {
        dispatch( new ToastError('The record could not be disabled.') )
        return
      }
        // throw new NgxsError('API did not return success: true.')
    } catch (error) {
      console.info(error.message)
      
      throw new NgxsError('Network error prevented the record from being deleted.')
    } finally {
      setState(
        patch<GwStateModel>({
          index: patch({ [resource]: { loading: false, records: [...updatedRecords] } })
        })
      )
    }
    
    dispatch( new ToastSuccess('Record disabled!') )
  }
  
  @Action(ClearGwRecords)
  clear({ setState }: StateContext<GwStateModel>, { resource }: ClearGwRecords) {
    setState(
      patch<GwStateModel>({
        index: patch({ [resource]: { loading: false, expiration: null, records: [] } })
      })
    )
  }
  
  @Action(RecordLogin)
  async recordLogin({dispatch}: StateContext<GwStateModel>, {assumedUsername}: RecordLogin) {
    let loginResponse
    
    try {
      loginResponse = this.api.login(assumedUsername)
    } catch (error) {
      console.error(error.message)
      dispatch( new ToastError('API Communication error'))
    }
    
    if (loginResponse && loginResponse.apiVersion && loginResponse.apiVersion !== environment.appVersion) {
      return dispatch( new ReloadPage() )
    }

  }
  
  @Action(ReloadPage)
  reloadPage() {
    location.reload()
    
    return
  }

}