import { Injectable } from '@angular/core'

import { Store } from '@ngxs/store'

import { UpdateKeepalive, ClearKeepalive } from '@app/ngxs'

/**
 * The purpose of this class is to discover when javascript execution has been suspended for too long
 * And to expire the session (clearing data).
 */

@Injectable({
  providedIn: 'root'
})
export class KeepaliveService {
  
  private intervalRef = null

  constructor(
    private store: Store,
  ) { }
  
  public init(): void {
    if (this.intervalRef) // already running
      return
    
    console.log('keepalive init')
    
    this.intervalRef = setInterval( () => {
      // console.log('keepalive ping')
      const currentTime = Date.now()
      
      this.store.dispatch(new UpdateKeepalive(currentTime))
    }, 15000) // Every 15 seconds
    
    
  }
  
  public cancel(): void {
    if (this.intervalRef) {
      // console.log('canceling the keepalive')
      
      clearInterval(this.intervalRef)
      this.intervalRef = null
      
      this.store.dispatch(new ClearKeepalive())
    }
      
  }
}
