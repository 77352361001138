import { Component, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnDestroy {
  config
  group: FormGroup
  processing: boolean = false

  constructor() {}
  
  subs: Subscription[] = []
  
  handle(fn) {
    this.processing = true
    
    // this.subs.push( fn(this.group.value)
    this.subs.push( fn(this.group.getRawValue())
      .pipe(
        finalize( () => this.processing = false )
      )
      .subscribe()
    )
  }
  
  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe()
    }
    
    this.subs = []
  }
}
