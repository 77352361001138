/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./confirm-sign-in.component";
import * as i3 from "@ngxs/store";
var styles_ConfirmSignInComponent = [];
var RenderType_ConfirmSignInComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmSignInComponent, data: {} });
export { RenderType_ConfirmSignInComponent as RenderType_ConfirmSignInComponent };
function View_ConfirmSignInComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmSignInComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Back to Sign In"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "label", [["class", "input-group-text w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Code"])), (_l()(), i0.ɵeld(7, 0, [["code", 1]], null, 0, "input", [["class", "form-control"], ["placeholder", "Code"], ["type", "text"]], null, [[null, "keyup"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.setCode(i0.ɵnov(_v, 7).value) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.onConfirm() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirm"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmSignInComponent_2)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_ConfirmSignInComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmSignInComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConfirmSignInComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "auth-confirm-sign-in", [], null, null, null, View_ConfirmSignInComponent_0, RenderType_ConfirmSignInComponent)), i0.ɵdid(1, 49152, null, 0, i2.ConfirmSignInComponent, [i3.Store], null, null)], null, null); }
var ConfirmSignInComponentNgFactory = i0.ɵccf("auth-confirm-sign-in", i2.ConfirmSignInComponent, View_ConfirmSignInComponent_Host_0, { authState: "authState" }, {}, []);
export { ConfirmSignInComponentNgFactory as ConfirmSignInComponentNgFactory };
