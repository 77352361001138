import { OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import { Store } from '@ngxs/store';
import { PingHeartbeat } from '@app/ngxs/heartbeat.ngxs';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class HeartbeatService {
    constructor(store) {
        this.store = store;
        this.start();
    }
    ngOnDestroy() {
        this.stop();
    }
    start() {
        this.ping();
        this.pingSub = interval(1000 * 60 * 4).subscribe(() => {
            this.ping();
        });
    }
    stop() {
        this.ping();
        this.pingSub.unsubscribe();
    }
    ping(param) {
        if (param) {
            this.store.dispatch(new PingHeartbeat(param));
        }
        else {
            this.store.dispatch(new PingHeartbeat());
        }
    }
}
HeartbeatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeartbeatService_Factory() { return new HeartbeatService(i0.ɵɵinject(i1.Store)); }, token: HeartbeatService, providedIn: "root" });
