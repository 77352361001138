import { Store } from '@ngxs/store';
import { UpdateKeepalive, ClearKeepalive } from '@app/ngxs';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
/**
 * The purpose of this class is to discover when javascript execution has been suspended for too long
 * And to expire the session (clearing data).
 */
export class KeepaliveService {
    constructor(store) {
        this.store = store;
        this.intervalRef = null;
    }
    init() {
        if (this.intervalRef) // already running
            return;
        console.log('keepalive init');
        this.intervalRef = setInterval(() => {
            // console.log('keepalive ping')
            const currentTime = Date.now();
            this.store.dispatch(new UpdateKeepalive(currentTime));
        }, 15000); // Every 15 seconds
    }
    cancel() {
        if (this.intervalRef) {
            // console.log('canceling the keepalive')
            clearInterval(this.intervalRef);
            this.intervalRef = null;
            this.store.dispatch(new ClearKeepalive());
        }
    }
}
KeepaliveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KeepaliveService_Factory() { return new KeepaliveService(i0.ɵɵinject(i1.Store)); }, token: KeepaliveService, providedIn: "root" });
