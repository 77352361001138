import { Component, OnInit } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  
  version: string

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.version = environment.appVersion
  }

}
