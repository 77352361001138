import { State, Action, StateContext, Selector } from '@ngxs/store'

export class UpdateKeepalive {
  static readonly type = '[Keepalive] Update'
  
  constructor(public timestamp: number) {}
}

export class ClearKeepalive {
  static readonly type = '[Keepalive] Clear'
  
  constructor() {}
}

// currentTimestamp (timestamp from keepalive service - the time its function was called)
// previousTimestamp (previous value of currentTimestamp, if any)
// expired (unused)
// currentTheNow (timestamp from inside ngxs, which may be significantly different than from the keepalive service)
export class KeepaliveStateModel {
  currentTimestamp: number
  previousTimestamp: number
  currentTheNow: number
  expired: boolean
  
}

@State<KeepaliveStateModel>({
  name: 'keepalive',
  defaults: {
    previousTimestamp: null,
    expired: false,
    currentTimestamp: null,
    currentTheNow: null,
  },
})
export class KeepaliveState {
  
  constructor() {}
  
  @Selector()
  static getKeepalive(state: KeepaliveStateModel) {
    return state
  }
  
  @Action(ClearKeepalive)
  clearKeepalive({ setState }: StateContext<KeepaliveStateModel>, event: ClearKeepalive) {
    setState({
      previousTimestamp: null,
      expired: false,
      currentTheNow: null,
      currentTimestamp: null,
    })
  }
  
  @Action(UpdateKeepalive)
  updateKeepalive({ getState, setState }: StateContext<KeepaliveStateModel>, { timestamp }:  UpdateKeepalive) {
    const state = getState()
    
    const theNow = Date.now()
    
    if (theNow - timestamp > 1000) {
      console.log('component time vs store time', theNow, timestamp, theNow - timestamp)
    }
    
    if (
      state.previousTimestamp
        &&
      timestamp - state.previousTimestamp > 900000
    ) {
      console.log('15 minute idle detected')
      console.log('signout + auth clear')
    }
    
    setState({
      previousTimestamp: state.currentTimestamp,
      currentTimestamp: timestamp,
      currentTheNow: theNow,
      expired: false,
    })
  }
}