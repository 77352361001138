import { Component, OnInit } from '@angular/core'

import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-bs-reference',
  templateUrl: './bs-reference.component.html',
  styleUrls: ['./bs-reference.component.css']
})
export class BsReferenceComponent implements OnInit {

  constructor(
    private toaster: ToastrService,
  ) { }

  ngOnInit() {}
  
  toastSuccess() {
    this.toaster.success('Success!', 'Toastr fun!', { timeOut: 500000, progressBar: true })
  }
  
  toastError() {
    this.toaster.error('Error!', 'Err', { closeButton: true, positionClass: 'toast-top-center' })
  } 

  toastWarning() {
    this.toaster.warning('Warning!', 'Warn', { closeButton: true })
  }
  
  toastInfo() {
    this.toaster.info('Info!')
  }
  
  toastShow() {
    this.toaster.show('Show!', 'shower', { timeOut: 50000 })
  }

}
