import { Component, Input, OnChanges } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

@Component({
  selector: 'app-s-toggle',
  templateUrl: './s-toggle.component.html',
  styleUrls: ['./s-toggle.component.css']
})
export class SToggleComponent { // implements OnChanges {

  @Input()
  config: any

  @Input()
  group: FormGroup
  
  controlRef: FormControl
  
  toggleVal: boolean

  constructor() { }

  toggle(event: boolean): void {
    this.group.patchValue({[this.config.name]: event})
  }

  ngOnChanges() {
    if (this.controlRef) {
      this.toggleVal = this.config.value
      this.controlRef.patchValue( this.config.value )
      this.controlRef.setValidators( this.config.validators )
    } else if (this.config) {
      this.toggleVal = !!this.config.value || !!this.config.default
      this.group.addControl(this.config.name, new FormControl({ value: this.toggleVal, disabled: this.config.options && this.config.options.readOnly }, this.config.validators))
    }
  }
  
  public reset(): void {
    this.toggleVal = !!this.config.value || !!this.config.default
    this.group.patchValue({[this.config.name]: this.toggleVal})
  }

}
