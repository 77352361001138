<div class="card mt-2">
  <ul class="list-group list-group-flush">
    <!-- IGNORE <a routerLink="/manage/merchant/{{session.selected['merchants']?.id}}" closeSidebar class="text-center font-weight-bold list-group-item list-group-item-action">-->
    <span class="text-center font-weight-bold list-group-item list-group-item-action">
      {{ session.selected['merchants']?.name || 'Selected Merchant' }}
    </span>
    
    <!--<a routerLink="/merchant/transaction-search" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <a routerLink="/merchant/transaction-search" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-search-dollar pr-2"></i>
      Search Transactions
    </a>
    
    <!--<a routerLink="/merchant/analytics" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <a routerLink="/merchant/analytics" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-chart-area pr-2"></i>
      Analytics Dashboard
    </a>
    
    <!--<a routerLink="/merchant/reports" closeSidebar class="list-group-item list-group-item-dark list-group-item-action disabled">-->
    <a routerLink="/merchant/reports" class="list-group-item list-group-item-dark disabled">
      <i class="fas fa-file-alt pr-2"></i>
      Reports <small>(Coming Soon)</small>
    </a>

    <!--<a routerLink="/users" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <!--  <i class="fas fa-users pr-2"></i>-->
    <!--  Users-->
    <!--</a>-->
    <!--<a routerLink="/offices" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <!--  <i class="fas fa-building pr-2"></i>-->
    <!--  Offices-->
    <!--</a>-->
    <!--<a routerLink="/mids" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <!--  <i class="fas fa-id-card pr-2"></i>-->
    <!--  MIDs-->
    <!--</a>-->
    <!--<a routerLink="/mid-groups" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <!--  <i class="fas fa-layer-group pr-2"></i>-->
    <!--  MID Groups-->
    <!--</a>-->
    <!--<a routerLink="/payments" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <!--  <i class="fas fa-credit-card pr-2"></i>-->
    <!--  Payments-->
    <!--</a>-->
    <!--<a routerLink="/routes" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <!--  <i class="fas fa-route pr-2"></i>-->
    <!--  Routes-->
    <!--</a>-->
  </ul>
</div>