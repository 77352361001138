<div class="form-group form-row mb-0" [formGroup]="group">
  <label class="col-sm-3 col-form-label text-sm-right" [for]="config.name">
    {{ config.label }}
  </label>
  <div class="col-sm-9">
    <div class="input-group" [ngClass]="{'border border-success rounded': controlRef.dirty && !editing}" (mouseenter)="hoverOn()" (mouseleave)="hoverOff()">
      <select
        (click)="makeEditable()"
        [ngClass]="(readOnly || !editing) ? 'form-control-plaintext' : 'form-control'"
        [name]="config.name"
        [formControlName]="config.name"
        [id]="config.name">
        <option *ngIf="config.options.nullable" [ngValue]="null">NULL</option>
        <option *ngIf="!config.options.nullable" value="" disabled>Choose</option>
        <option *ngFor="let option of config.options.select | async" [ngValue]="option.value">{{option.display}}</option>
      </select>
      <div class="input-group-append">
        <button *ngIf="!editing" [class.invisible]="!hovering" class="btn" type="button" (click)="makeEditable()"><i class="fas fa-edit"></i></button>
        <ng-container *ngIf="editing">
          <button class="btn btn-dark" type="button" (click)="markSave()"><i class="fas fa-check-circle text-primary"></i></button>
          <button class="btn btn-dark" type="button" (click)="markReset()"><i class="fas fa-times-circle text-secondary"></i></button>
        </ng-container>
      </div>
    </div>
    <small id="{{ config.name }}HelpBlock" class="form-text text-danger" [class.invisible]="group.get(config.name).status !== 'INVALID'">
      <span *ngIf="controlRef.errors?.nonZero && (group.touched || group.dirty)">Selection must not be zero.</span>
      &nbsp;
    </small>
  </div>
</div>