import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-merchant-form',
  templateUrl: './merchant-form.component.html',
  styleUrls: ['./merchant-form.component.scss']
})
export class MerchantFormComponent {
  @ViewChildren('nameControl, dbaControl, tierControl, onboardingDateControl, websiteControl, genContactControl, genPhoneControl, genEmailControl, execContactControl, execPhoneControl, execEmailControl, techContactControl, techPhoneControl, techEmailControl, opsContactControl, opsPhoneControl, opsEmailControl, affiliateIdControl, agencyIdControl, salesRepIdControl')
  controls: QueryList<any>
  
  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }
  
  public reset() {
    this.controls.forEach( control => control.reset() )
  }

}