import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core'
import { Router, RouterOutlet, Event, NavigationStart, NavigationEnd } from '@angular/router'

import { Store, Actions, ofActionErrored } from '@ngxs/store'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NgxSpinnerService } from '@app/errata/ngx-spinner/ngx-spinner.service'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subscription, Subject } from 'rxjs'
import { filter, takeUntil, distinctUntilChanged } from 'rxjs/operators'

import { SessionStateModel, SessionState, BeginSession, AppSignout, Authorizer, InitCredentials } from '@app/ngxs'
import { fadeOutIn } from '@app/animations/animations'
import { KeepaliveService, HeartbeatService } from '@app/services'

import { AboutComponent } from '@app/ui/modals/about/about.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ fadeOutIn ]
})
export class AppComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>()
  idleState$: Subscription
  
  private _animate: boolean = true // Used in template.
  private _opened: boolean = false
  
  sessionStateModel$: Observable<SessionStateModel>
  keepaliveStateModel$: Subscription

  session: SessionStateModel = new SessionStateModel()
  
  authState: any
  
  @ViewChild('idleModal', { static: false })
  idleModal: TemplateRef<any>
  
  idleState: 'string'

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private keepalive: KeepaliveService,
    private heartbeat: HeartbeatService,
  ) {
    this.sessionStateModel$ = this.store.select(state => state.session)
    
    // Cognito
    this.store.select(SessionState.getAuthState)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe((authState: Authorizer) => {
        this.authState = authState
        
        // console.log('authstate', authState)
        
        if (authState.state === 'signedIn') {
          this._opened = false
          console.log('starting keepalive')
          this.keepalive.init()
        }
      })
      
    // IdleService
    this.idleState$ = this.store.select(state => state.session.idle.state)
      .pipe(
        distinctUntilChanged(),
        filter(s => s),
        takeUntil(this.destroyed$),
      )
      .subscribe(idleState => {
        this.idleState = idleState
        
        // console.log('state.idle changed! New value is ', idleState)
        
        if(idleState === 'You will be logged out soon.') {
          this.modalService.open(this.idleModal, { windowClass: 'fadeInDown', centered: true })
        }
      })
    
    // KeepAlive
    this.keepaliveStateModel$ = this.store.select( state => state.keepalive )
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe( ({ currentTheNow, currentTimestamp, previousTimestamp }) => {
        // const theNowNow = Date.now()
        
        // console.log('checking keepalive', currentTimestamp, previousTimestamp, currentTheNow, theNowNow)
        
        if (currentTheNow - currentTimestamp > 1000) {
          console.warn('delayed keepalive activity')
        }
    
        if (
          previousTimestamp
            &&
          currentTimestamp - previousTimestamp > 900000
        ) {
          console.warn('15 minute idle detected')
          this.signout()
        }
      })
  }
  
  ngOnInit() {

    this.sessionStateModel$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe(state => {
        ['signIn', 'signedOut', 'signedUp']
          .includes(state.auth.state.state)
            ? this.spinner.hide()
            : state.loading
              ? this.spinner.show()
              : this.spinner.hide();
            
        this.session = state

        // Opens side bar for first time when the session is ready
        // this._opened = (this.session && !this.session.loading)
      })
      
    this.actions$.pipe( ofActionErrored(BeginSession) )
      .subscribe( () => {
        this.toaster.error('Session initialization error!')
          
        this.router.navigate(['/app-error'])
      })
    
    this.initRouteLoadingIndicator()
    this.initToaster()
  }
  
  ngAfterViewInit() {
    this.initCredentials()
  }
  
  // Does this ever even get called?
  ngOnDestroy() {
    console.info('Goodbye!')
    this.heartbeat.stop()
    
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
  
  /**
   * When state changes to 'signedIn', fetches this user's information from the API.
   */
  private initCredentials() {
		this.store.dispatch(new InitCredentials(this.idleModal))
  }
  
  /**
   * Subscribes to route changes and shows or hides a loading animation appropriately.
   */
  private initRouteLoadingIndicator() {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe( (event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.modalService.dismissAll()
            this.toaster.clear()
            this.spinner.show()
            break
          }
          
          case event instanceof NavigationEnd:
          default: {
            this.spinner.hide()
            // FIXME - there may be other Router Events; however, these should be all that involve navigation
            // https://angular.io/guide/router#router-events
            break
          }
        }
      })
  }
  
  private initToaster() {
    this.store.select(state => state['toaster'])
      .pipe(
        filter( t => t ),
        takeUntil(this.destroyed$)
      )
      .subscribe(t => {
        switch (t.tType) {
          case 'success':
            this.toaster.success(t.message, t.title, t.options)
            break
          case 'error':
            this.toaster.error(t.message, t.title, t.options)
            break
          case 'warning':
            this.toaster.warning(t.message, t.title, t.options)
            break
          case 'info':
            this.toaster.info(t.message, t.title, t.options)
            break
          default:
            console.warn(`Unknown toast type: |${t.tType}|`, t.tType)
        }
      })
  }

  open(content) {
    const modalRef = this.modalService.open(content, { windowClass: 'slideDown', centered: true })
    
    return modalRef
  }
  
  openAboutMenu() {
    const modalRef = this.modalService.open(AboutComponent, { windowClass: 'slideDown', centered: true })
    
    return modalRef
  }
  
  public signout(): void {
    this.keepalive.cancel()
		this.store.dispatch(new AppSignout())
    this.spinner.hide() // FIXME - changes can't be detected within ngxs
    this.modalService.dismissAll()
  }

  private _toggleSidebar() { // Used in template
    this._opened = !this._opened
  }
  
  loading(): void {
    this.spinner.show()
    
    setTimeout( () => this.spinner.hide(), 5000)
  }
  
  routerState(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
  }
}