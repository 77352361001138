<ng-container [formGroup]="group">
  
  <div class="form-group form-row mb-0" formArrayName="passwordMeta" (mouseenter)="hoverOn()" (mouseleave)="hoverOff()">
    <label class="col-sm-3 col-form-label text-sm-right" [for]="config.name">{{ config.label }}</label>
    <div class="col-sm-9">
      <div class="input-group" [ngClass]="{'border border-primary rounded': controlRef.dirty && !editing}">
        <input
          (click)="makeEditable()"
          [ngClass]="(readOnly || !editing) ? 'form-control-plaintext' : 'form-control'"
          type="password"
          [id]="config.name"
          [name]="config.name"
          formControlName="0"
          [placeholder]="config.placeholder"
          [readonly]="readOnly || !editing"/>
        <div class="input-group-append">
          <button *ngIf="hovering" class="btn" type="button" (click)="makeEditable()"><i class="fas fa-edit"></i></button>
        </div>
      </div>
      <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="controlA.status !== 'INVALID'">
        <!--<app-error-messages *ngIf="(controlRef.touched || controlRef.dirty) && controlRef.errors" [errors]="controlRef.errors"></app-error-messages>-->
        <span *ngIf="controlA.errors?.required">This field is required.</span>
      </small>
    </div>
  </div>
  <div class="form-group form-row mb-0" formArrayName="passwordMeta" (mouseenter)="hoverOn()" (mouseleave)="hoverOff()">
    <label class="col-sm-3 col-form-label text-sm-right" [for]="config.name">Confirm</label>
    <div class="col-sm-9">
      <div class="input-group" [ngClass]="{'border border-primary rounded': controlRef.dirty && !editing}">
        <input
          (click)="makeEditable()"
          [ngClass]="(readOnly || !editing) ? 'form-control-plaintext' : 'form-control'"
          type="password"
          [id]="config.name + 'Confirmation'"
          [name]="config.name"
          formControlName="1"
          [placeholder]="config.placeholder"
          [readonly]="readOnly || !editing"/>
        <div class="input-group-append">
          <ng-container *ngIf="editing">
            <button class="btn btn-dark" type="button" (click)="markSave()"><i class="fas fa-check-circle text-primary"></i></button>
            <button class="btn btn-dark" type="button" (click)="markReset()"><i class="fas fa-times-circle text-secondary"></i></button>
          </ng-container>
        </div>
      </div>
      <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="controlArray.status !== 'INVALID'">
        <span *ngIf="controlArray.errors?.passwordMismatch && (group.touched || group.dirty)">Passwords do not match.</span>
        &nbsp;
      </small>
    </div>
  </div>
  
</ng-container>