<app-s-hidden [config]="fields['id']" [group]="formGroup"></app-s-hidden>
<app-s-text #nameControl [config]="fields['name']" [group]="formGroup"></app-s-text>
<app-s-text #dbaControl [config]="fields['dba']" [group]="formGroup"></app-s-text>
<app-s-text #tierControl [config]="fields['tier']" [group]="formGroup"></app-s-text>
<app-s-text #onboardingDateControl [config]="fields['onboarding_date']" [group]="formGroup"></app-s-text>
<app-s-text #websiteControl [config]="fields['website']" [group]="formGroup"></app-s-text>
<app-s-text #genContactControl [config]="fields['gen_contact']" [group]="formGroup"></app-s-text>
<app-s-text #genPhoneControl [config]="fields['gen_phone']" [group]="formGroup"></app-s-text>
<app-s-text #genEmailControl [config]="fields['gen_email']" [group]="formGroup"></app-s-text>
<app-s-text #execContactControl [config]="fields['exec_contact']" [group]="formGroup"></app-s-text>
<app-s-text #execPhoneControl [config]="fields['exec_phone']" [group]="formGroup"></app-s-text>
<app-s-text #execEmailControl [config]="fields['exec_email']" [group]="formGroup"></app-s-text>
<app-s-text #techContactControl [config]="fields['tech_contact']" [group]="formGroup"></app-s-text>
<app-s-text #techPhoneControl [config]="fields['tech_phone']" [group]="formGroup"></app-s-text>
<app-s-text #techEmailControl [config]="fields['tech_email']" [group]="formGroup"></app-s-text>
<app-s-text #opsContactControl [config]="fields['ops_contact']" [group]="formGroup"></app-s-text>
<app-s-text #opsPhoneControl [config]="fields['ops_phone']" [group]="formGroup"></app-s-text>
<app-s-text #opsEmailControl [config]="fields['ops_email']" [group]="formGroup"></app-s-text>
<app-s-select #affiliateIdControl [config]="fields['affiliate_id']" [group]="formGroup"></app-s-select>
<app-s-select #agencyIdControl [config]="fields['agency_id']" [group]="formGroup"></app-s-select>
<app-s-select #salesRepIdControl [config]="fields['sales_rep_id']" [group]="formGroup"></app-s-select>
<app-s-select #statusIdControl [config]="fields['status_id']" [group]="formGroup"></app-s-select>