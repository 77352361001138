import { Component, Input, OnChanges } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

@Component({
  selector: 'app-s-select',
  templateUrl: './s-select.component.html',
  styleUrls: ['./s-select.component.css']
})
export class SSelectComponent implements OnChanges {
  // _config: any
  @Input()
  config: any
  // set config(c) {
  //   if (c) {
  //     this._config = c
  //     this.group.addControl(this.config.name, this.controlRef = new FormControl({ value: this.config.value || this.config.default, disabled: this.config.options.readOnly}, this.config.validators))
  //     this.readOnly = this.config.options && this.config.options.readOnly
  //   }
  // }
  
  // get config() {
  //   return this._config
  // }
  
  @Input()
  group: FormGroup
  
  readOnly: boolean
  editing: boolean = false
  
  controlRef: FormControl

  constructor() { }
  
  ngOnChanges() {
    if (this.controlRef) {
      this.controlRef.patchValue( this.config.value )
      this.controlRef.setValidators( this.config.validators )
    } else if (this.config) {
      this.group.addControl(this.config.name, this.controlRef = new FormControl({ value: this.config.value || this.config.default, disabled: this.config.options.readOnly}, this.config.validators))
      this.readOnly = this.config.options && this.config.options.readOnly
    }
  }
  
  editable(val: boolean) {
    this.editing = val && !this.readOnly
  }
  
  public reset() {
    this.controlRef.reset({ value: this.config.value || this.config.default, disabled: this.config.options && this.config.options.readOnly})
    this.editing = false
  }
}
