<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#ffffff" type="ball-atom"></ngx-spinner>

<ng-container [ngSwitch]="authState?.state">

  <ng-template [ngSwitchCase]="'signedIn'">

    <ng-sidebar-container class="position-absolute">

      <ng-sidebar #sidey [(opened)]="_opened" [mode]="'over'" [showBackdrop]="false" [closeOnClickBackdrop]="true" [animate]="_animate" [autoCollapseWidth]="500" [autoFocus]="false">
        
        <div style="height: 100%;" class="bg-dark">
          <header class="shadow">
            <nav class="navbar navbar-default bg-primary py-0" style="height: 60px;">
              <span class="navbar-text"></span>
              
              <button closeSidebar class="btn btn-outline-secondary navbar-btn shadow px-4 my-1" aria-label="Close Main Menu"><i class="fas fa-times"></i></button>
            </nav>
            <div class="bg-primary p-0 m-0" style="height: 6px;"></div>
          </header>
          
          <div class="px-2 bg-dark">
            <app-main-menu [session]="session"></app-main-menu>
            
            <div class="d-flex justify-content-center bg-dark py-5">
              <img src="assets/img/coalesce-menu-logo.svg" class="img-fluid align-self-center bg-dark m-0 p-0" style="max-width: 7rem;" alt="Coalesce logo">
            </div>
            
          </div>
          
        </div>
      </ng-sidebar>
      
      <div ng-sidebar-content>
        <header class="sticky-top shadow">
          <nav class="navbar navbar-default bg-dark py-0" style="height: 60px;">
            <button class="btn btn-primary navbar-btn text-muted shadow px-4 my-1" (click)="sidey.open()" aria-label="Open Main Menu"><i class="fas fa-bars"></i></button>
 
            <div class="navbar-text mt-0 p-0">
              <img src="assets/img/STEPpay-logo-navbar.svg" class="img-fluidalign-self-center d-none d-sm-block m-0 py-0" height="54" style="max-height: 54px;" alt="STEPpay.com logo">
            </div>
            
            <div ngbDropdown class="d-inline-block my-1" placement="bottom-right" display="dynamic">
              <button class="btn btn btn-primary navbar-btn text-muted shadow" id="userMenu" ngbDropdownToggle style="overflow:true" aria-label="Open User Menu">
                <i class="fas fa-user-circle mr-2"></i>
                <span *ngIf="!session.loading && session.currentUser; else sessionUserLoader" class="mr-2">{{ session.currentUser.username }}</span>
              </button>
              
              <div ngbDropdownMenu aria-labelledby="userMenu">
                <h6 *ngIf="!session.loading && session.currentUser; else sessionUserLoader" class="dropdown-header text-light">{{ session.currentUser.full_name }}</h6>
                
                <ng-template #usernameLoader>
                  <div class="ph-item mb-0" style="width: 12em;"></div>
                </ng-template>
                
                <div class="dropdown-divider"></div>
                
                <a class="dropdown-item" routerLink="account/profile"><i class="fas fa-address-card mr-2"></i>My Profile</a>
                
                <app-superuser-config-menu *ngIf="session.roles.superuser" (bsRefModal)="open(bsRefModal)" (loadingAnimation)="loading()" (idleModal)="open(idleModal)"></app-superuser-config-menu>
                
                <div class="dropdown-divider"></div>
                
                <button class="dropdown-item" (click)="signout()"><i class="fas fa-sign-out-alt mr-2"></i>Sign Out</button>
                
                <button class="dropdown-item" (click)="openAboutMenu()"><i class="fas fa-info-circle mr-2"></i>About</button>
              </div>
            </div>
          </nav>
          <div class="bg-primary p-0 m-0" style="height: 6px;"></div>
        </header>
        
        <main id="maincontent" class="mb-5">
          <div class="container" [@fadeOutIn]="routerState(outlet)">
            <router-outlet #outlet="outlet" *ngIf="!session.loading"></router-outlet>
          </div>
          
        </main>
        
        <div style="clearfix w-100"></div>
        
        <app-universal-footer></app-universal-footer>
        
      </div>
      
<!--<div style="clear:both;"></div>-->
    </ng-sidebar-container>
  </ng-template>
  
  <ng-template ngSwitchDefault>
    <main id="maincontent">
      <authenticator-component></authenticator-component>
    </main>

  </ng-template>

  <!--<div class="fixed-bottom">-->
  <!--  <div class="container">-->
  <!--    <p class="text-center">-->
  <!--    <small class="text-muted">-->
  <!--		  &copy; 2010-2019 <span class="text-success">Coalesce Holdings, LLC</span> or its subsidiaries. All rights reserved. <a target="_blank" href="https://agreements.coalescepayments.com/" class="text-muted">Terms of Service | Privacy</a> &bull; <a target="_blank" href="http://status.coalescepayments.com/" class="text-muted">Status</a>-->
  <!--		</small>-->
  <!--		</p>-->
  <!--  </div>-->
  <!--</div>-->
    
</ng-container>

<ng-template #sessionUserLoader>
  <span class="ph-item m-0" style="width: 8em; display: inline-block;"></span>
</ng-template>

<ng-template #idleModal let-c="close" let-d="dismiss">
  
  <div class="modal-header bg-info">
    <h4 class="modal-title text-light"><i class="fas fa-user-clock mr-2"></i>You've gone idle.</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close Idle Modal" (click)="d()">
      <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p ngbAutofocus>{{ idleState }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="c()">Continue</button>
  </div>

</ng-template>

<ng-template #bsRefModal let-c="close" let-d="dismiss">

  <div class="modal-header bg-primary">
    <h4 class="modal-title"><i class="fas fa-swatchbook mr-2"></i>Bootstrap Reference</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close Bootstrap Reference Modal" (click)="d()">
      <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <app-bs-reference></app-bs-reference>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="d()">Continue</button>
  </div>
  
</ng-template>