import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PRIMARY_SPINNER, NgxSpinner } from './ngx-spinner';
import * as i0 from "@angular/core";
export class NgxSpinnerService {
    /**
     * Creates an instance of NgxSpinnerService.
     * @memberof NgxSpinnerService
     */
    constructor() {
        /**
         * Spinner observable
         *
         * @memberof NgxSpinnerService
         */
        this.spinnerObservable = new Subject();
    }
    /**
    * Get subscription of desired spinner
    * @memberof NgxSpinnerService
    **/
    getSpinner(name) {
        return this.spinnerObservable.asObservable().pipe(filter((x) => x && x.name == name));
    }
    /**
     * To show spinner
     *
     * @memberof NgxSpinnerService
     */
    show(name = PRIMARY_SPINNER, spinner) {
        if (spinner) {
            spinner['name'] = name;
            this.spinnerObservable.next(new NgxSpinner(spinner));
        }
        else {
            this.spinnerObservable.next(new NgxSpinner({ name, show: true }));
        }
    }
    /**
    * To hide spinner
    *
    * @memberof NgxSpinnerService
    */
    hide(name = PRIMARY_SPINNER) {
        this.spinnerObservable.next(new NgxSpinner({ name, show: false }));
    }
}
NgxSpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NgxSpinnerService_Factory() { return new NgxSpinnerService(); }, token: NgxSpinnerService, providedIn: "root" });
