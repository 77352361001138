import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

// import { AmplifyService } from 'aws-amplify-angular'
import Auth from '@aws-amplify/auth'

import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'
import { environment } from '../../../environments/environment'
import { Observable, from } from 'rxjs'
import { switchMap } from 'rxjs/operators'

export interface ApiOutcome {
  success: boolean
  id?: number
  clobbered?: boolean
}

export interface UniqueOutcome {
  unique: boolean
}

export interface LoginOutcome {
  logged: boolean
  apiVersion: string
}

export interface QuickSightResponse {
  Status?: number
  EmbedUrl?: string
  RequestId?: string
  errorMessage?: string
}

export interface LegacyResponse {
  exceptionMessage?: string
  errorMessage?: string
  transactionId?: number
  result?: string
  resultmsg?: string
  auth?: string
}

export interface HeartbeatResponse {
  errorMessage?: string
  message?: string
}

@Injectable({
  providedIn: 'root'
})
export class GatewayApiService {
  private apiUri: string = environment.gwApiUri
  private legacyUri: string = environment.legacyUri

  constructor(
    // private amplify: AmplifyService,
    private http: HttpClient
  ) { }
  
  /**
   * Should return a single item, if it exists.  Our API always returns an array
   * so here we will reduce this to a single item.
   */
  async getOne<T extends IApiResourceRecordModel>(resource: string, id: string): Promise<T|null> {
    let result: T[]
    
    try {
      result = await this.http.get<T[]>(`${this.apiUri}/resource/${resource}`, await this.makeOptions({id: id})).toPromise()
    } catch (err) {
      console.log(err.message)
      return null
    }
    
    if (result.length === 1) {
      return result[0]
    }
    
    // FIXME perhaps throw an error at this point.
    
    return null
  }
  
  async getAll<T extends IApiResourceRecordModel>(resource: string, payload: any = null): Promise<Array<T>|null> {
    try {
      return await this.http.post<T[]>(`${this.apiUri}/resource/${resource}`, { action: 'READ', payload: payload || {} }, await this.makeOptions()).toPromise()
    } catch (error) {
      console.error(error.message)
    }
    
    return null
  }
  
  async getPage<T extends IApiResourceRecordModel>(resource: string, payload: any = null, page: number): Promise<Array<T>> {
    try {
      return await this.http.get<T[]>(`${this.apiUri}/paged/${resource}`, await this.makeOptions(payload)).toPromise()
    } catch (error) {
      console.error(error.message) // FIXME error service
      throw error
    }
  }
  
  async create<T extends IApiResourceRecordModel>(resource: string, payload: any): Promise<T|null> {
    try {
      return await this.http.post<T>(`${this.apiUri}/resource/${resource}`, { action: 'CREATE', payload: payload }, await this.makeOptions()).toPromise()
    } catch (err) {
      console.log(err.message)
      return null
    }
  }
  
  async update<T extends IApiResourceRecordModel>(resource: string, payload: any, previous: any): Promise<ApiOutcome> { // Promise<T|null> {
    try {
      return await this.http.post<ApiOutcome>(`${this.apiUri}/resource/${resource}`, { action: 'UPDATE', payload, previous }, await this.makeOptions()).toPromise()
    } catch (err) {
      console.log(err.message)
      return { success: false }
    }
  }
  
  async disable(resource: string, id: string): Promise<ApiOutcome> {
    // FIXME id may be compound
    const payload = {
      id: {
        op: '=',
        val: id,
      },
      status: {
        op: '=',
        val: 'inactive'
      }
    }
  
    try {
      return await this.http.post<ApiOutcome>(`${this.apiUri}/resource/${resource}`, { action: 'DELETE', payload: payload }, await this.makeOptions()).toPromise()
    } catch (err) {
      console.log(err.message)
      return { success: false }
    }
  }
  
  async makeOptions(params: any = null): Promise<{ headers?: HttpHeaders | { [header: string]: string | string[]; }; observe?: 'body'; params?: HttpParams | { [param: string]: string | string[]; }; reportProgress?: boolean; responseType?: 'json'; withCredentials?: boolean; }> {
    const options: any = {
      headers: new HttpHeaders({
        // 'Accept-Encoding': '*', // FIXME - API Gateway says I must set this header to something but Fetch API doesn't allow it.
        'Content-Type': 'application/json',
        // 'Authorization': (await this.amplify.auth().currentSession()).getIdToken().getJwtToken()
        'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
      })
    }
    
    if (params) {
      options.params = new HttpParams({
        fromObject: params
      })
    }
    
    return options
  }
  
  unique(table: string, field: string, term: string): Observable<UniqueOutcome> {
    return from( this.makeOptions({ term: term }) )
      .pipe(
        switchMap( opts => this.http.get<UniqueOutcome>(`${this.apiUri}/unique/${table}/${field}`, opts) )
        // FIXME find out what 'opts' is.
      )
  }
  
  async login<LoginOutcome>(assumedUsername: string): Promise<LoginOutcome|null> {
    try {
      return await this.http.post<LoginOutcome>(`${this.apiUri}/login/${assumedUsername}`, undefined, await this.makeOptions()).toPromise()
    } catch (error) {
      console.error(error.message)
      return null
    }
  }
  
  async getQuickSightDashboard<QuickSightResponse>(payload: any): Promise<QuickSightResponse> {
    try {
      return await this.http.get<QuickSightResponse>(`${this.apiUri}/quicksight/get-dashboard`, await this.makeOptions(payload)).toPromise()
    } catch (error) {
      console.error(error.message)
      throw error
    }
  }
  
  async legacySystem<LegacyResponse>(payload: any): Promise<LegacyResponse> {
    try {
      return await this.http.get<LegacyResponse>(this.legacyUri, await this.makeOptions(payload)).toPromise()
    } catch (error) {
      console.log(error.message)
      
      if (error.status > 0 && error.error)
        return error.error
      
      throw error
    }
  }
  
  async heartbeat(payload?: any): Promise<HeartbeatResponse> {
    try {
      const accessToken = (await Auth.currentSession()).getAccessToken()["jwtToken"]
      
      return await this.http.post<HeartbeatResponse>(`${this.apiUri}/heartbeat/checkin`,  { "accessToken": accessToken }, await this.makeOptions()).toPromise()
    } catch (error) {
      console.error(error)
      return null
    }
  }
}