import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-affiliate-form',
  templateUrl: './affiliate-form.component.html',
  styleUrls: ['./affiliate-form.component.scss']
})
export class AffiliateFormComponent {
  constructor() { }
  
  @ViewChildren('nameControl, isHotControl, tierControl, contactControl, phonesControl, emailControl, websiteControl, statusControl')
  controls: QueryList<any>
  
  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  public reset() {
    this.controls.forEach( control => control.reset() )
  }
}