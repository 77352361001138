<ng-template #loader>
  <div class="ph-item">
    <div class="ph-col-12"></div>
  </div>
</ng-template>

<div *ngIf="config; else loader" class="form-group form-row align-items-center mb-1" [formGroup]="formGroup">
  <div class="col-sm-3 text-sm-right">
    <label class="col-form-label" [for]="config.name">{{ config.label }}</label>
  </div>
  <div class="col-sm-9 d-inline-flex">
    <div class="custom-control custom-switch align-self-center">
      <input
        type="checkbox"
        class="custom-control-input"
        [id]="config.name"
        [name]="config.name"
        [formControlName]="config.name">
      <label class="custom-control-label" [for]="config.name">&nbsp;</label>
    </div>
    <button
      type="button"
      class="btn ml-auto"
      placement="right"
      ngbPopover="{{ config.helpText }}">
      <i class="fas fa-question-circle"></i>
    </button>
  </div>
</div>

<div [ngClass]="{'border border-secondary': config}" class="rounded rounded-lg mb-3 pl-1">
  <ng-content></ng-content>
</div>