import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'
import { filter, map, distinctUntilChanged } from 'rxjs/operators'

import { Store, Actions } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { GwFormContainerComponent } from '@app/ui/templates/gw-resource/gw-form-container/gw-form-container.component'
import { ProjectorService } from '@app/services/gateway-api'
import { UpdateGwRecord, DisableGwRecord } from '@app/ngxs/gateway-api'
import { GwResource } from '@app/models/gateway-api'
import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'
import { ToastWarning } from '@app/ngxs/toaster.ngxs'

@Component({
  selector: 'app-view-edit-resource',
  templateUrl: './view-edit-resource.component.html',
  styleUrls: ['./view-edit-resource.component.scss']
})
export class ViewEditResourceComponent implements OnInit {
  @ViewChild('disableModal', { static: true })
  public disableModal: TemplateRef<any>
   
  @ViewChild(GwFormContainerComponent, { static: true })
  public formContainer: GwFormContainerComponent
  
  @Input()
  resource: GwResource
  
  @Input()
  title: string
  
  @Input()
  subtitle: string
  
  @Input()
  noButtons: boolean = false
  
  @Input()
  resourceAlias: string
  
  _record$: Observable<{ loading: boolean, data: IApiResourceRecordModel }>
  _record$Sub: Subscription
  _fieldsSub: Subscription
  
  private previousResourceValues: IApiResourceRecordModel = {}
  
  @Input()
  // record$: Observable<{ loading: boolean, data: IApiResourceRecordModel }>
  set record$(rec: Observable<{ loading: boolean, data: IApiResourceRecordModel }>) {
    if (!rec) return
    
    // console.log('record$ has a new value')
    
    if (this._record$Sub) {
      // console.log('unsubscribing record$Sub')
      this._record$Sub.unsubscribe()
    }
      
      
    if (this._fieldsSub) {
      // console.log('unsubscribing _fieldsSub')
      this._fieldsSub.unsubscribe()
    }
      
    this._record$ = rec
    this._record$Sub = this._record$
      .subscribe( r => {

        if (!r || (!r.loading && !r.data)) {
          console.log(`not found: ${JSON.stringify(r)}`)
          
          setTimeout( () => {
            this.store.dispatch( new ToastWarning('Returning...', 'Could not locate record!') )
            setTimeout( () => this.router.navigate(['/']), 3500 )
          })
          
          return
        }
        
        this.record = r
        
        if (this._fieldsSub) {
          // console.log('unsubscribing fieldsSub')
          this._fieldsSub.unsubscribe()
        }
          
          
        // console.log(`Subscribing to field changes for ${this.resource}`)
        
        this._fieldsSub = this.store.select( s => s['gwState'].index['api-manifest'] )
          .pipe(
            filter( s => s && s.records && s.records[0] ),
            distinctUntilChanged(),
          )
          .subscribe( s => {
            
            // console.log(`detected field change for ${this.resource}`)
            
            this.fields = {
              ...this.projector.projectStaticGwResource(s.records[0].manifest, this.resource, this.record.data, { validations: 'update' }),
            }
            
            // console.log('previous resource', this.previousResourceValues)
            
            for (const key in this.fields) {
              this.previousResourceValues[ key ] = this.fields[key].value
            }
            
            // console.log('fields changed', this.resource, this.fields)
          })
      })
    
  }
  
  get record$() {
    return this._record$
  }
  
  
  subs: Subscription[] = []
  
  record: any = { loading: true, data: null }
  
  roles: any = {}
  formGroup: FormGroup = new FormGroup({})
  
  fields: any = {}
  buttonFields: any = {
    'buttons': {
      type: 'button',
      buttons: [
        {
          icon: 'fas fa-undo-alt',
          label: 'Reset',
          fn: (event) => {
            return new Observable( (observer) => {
              this.formContainer.reset()
              observer.next()
              observer.complete()
            })
          }
        },
        {
          icon: 'fas fa-user-times',
          label: 'Disable',
          fn: (event) => { return this.openModal(this.disableModal) },
          primary: false
        }, {
          icon: 'fas fa-save',
          label: 'Save',
          fn: (event) => {
            const [updatedOpts, previousOpts] = [{}, {}]
            
            Object.keys(event).forEach( key => {
              updatedOpts[key] = {
                op: '=',
                val: event[key],
              }
            })
            
            Object.keys(this.previousResourceValues).forEach( key => {
              previousOpts[key] = {
                op: '=',
                val: this.previousResourceValues[key],
              }
            })
            
            return this.store.dispatch(new UpdateGwRecord(this.resource, updatedOpts, previousOpts))
          },
          primary: true
        }
      ]
    }
  }
  
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private projector: ProjectorService
  ) {}

  ngOnInit() {
    this.title = this.title || `viewing ${this.resourceAlias}`

    this.subs.push(
      
      // this.record$
      //   .subscribe( r => {
          
      //     // this.formGroup = new FormGroup({})
          
      //     if (!r || (!r.loading && !r.data)) {
      //       console.log(`not found: ${JSON.stringify(r)}`)
            
      //       setTimeout( () => {
      //         this.toaster.warning('Returning...', 'Could not locate record!', { positionClass: 'toast-bottom-center' })
      //         setTimeout( () => this.router.navigate(['/']), 3500 )
      //       })
            
      //       return
      //     }
          
      //     this.record = r
          
      //     this.subs.push(this.store.select( s => s['gwState'].index['api-manifest'] )
      //       .pipe(
      //         filter( s => s && s.records && s.records[0] )
      //       )
      //       .subscribe( s => {
              
      //         this.fields = {
      //           ...this.projector.projectStaticGwResource(s.records[0].manifest, this.resource, this.record.data),
      //           // ...this.fields,
      //         }
              
      //       })
      //     )
      //   }),
        
      this.store.select(s => s['session'])
        .pipe(
          // map(s => ({ loading: s.loading, isSuperuser: s.isSuperuser }))
          map(s => ({ loading: s.loading, isSuperuser: s.roles.superuser }))
        )
        .subscribe(s => this.roles['superuser'] = s.isSuperuser)
    )
  }
  
  ngOnDestroy() {
    if (this._fieldsSub)
      this._fieldsSub.unsubscribe()
    
    for (let sub of this.subs) {
      sub.unsubscribe()
    }
    
    this.subs = []
  }
  
  // save(event): void {
  //   console.log('Saving record.')

  //   this.store.dispatch(new UpdateGwRecord(this.resource, event, event))
  // }
  
  deleteRecord(event): void {
    this.store.dispatch(new DisableGwRecord(this.resource, event))
  }
  
  openModal(content) {
    return new Observable( (observer) => {
      this.modalService.open(content, {centered: true})
      observer.next()
      observer.complete()
    })
    // return new Promise( (resolve, reject) => {
    //   this.modalService.open(content, {centered: true})
    //   resolve()
    // })
  }

}