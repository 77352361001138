import { Injectable } from '@angular/core'
import { Router, Route, CanLoad, UrlSegment } from '@angular/router'
import { filter, take } from 'rxjs/operators'

import { Store } from '@ngxs/store'

// There is an unsolved issue with this service.  Signing out and switching users may not result in the correct URls being navigable.
// This is due to the filter condition on the store select.  Essentially, the previous value is getting read when it should be waiting for the values
// That show up after a Session.BeginSession takes place.
// In other words, delay navigation to the route until BeginSession has completed

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor(
    private router: Router,
    private store: Store,
  ) { }
  
  canLoad(route: Route, url: UrlSegment[]): Promise<boolean> {
    const path = route && route.path
    
    return this.store.select( s => {
      return s['session']
    })
    .pipe(
      filter( s => s && !s.loading && s.roles && s.currentUser ),
      take(1),
    )
    .toPromise()
    .then( (session): boolean => {
      // console.log('Promise got:', session)
      
      let returnVal = false
        
      switch (path) {
        case undefined:
          // console.log('path was undefined')
          returnVal = true
          break
        case 'merchant':
          // console.log('merchant case', session.roles.merchant)
          returnVal = session.roles.merchant
          break
        case 'crm':
          // console.log('crm case')
          returnVal = session.roles.superuser
          break
        case 'affiliate':
          // console.log('affiliate case')
          returnVal = session.roles.affiliate
          break
        default:
          // console.log('default case')
          returnVal = true
      }
      
      if (!returnVal)
        this.router.navigate(['/'])
      
      return returnVal
    })
    .catch( (error): boolean => {
      console.error('There was a problem retrieving role information for the current user:', error)
      return true
    })
  }
}
