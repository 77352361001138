/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ngx-spinner.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ngx-spinner.component";
import * as i4 from "./ngx-spinner.service";
var styles_NgxSpinnerComponent = [i0.styles];
var RenderType_NgxSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NgxSpinnerComponent, data: {} });
export { RenderType_NgxSpinnerComponent as RenderType_NgxSpinnerComponent };
function View_NgxSpinnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_NgxSpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "overlay"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-color": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], [[8, "className", 0], [4, "color", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NgxSpinnerComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "loading-text"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.spinner.bdColor); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.spinner.divArray; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.spinner.class; var currVal_2 = _co.spinner.color; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_NgxSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NgxSpinnerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NgxSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-spinner", [], null, null, null, View_NgxSpinnerComponent_0, RenderType_NgxSpinnerComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgxSpinnerComponent, [i4.NgxSpinnerService, [8, null]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NgxSpinnerComponentNgFactory = i1.ɵccf("ngx-spinner", i3.NgxSpinnerComponent, View_NgxSpinnerComponent_Host_0, { bdColor: "bdColor", size: "size", color: "color", type: "type" }, {}, ["*"]);
export { NgxSpinnerComponentNgFactory as NgxSpinnerComponentNgFactory };
