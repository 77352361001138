/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage-resource.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./view-edit-resource/view-edit-resource.component.ngfactory";
import * as i3 from "./view-edit-resource/view-edit-resource.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngxs/store";
import * as i6 from "ngx-toastr";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../services/gateway-api/projector.service";
import * as i9 from "./manage-resource.component";
var styles_ManageResourceComponent = [i0.styles];
var RenderType_ManageResourceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManageResourceComponent, data: {} });
export { RenderType_ManageResourceComponent as RenderType_ManageResourceComponent };
export function View_ManageResourceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-view-edit-resource", [], null, null, null, i2.View_ViewEditResourceComponent_0, i2.RenderType_ViewEditResourceComponent)), i1.ɵdid(3, 245760, null, 0, i3.ViewEditResourceComponent, [i4.Router, i5.Store, i5.Actions, i6.ToastrService, i7.NgbModal, i8.ProjectorService], { resource: [0, "resource"], resourceAlias: [1, "resourceAlias"], record$: [2, "record$"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.alias; var currVal_2 = _co.record$; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ManageResourceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-manage-resource", [], null, null, null, View_ManageResourceComponent_0, RenderType_ManageResourceComponent)), i1.ɵdid(1, 245760, null, 0, i9.ManageResourceComponent, [i4.ActivatedRoute, i8.ProjectorService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManageResourceComponentNgFactory = i1.ɵccf("app-manage-resource", i9.ManageResourceComponent, View_ManageResourceComponent_Host_0, {}, {}, []);
export { ManageResourceComponentNgFactory as ManageResourceComponentNgFactory };
