import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { StandardFormModule } from '@app/ui/s-form/standard-form.module'

import { GwFormContainerComponent } from './gw-form-container/gw-form-container.component'
import { UserFormComponent }        from './gw-form-container/user-form/user-form.component'
import { SuperuserFormComponent }   from './gw-form-container/superuser-form/superuser-form.component'
import { AffiliateFormComponent }   from './gw-form-container/affiliate-form/affiliate-form.component'
import { MerchantFormComponent } from './gw-form-container/merchant-form/merchant-form.component'
import { OfficeFormComponent } from './gw-form-container/office-form/office-form.component'
import { MidGroupFormComponent } from './gw-form-container/mid-group-form/mid-group-form.component'
import { MidFormComponent } from './gw-form-container/mid-form/mid-form.component'
import { RouteFormComponent } from './gw-form-container/route-form/route-form.component'
import { PaymentTypeFormComponent } from './gw-form-container/payment-type-form/payment-type-form.component'
import { StatusFormComponent } from './gw-form-container/status-form/status-form.component'
import { AgencyFormComponent } from './gw-form-container/agency-form/agency-form.component'
import { ProcessorFormComponent } from './gw-form-container/processor-form/processor-form.component'

@NgModule({
  declarations: [
    GwFormContainerComponent,
    UserFormComponent,
    SuperuserFormComponent,
    AffiliateFormComponent,
    MerchantFormComponent,
    OfficeFormComponent,
    MidGroupFormComponent,
    MidFormComponent,
    RouteFormComponent,
    PaymentTypeFormComponent,
    StatusFormComponent,
    AgencyFormComponent,
    ProcessorFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StandardFormModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StandardFormModule,
    GwFormContainerComponent,
    UserFormComponent,
    SuperuserFormComponent,
    AffiliateFormComponent,
    MerchantFormComponent,
    OfficeFormComponent,
    MidGroupFormComponent,
    MidFormComponent,
  ]
})
export class GwResourceModule { }