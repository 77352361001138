import { TemplateRef } from '@angular/core'

import { filter, takeUntil, distinctUntilKeyChanged, takeWhile } from 'rxjs/operators'
import { Subscription, pipe, Subscriber } from 'rxjs'

import { Store, State, Action, StateContext, Selector } from '@ngxs/store'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core'
import Auth from '@aws-amplify/auth'

import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'
import { GatewayApiService } from '@app/services/gateway-api'
import { UsersRecordModel } from '@app/models/gateway-api'

import { FetchAppErrorRecords } from '@app/ngxs/gateway-api/app-error.ngxs'

import { NgxsError } from '@app/classes/error/ngxs-error'
import { FetchGwRecords, RecordLogin } from '@app/ngxs/gateway-api'
import { ToastSuccess, ToastError, ToastWarning, ToastInfo } from '@app/ngxs/toaster.ngxs'
import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'

export class BeginSession {
  static readonly type = '[Session] Begin Session'
    
  constructor(public username: string) {}
}

export class ClearCurrentUser {
  static readonly type = '[Session] Clear Current User'
    
  constructor() {}
}

export class ClearSession {
  static readonly type = '[Session] Clear Session'
  
  constructor() {}
}

export class SetSelectedResource {
  static readonly type = '[Session] Set Selected Resource'
  
  constructor(public resource: string, public id: number) {}
}

export class ClearSelectedResource {
  static readonly type = '[Session] Clear Selected Resource'
  
  constructor(public resource: string) {}
}

export class IdleSetState {
  static readonly type = '[Session] Idle Set State'
  
  constructor(public stateName: string, public idleTemplate?: TemplateRef<any>) {}
}

export class InitializeIdle {
  static readonly type = '[Session] Initialize Idle'
  
  constructor(public idleTemplate: TemplateRef<any>) {}
}

export class CancelIdle {
  static readonly type = '[Session] Cancel Idle'
  
  constructor() {}
}

export class AppSignin {
  static readonly type = '[Session] App Signin'
  
  constructor() {}
}

export class AppSignout {
  static readonly type = '[Session] App Signout'
  
  constructor() {}
}

export class InitCredentials {
  static readonly type = '[Session] Init Credentials'
  
  constructor(public idleTemplate: TemplateRef<any>) {}
}

export class CognitoSignIn {
  static readonly type = '[Session] Cognito Sign In'
  
  constructor(public username: string, public password: string) {}
}

export class CognitoForgotPass {
  static readonly type = '[Session] Cognito Forgot Pass'
  
  constructor(public username: {username: string} | null) {}
}

export class CognitoSignUp {
  static readonly type = '[Session] Cognito Sign Up'
  
  constructor(public username: {username: string} | null) {}
}

export class CognitoSetError {
  static readonly type = '[Session] Cognito Set Error'
  
  constructor(public err: any) {}
}

export class CognitoForgotPassSubmit {
  static readonly type = '[Session] Cognito Forgot Pass Submit'
  
  constructor(public username: string, public code: string, public password: string) {}
}

export class CognitoSendCode {
  static readonly type = '[Session] Cognito Send Code'
  
  constructor(public username: string) {}
}

export class CognitoRequireSignIn {
  static readonly type = '[Session] Cognito Require Sign In'
  
  constructor(public user: any) {}
}

export class CognitoCompleteNewPassword {
  static readonly type = '[Session] Cognito Complete New Password'
  
  constructor(public user: any, public password: string) {}
}

export class CognitoConfirmSignIn {
  static readonly type = '[Session] Cognito Confirm Sign In'
  
  constructor(public user: any, public code: string, public mfaType: any) {}
}

export class Authorizer {
  state: string
  user: any
}

export class SessionStateModel {
  loading: boolean
  permissions: {[key: string]: boolean}
  roles: {[key: string]: boolean}
  currentUser: UsersRecordModel | null
  extendedPermissions: any
  selected: {[key: string]: IApiResourceRecordModel}
  idle: {
    state: string
    modalRef: NgbModalRef
  }
  auth: {
    state: Authorizer
    subscription: Subscription
    loading: boolean
    loadingCode: boolean
    loadingForgot: boolean
    errorMessage: string
  }
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    loading: true,
    roles: {'superuser': false, 'merchant': false, 'affiliate': false},
    permissions: {},
    currentUser: null,
    extendedPermissions: null,
    selected: {},
    idle: {
      state: null,
      modalRef: null
    },
    auth: {
      state: {
        state: 'signedOut',
        user: null
      },
      subscription: null,
      loading: false,
      loadingCode: false,
      loadingForgot: false,
      errorMessage: null
    }
  }
})
export class SessionState {
  constructor(
    private api: GatewayApiService,
    private store: Store,
    // private idleService: IdleService,
    private idle: Idle,
    // private middlemanService: IdleMiddlemanService,
    private modalService: NgbModal,
    private cognitoAuth: CognitoAuthService
  ) {}
  
  start: Subscription = new Subscription()
  end: Subscription = new Subscription()
  timeout: Subscription = new Subscription()
  
  @Selector()
  static getCurrentUser(state: SessionStateModel) {
    return state.currentUser
  }
  
  @Action(BeginSession)
  async beginSession({patchState, dispatch}: StateContext<SessionStateModel>, {username}: BeginSession) {
    patchState({
      loading: true,
      currentUser: null,
    })
    
    const session: SessionStateModel = new SessionStateModel()
    let result: UsersRecordModel[]
    
    const currentUserPayload = {
      username: {
        op: '=',
        val: username,
      }
    }

    try {
      result = await this.api.getAll<UsersRecordModel>('users', currentUserPayload)
    } catch (error) {
      console.error(error.message)
      
      result = null
      
      throw new NgxsError('Failed to fetch current user.')
    }
    
    if (!result || result.length !== 1) { // FIXME - if user information can't be found, a generic sign-out screen should be shown along with an error message
      patchState({
        loading: false
      })
      throw new NgxsError('User account could not be located!')
    }
    
    session.currentUser = result[0]
    session.extendedPermissions = {}
    
    let extendedPermissions // { PreferenceName, PreferenceValue }

    try {
      extendedPermissions = await this.api.getAll<IApiResourceRecordModel>('extended-permissions', {})
    } catch (error) {
      console.error(error.message)
      
      extendedPermissions = null
    }
    
    if (extendedPermissions) {
      const singleEntryOnly           = extendedPermissions.find( r => r.name === 'SingleEntryOnly' )
      const singleEntryIfNotUserAdmin = extendedPermissions.find( r => r.name === 'SingleEntryIfNotUserAdmin' )
      const singleEntryIfNotVoid      = extendedPermissions.find( r => r.name === 'SingleEntryIfNotVoid' )
      
      session.extendedPermissions = {
        singleEntryOnly: singleEntryOnly ? singleEntryOnly.value : null,
        singleEntryIfNotUserAdmin: singleEntryIfNotUserAdmin ? singleEntryIfNotUserAdmin.value : null,
        singleEntryIfNotVoid: singleEntryIfNotVoid ? singleEntryIfNotVoid.value : null,
      }
      
      if (
        (singleEntryOnly && singleEntryOnly.value === 'true')
          ||
        (singleEntryIfNotUserAdmin && singleEntryIfNotUserAdmin.value === 'true' && !session.currentUser.allow_user)
          ||
        (singleEntryIfNotVoid && singleEntryIfNotVoid.value === 'true' && !session.currentUser.allow_void)
          ||
        (session.currentUser.report_only)
      ) {
        return dispatch([
          new ToastInfo('Your account is not authorized for this service.', 'Signed Out'),
          new AppSignout(),
        ])
      }
    }
    
    session.roles = {
      "superuser": false,
      "affiliate": false,
      "merchant": false,
    }
    
    if (session.currentUser.is_admin) {
      session.roles.superuser = true
      
      return dispatch([
        new RecordLogin(username),
        new FetchGwRecords('api-manifest'),
        new FetchGwRecords('statuses'),
        new FetchGwRecords('mids'),
        new FetchGwRecords('users'),
        new FetchGwRecords('merchants'),
        new FetchGwRecords('affiliates'),
        new FetchGwRecords('offices'),
        new FetchGwRecords('processors'),
        new FetchGwRecords('agencies'),
        new FetchGwRecords('mid-groups'),
        new FetchGwRecords('routes'),
        new FetchGwRecords('payment-types'),
        new FetchAppErrorRecords(),
      ])
      .subscribe( () => {
        patchState({
          loading: false,
          ...session
        })
      })
    } else if (session.currentUser.affiliate_id !== 0) {
      session.roles.affiliate = true
      
      return dispatch([
        new RecordLogin(username),
        new FetchGwRecords('api-manifest'),
        new FetchGwRecords('statuses'),
        new FetchGwRecords('merchants'),
        new FetchGwRecords('affiliates'),
        new FetchGwRecords('users'),
        new FetchGwRecords('offices'),
        new FetchGwRecords('mids'),
        new FetchGwRecords('processors'),
        new FetchGwRecords('routes'),
        new FetchGwRecords('payment-types'),

        new SetSelectedResource('affiliates', session.currentUser.affiliate_id)
      ])
      .subscribe( () => {
        patchState({
          loading: false,
          ...session
        })
      })
    } else if (session.currentUser.merchant_id !== 0) {
      session.roles.merchant = true

      return dispatch([
        new RecordLogin(username),
        new FetchGwRecords('api-manifest'),
        new FetchGwRecords('statuses'),
        new FetchGwRecords('users'),
        new FetchGwRecords('merchants'),
        new FetchGwRecords('offices'),
        // new FetchGwRecords('mids'),
        // new FetchGwRecords('processors'),
        // new FetchGwRecords('routes'),
        // new FetchGwRecords('payment-types'),
        
        new SetSelectedResource('merchants', session.currentUser.merchant_id),
        new SetSelectedResource('office', session.currentUser.office_id),
      ])
      .subscribe( () => {
        patchState({
          loading: false,
          ...session
        })
      })
      
      // TODO - fetch their Merchant info from sbps_clnt
    }
  }
  
  @Action(ClearCurrentUser)
  clearCurentUser({patchState, getState}: StateContext<SessionStateModel>) {
    patchState({
      ...getState(),
      currentUser: null
    })
  }
  
  @Action(ClearSession)
  clearSession({patchState}: StateContext<SessionStateModel>) {
    patchState({
      loading: true,
      roles: {'superuser': false, 'merchant': false, 'affiliate': false},
      permissions: {},
      currentUser: null,
      selected: {},
      idle: {
        state: null,
        modalRef: null
      },
      auth: {
        state: {
          state: 'signedOut',
          user: null
        },
        subscription: null,
        loading: false,
        loadingCode: false,
        loadingForgot: false,
        errorMessage: null
      }
    })
  }

  @Action(SetSelectedResource)
  setSelectedResource({patchState, getState}: StateContext<SessionStateModel>, {resource, id}: SetSelectedResource) {
    return this.store.select(s => s['gwState'].index[resource])
      .pipe(
        filter(s => s && s.records)
      )
      .subscribe(state => {
        patchState({ selected: { ...getState().selected, [resource]: state.records.find(r => r.id === id) } })
      })
  }
  
  @Action(ClearSelectedResource)
  clearSelectedResource({patchState, getState}: StateContext<SessionStateModel>, {resource}: SetSelectedResource) {
    return this.store.select(s => s['gwState'].index[resource])
      .pipe(
        filter(s => s && s.records)
      )
      .subscribe(state => {
        patchState({ selected: { ...getState().selected, [resource]: undefined } })
      })
  }
  
  @Action(IdleSetState)
  idleSetState({patchState, getState, dispatch}: StateContext<SessionStateModel>, {stateName, idleTemplate}: IdleSetState) {
    let a: string = ''
    let b: boolean = false
    
    if (stateName === 'start') {
      a = 'You will be logged out soon.'
      b = true
    } else if (stateName === 'end') {
      a = 'No longer idle.'
    } else if (stateName === 'timeout') {
      a = 'Your session has timed out.'
      dispatch([new AppSignout()])
    }
    
    const state = getState()
    
    patchState({
      ...state,
      idle: {
        ...state.idle,
        state: a,
      }
    })
    
  }
  
  @Action(InitializeIdle)
  initializeIdle({patchState, getState}: StateContext<SessionStateModel>, {idleTemplate}: InitializeIdle) {
    
    this.idle.setIdle(720) // 720 = 12 minutes
    this.idle.setTimeout(180) // 180 = 3 minutes 
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
    
    this.start = this.idle.onIdleStart.subscribe(() => {
      this.store.dispatch(new IdleSetState('start', idleTemplate))
    }),
    this.end = this.idle.onIdleEnd.subscribe(() => {
      this.store.dispatch(new IdleSetState('end'))
    }),
    this.timeout = this.idle.onTimeout.subscribe(() => {
      this.store.dispatch(new IdleSetState('timeout'))
    })
    
    const initialState = getState()
    
    patchState({
      ...initialState,
      idle: {
        ...initialState.idle,
        state: `You will be logged out automatically if you go idle for too long.`,
      }
    })
    
    this.idle.watch()
  }
  
  @Action(CancelIdle)
  cancelIdle({patchState, getState}: StateContext<SessionStateModel>) {
        
    if (this.start)
      this.start.unsubscribe()
      
    if (this.end)
      this.end.unsubscribe()
      
    if (this.timeout)
      this.timeout.unsubscribe()
      
    this.start = null
    this.end = null
    this.timeout = null
      
  }
  
  @Selector()
  static getAuthState(state: SessionStateModel) {
    return state.auth.state
  }
  
  @Action(AppSignin)
  appSignin({}: StateContext<SessionStateModel>) {
    // do some stuff here
  }
  
  @Action(AppSignout)
  appSignout( {dispatch}: StateContext<SessionStateModel>) {
    
    Auth.signOut()
      .then( () => {
        this.cognitoAuth.setAuthState({ state: 'signedOut', user: null })

        dispatch([
          new CancelIdle(),
          new ClearSession(),
        ])
        
        // this.router.navigate([``]) // FIXME re-enable for production
      })
      .catch( event => {
        console.error('bad signout', JSON.stringify(event))
        // this.router.navigate([``])
      }) // FIXME log error to database
    
    // this.amplifyService.auth().signOut()
    //   .then( () => {
    //     this.store.dispatch(new ClearSession())
    //     this.spinner.hide() // FIXME this shouldn't be needed but yet it is.
    //   })
    //   .catch( event => console.error('bad signout', JSON.stringify(event)) ) // FIXME log error

    // this.router.navigate([``]) // FIXME re-enable for production
  }
  
  @Action(InitCredentials)
  initCredentials({patchState, getState, dispatch}: StateContext<SessionStateModel>, {idleTemplate}: InitCredentials) {
    // console.log('init credentials')
    
    const authSub = this.cognitoAuth.authStateChanges$
      .pipe( 
        distinctUntilKeyChanged('state')
      )
      .subscribe(change => {
        const state = getState()
        
        patchState({
          ...state,
          auth: {
            ...state.auth,
            state: {
              state: change.state,
              user: null
            },
          }
        })
        
        if (change.state === 'signedIn' && change.user) {
          dispatch([
            new InitializeIdle(idleTemplate),
            new BeginSession(change.user.username),
          ])
        }
      })
  }
  
  @Action(CognitoSignIn)
  cognitoSignIn({patchState, getState, dispatch}: StateContext<SessionStateModel>, {username, password}: CognitoSignIn) {
    const initialState = getState()
    
    // console.log('signing in cognito')
    
    patchState({
      ...initialState,
      auth: {
        ...initialState.auth,
        loading: true
      }
    })
    
    return Auth.signIn(username, password)
      .then(user => {
        
        
        const state = getState()
        
        const authState: string = (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA')
              ? 'confirmSignIn' 
              : (user.challengeName === 'NEW_PASSWORD_REQUIRED')
                ? 'requireNewPassword'
                : 'signedIn'
        
        this.cognitoAuth.setAuthState({ state: authState, user: user})
        
        patchState({
          ...state,
          auth: {
            ...state.auth,
            state: { state: authState, user: user.username},
            loading: false
          }
        })
        
      })
      .catch(err => {
        
        const state = getState()
        patchState({
          ...state,
          auth: {
            ...state.auth,
            loading: false,
          }
        })
        
        dispatch(new CognitoSetError(err))
        
      })
  }
  
  @Action(CognitoForgotPass)
  cognitoForgotPass({patchState, getState}: StateContext<SessionStateModel>, {username}: CognitoForgotPass) {
    const state = getState()
    patchState({
      ...state,
      loading: false
    })
    this.cognitoAuth.setAuthState({ state: 'forgotPassword', user: username })
  }

  @Action(CognitoSignUp)
  cognitoSignUp({patchState, getState}: StateContext<SessionStateModel>, {username}: CognitoSignUp) {
    this.cognitoAuth.setAuthState({ state: 'signUp', user: username })
  }
  
  @Action(CognitoSetError)
  cognitoSetError({patchState, getState}: StateContext<SessionStateModel>, {err}: CognitoSetError) {
    const state = getState()
    
    patchState({
      ...state,
      auth: {
        ...state.auth,
        errorMessage: err ? this.wrapCognitoError(err, err.message) : null
      }
    })
  }
  
  @Action(CognitoForgotPassSubmit)
  cognitoForgotPassSubmit({patchState, getState, dispatch}: StateContext<SessionStateModel>, {username, code, password}: CognitoForgotPassSubmit) {
    const initState = getState()
    patchState({
      ...initState,
      auth: {
        ...initState.auth,
        loadingCode: true
      }
    })
    
    
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        const user = { username: username }
        this.cognitoAuth.setAuthState({ state: 'signIn', user: user })
        patchState({
          ...initState,
          auth: {
            ...initState.auth,
            loadingCode: false
          }
        })
      })
      .catch(err => {
        dispatch(new CognitoSetError(err))
        patchState({
          ...initState,
          auth: {
            ...initState.auth,
            loadingCode: false
          }
        })
      })    
  }
  
  @Action(CognitoSendCode)
  cognitoSendCode({patchState, getState, dispatch}: StateContext<SessionStateModel>, {username}: CognitoSendCode) {
    
    let state = getState()
    patchState({
      ...state,
      auth: {
        ...state.auth,
        loadingForgot: true
      }
    })
    
    dispatch(new CognitoSetError(null))
    
    Auth.forgotPassword(username)
      .then( () => {
        
        
        patchState({
          ...state,
          auth: {
            ...state.auth,
            loadingForgot: false
          }
        })
        
      })
      .catch( err => {
        dispatch(new CognitoSetError(err))
        patchState({
          ...state,
          auth: {
            ...state.auth,
            loadingForgot: false
          }
        })
      })
  }
  
  @Action(CognitoRequireSignIn)
  cognitoRequireSignIn({patchState, getState}: StateContext<SessionStateModel>, {user}: CognitoRequireSignIn) {
    const state = getState()
    patchState({
      ...state,
      loading: true
    })
    this.cognitoAuth.setAuthState({ state: 'signIn', user: user })
  }
  
  @Action(CognitoCompleteNewPassword)
  cognitoCompleteNewPassword({patchState, getState, dispatch}: StateContext<SessionStateModel>, {user, password}: CognitoCompleteNewPassword) {
    
    Auth.completeNewPassword(user, password, user.challengeParam)
      .then(() => {
        dispatch(new CognitoRequireSignIn(user))
      })
      .catch(err => {
        dispatch(new CognitoSetError(err))
      })
  }
  
  @Action(CognitoConfirmSignIn)
  cognitoConfirmSignIn({patchState, getState, dispatch}: StateContext<SessionStateModel>, {user, code, mfaType}: CognitoConfirmSignIn) {
    
    Auth.confirmSignIn(user, code, mfaType)
      .then( () => this.cognitoAuth.setAuthState({ state: 'signedIn', user: user}))
      .catch( err => {
        dispatch(new CognitoSetError(err))
      })
  }
  
  wrapCognitoError(err, message: string): string {
    
    // Valid username, blank password.
    if (message === 'null invocation failed due to configuration.')
      return 'Please provide both a username and a password.'
    
    if (message === 'null failed with error Generate callenges lambda cannot be called..')
      return 'Incorrect username or password.'
      
    // Invalid username, non-blank password.
    if (message === 'UserMigration failed with error : Login credentials were not recognized..')
      return 'Incorrect username or password.'
    
    // Invalid username, blank password
    if (message === 'User does not exist.')
      return 'Incorrect username or password.'
      
    if (message === 'Password attempts exceeded.')
      return 'Password attempts exceeded. Please try again in a few minutes.'
      
    if (message === 'UserMigration failed with error : Username could not be located..')
      return 'User not found.'
      
    if (message && message[message.length -1] !== '.')
      return message += '.'
    
    if (!message && typeof err === "string" && err[err.length -1] !== '.')
      return err += '.'
      
      
      
    return message || err
  }
}