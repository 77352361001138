/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./buttons-array.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./buttons-array.component";
var styles_ButtonsArrayComponent = [i0.styles];
var RenderType_ButtonsArrayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonsArrayComponent, data: {} });
export { RenderType_ButtonsArrayComponent as RenderType_ButtonsArrayComponent };
function View_ButtonsArrayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "spinner-border spinner-border-sm mr-2"], ["role", "status"]], null, null, null, null, null))], null, null); }
function View_ButtonsArrayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, " pr-2"); _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonsArrayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn ml-2"]], [[8, "type", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handle(_v.context.$implicit.fn) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonsArrayComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["icon", 2]], null, 0, null, View_ButtonsArrayComponent_3)), (_l()(), i1.ɵted(6, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn ml-2"; var currVal_3 = (_v.context.$implicit.primary ? "btn-primary" : "btn-outline-secondary"); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.processing; var currVal_5 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.primary ? "submit" : "button"), ""); var currVal_1 = (_v.context.$implicit.primary && (_co.formGroup.invalid || _co.formGroup.pristine)); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = _v.context.$implicit.label; _ck(_v, 6, 0, currVal_6); }); }
export function View_ButtonsArrayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonsArrayComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fields.buttons; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ButtonsArrayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-buttons-array", [], null, null, null, View_ButtonsArrayComponent_0, RenderType_ButtonsArrayComponent)), i1.ɵdid(1, 180224, null, 0, i3.ButtonsArrayComponent, [], null, null)], null, null); }
var ButtonsArrayComponentNgFactory = i1.ɵccf("app-buttons-array", i3.ButtonsArrayComponent, View_ButtonsArrayComponent_Host_0, { formGroup: "formGroup", fields: "fields", formContainer: "formContainer" }, {}, []);
export { ButtonsArrayComponentNgFactory as ButtonsArrayComponentNgFactory };
