import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core'

// import { includes } from './common'
// import { AmplifyService } from 'aws-amplify-angular'
// import Auth from '@aws-amplify/auth'

import { takeUntil, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';

import { Store } from '@ngxs/store'
import { CognitoSignIn, CognitoForgotPass, CognitoSignUp, CognitoSetError } from '@app/ngxs'


// import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'

@Component({
  selector: 'auth-sign-in',
  templateUrl: './sign-in.component.html'
})
export class SignInComponent implements AfterViewInit {
  @ViewChild('usernameControl', { static: false })
  usernameControl: ElementRef
  
  _authState: any
  _show: boolean
  username: string = ''
  password: string = ''
  errorMessage: string
  private loading: boolean = false

  destroyed$: Subject<boolean> = new Subject<boolean>()
  loading$: Subscription
  error$: Subscription
  
  constructor(
    private store: Store
    // private cognitoAuth: CognitoAuthService
    // private amplifyService: AmplifyService
  ) {
    this.loading$ = this.store.select(state => state.session.auth)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe(authState => {
        this.loading = authState.loading
      })
      
    this.error$ = this.store.select(state => state.session.auth.errorMessage)
      .pipe(
        distinctUntilChanged(),
        filter(s => s),
        takeUntil(this.destroyed$),
      )
      .subscribe(authState => {
        this.errorMessage = authState
      })
  }
  
  ngAfterViewInit() {
    this.usernameControl.nativeElement.focus()
  }

  @Input()
  set authState(authState: any) {
    this._authState = authState;
    // this._show = includes(['signIn', 'signedOut', 'signedUp'], authState.state);
    this._show = ['signIn', 'signedOut', 'signedUp'].includes(authState.state)
    // this.username = authState.user? authState.user.username || '' : '';
  }

  // setUsername(username: string) {
  //   this.username = username;
  // }

  // setPassword(password: string) {
  //   this.password = password;
  // }

  onSignIn() {
    // this.loading = true
    this._setError(null)
    
    this.store.dispatch(new CognitoSignIn(this.username, this.password))

    // this.amplifyService.auth().signIn(this.username, this.password)
    //   .then(user => {
    //     if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
    //       this.amplifyService.setAuthState({ state: 'confirmSignIn', user: user });
    //     } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    //       this.amplifyService.setAuthState({ state: 'requireNewPassword', user: user });
    //     } else {
    //       this.amplifyService.setAuthState({ state: 'signedIn', user: user });
    //     }
        
    //     this.loading = false
    //   })
    //   .catch(err => {
    //     this._setError(err)
    //     this.loading = false
    //   })
  }

  onDestroy() {
    
  }

  onForgotPassword() {
    const user = this.username? { username: this.username } : null

    // this.amplifyService.setAuthState({ state: 'forgotPassword', user: user })
    // this.cognitoAuth.setAuthState({ state: 'forgotPassword', user: user })
    
		this.store.dispatch(new CognitoForgotPass(user))
  }

  onSignUp() {
    const user = this.username? { username: this.username } : null

    // this.amplifyService.setAuthState({ state: 'signUp', user: user });
    // this.cognitoAuth.setAuthState({ state: 'signUp', user: user })
    
		this.store.dispatch(new CognitoSignUp(user))
  }

  _setError(err) {
    
		this.store.dispatch(new CognitoSetError(err))
  }
}