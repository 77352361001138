/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payments.component";
var styles_PaymentsComponent = [i0.styles];
var RenderType_PaymentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsComponent, data: {} });
export { RenderType_PaymentsComponent as RenderType_PaymentsComponent };
export function View_PaymentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" payments works!\n"]))], null, null); }
export function View_PaymentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payments", [], null, null, null, View_PaymentsComponent_0, RenderType_PaymentsComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsComponentNgFactory = i1.ɵccf("app-payments", i2.PaymentsComponent, View_PaymentsComponent_Host_0, {}, {}, []);
export { PaymentsComponentNgFactory as PaymentsComponentNgFactory };
