<app-s-hidden #idControl [config]="fields['id']" [group]="formGroup"></app-s-hidden>
<app-s-text #usernameControl [config]="fields['username']" [group]="formGroup"></app-s-text>
<app-s-password #passwordControl [config]="fields['password']" [group]="formGroup"></app-s-password>
<app-s-toggle #passwordForceChangeControl [config]="fields['password_force_change']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #serviceAccountControl [config]="fields['service_account']" [group]="formGroup"></app-s-toggle>
<app-s-text #fullNameControl [config]="fields['full_name']" [group]="formGroup"></app-s-text>
<app-s-text #emailControl [config]="fields['email']" [group]="formGroup"></app-s-text>
<app-phone-array #phonesControl [config]="fields['phones']" [group]="formGroup"></app-phone-array>
<app-s-text #tierControl *ngIf="roles['superuser']" [config]="fields['tier']" [group]="formGroup"></app-s-text>
<app-s-select #clientIdControl [config]="fields['merchant_id']" [group]="formGroup"></app-s-select>
<app-s-select #branchIdControl [config]="fields['branch_id']" [group]="formGroup"></app-s-select>
<app-s-toggle-section #reportOnlyControl *ngIf="formGroup" [config]="fields['report_only']" [formGroup]="formGroup">
  <app-s-toggle #ipLockExemptControl *ngIf="roles['superuser']" [config]="fields['ip_lock_exempt']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #isAdminControl *ngIf="roles['superuser']" [config]="fields['is_admin']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allClientControl *ngIf="roles['superuser']" [config]="fields['all_client']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allBranchControl *ngIf="roles['superuser']" [config]="fields['all_branch']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowSbpsControl *ngIf="roles['superuser']" [config]="fields['allow_sbps']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowUserControl [config]="fields['allow_user']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowClientControl *ngIf="roles['superuser']" [config]="fields['allow_client']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowBranchControl [config]="fields['allow_branch']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowTranControl [config]="fields['allow_tran']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowVoidControl [config]="fields['allow_void']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowCreditControl [config]="fields['allow_credit']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowReverseControl [config]="fields['allow_reverse']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowRetryControl [config]="fields['allow_retry']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowCopyControl [config]="fields['allow_copy']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowPlanControl [config]="fields['allow_plan']" [group]="formGroup"></app-s-toggle>
  <app-s-toggle #allowPlanTerminateControl [config]="fields['allow_plan_terminate']" [group]="formGroup"></app-s-toggle>
</app-s-toggle-section>
<app-s-text #supOvrPinControl [config]="fields['sup_ovr_pin']" [group]="formGroup"></app-s-text>
<app-s-text #extMerchClientControl [config]="fields['ext_merch_client']" [group]="formGroup"></app-s-text>
<app-s-select #affiliateIdControl *ngIf="roles['superuser']" [config]="fields['affiliate_id']" [group]="formGroup"></app-s-select>
<app-s-select #statusIdControl [config]="fields['status_id']" [group]="formGroup"></app-s-select>