import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { Store } from '@ngxs/store'
import Auth from '@aws-amplify/auth'
import { ClearSession } from '@app/ngxs'
import { NgxSpinnerService } from '@app/errata/ngx-spinner/ngx-spinner.service'

import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'

@Component({
  selector: 'app-app-error',
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.scss']
})
export class AppErrorComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store,
    private cognitoAuth: CognitoAuthService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
  }
  
  signout(): void {
    Auth.signOut()
      .then( () => {
        this.cognitoAuth.setAuthState({ state: 'signedOut', user: null })
        this.store.dispatch(new ClearSession())
        this.spinner.hide() // FIXME shouldn't be needed but is.
        this.router.navigate([`/`])
      })
      .catch( event => console.error('bad signout', JSON.stringify(event)) ) // FIXME log error to database
    
    // this.amplifyService.auth().signOut()
    //   .then( () => {
    //     this.store.dispatch(new ClearSession())
    //     this.spinner.hide() // FIXME this shouldn't be needed but yet it is.
    //   })
    //   .catch( event => console.error('bad signout', JSON.stringify(event)) ) // FIXME log error

    // this.router.navigate([``]) // FIXME re-enable for production
  }

}
