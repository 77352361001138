import { State, Action, StateContext, Selector } from '@ngxs/store'
import { patch, append } from '@ngxs/store/operators'

import { GatewayApiService } from '@app/services/gateway-api'
// import { GwResource } from '@app/models/gateway-api'
import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'

import { NgxsError } from '@app/classes/error/ngxs-error'

export class FetchAppErrorRecords {
  static readonly type = '[App-Error] Fetch'
    
  // constructor(public resource: GwResource) {}
  constructor() {}
}

export class FetchAppErrorRecordsPage {
  static readonly type = '[Omni] Fetch Page'
    
  constructor(public page: number) {}
}

export class CreateAppErrorRecord {
  static readonly type = '[App-Error] Create'
    
  constructor(public payload: IApiResourceRecordModel) {}
}

export class UpdateAppErrorRecord {
  static readonly type = '[App-Error] Update'
    
  constructor(public payload: IApiResourceRecordModel, public previous: IApiResourceRecordModel) {}
}

export class DisableAppErrorRecord {
  static readonly type = '[App-Error] Disable'
    
  constructor(public payload: IApiResourceRecordModel) {}
}

export class ClearAppErrorRecords {
  static readonly type = '[App-Error] Clear'
    
  constructor() {}
}

// export class GwRecordSet {
//   loading: boolean
//   records: IApiResourceRecordModel[]
// }

// export type GwRecordSetIndex =
// {
//   [key in GwResource]: GwRecordSet
// }

export class AppErrorStateModel {
  loading: boolean
  records: IApiResourceRecordModel[]
}

// @State<GwStateModel>({
//   name: 'gwState',
//   defaults: {
//     loading: true,
//     index: {} as GwRecordSetIndex
//   }
// })

@State<AppErrorStateModel>({
  name: 'appError',
  defaults: {
    loading: true,
    records: [],
  }
})
export class GwAppErrorState {
  
  constructor(
    private api: GatewayApiService
  ) {}
  
  @Selector()
  static getRecords(state: AppErrorStateModel): Partial<AppErrorStateModel> {
    console.log('App-Error selector was called')
    return state
  }

  @Action(FetchAppErrorRecords)
  async fetch({setState}: StateContext<AppErrorStateModel>) {
    // console.log(`fetching: ${resource}`)
    
    setState(
      patch<AppErrorStateModel>({
        loading: true,
        records: [],
      })
    )
    
    let result: IApiResourceRecordModel[] = []
    
    try {
      result = await this.api.getAll<IApiResourceRecordModel>('app-error')
    } catch (error) {
      console.info(`Could not fetch application error messages.`)
      
      throw new NgxsError('The records could not be retrieved.')
    } finally {
      setState(
        patch<AppErrorStateModel>({
          loading: false,
          records: result
        })
      )
    }
  }
  
  // @Action(FetchAppErrorRecordsPage)
  // async fetchPage({setState}: StateContext<GwStateModel>, { page }: FetchGwRecordsPage) {

  //   setState(
  //     patch<GwStateModel>({
  //       index: patch({ [resource]: { loading: true, records: [] } })
  //     })
  //   )
    
  //   let result: IApiResourceRecordModel[] = []
    
  //   try {
  //     result = await this.api.getAll<IApiResourceRecordModel>(resource)
  //   } catch (error) {
  //     console.info(`Could not fetch ${resource}.`)
      
  //     throw new NgxsError('The records could not be retrieved.')
  //   } finally {
  //     setState(
  //       patch<GwStateModel>({
  //         index: patch({ [resource]: { loading: false, records: result } })
  //       })
  //     )
  //   }
  // }
  
  @Action(CreateAppErrorRecord)
  async create({setState}: StateContext<AppErrorStateModel>, { payload }: CreateAppErrorRecord) {
    let result
    
    try {
      result = await this.api.create<IApiResourceRecordModel>('app-error', payload)
      
      if (!result.success)
        throw new NgxsError('API action completed, but result was not successful.')
        
      if (!result.id)
        throw new NgxsError('API action completed successfully, but no ID was returned.')
    } catch (error) {
      console.info(error.message)
      
      throw new NgxsError('The record could not be created.')
    }
    
    payload.id = result.id // FIXME - should use propery Primary Key
    
    setState(
      patch<AppErrorStateModel>({
        records: append([ payload ])
      })
    )
  }
  
  @Action(UpdateAppErrorRecord)
  async update({getState, setState}: StateContext<AppErrorStateModel>, { payload, previous }: UpdateAppErrorRecord) {
    const state = getState()
    
    setState(
      patch<AppErrorStateModel>({
        loading: true,
        records: [...state.records]
      })
    )
    
    const index = state.records.findIndex(i => i.id == payload.id)
    
    if (index === -1) {
      console.info(`Error: Attempt to update an application error messasge failed because the record could not be located locally.`)
      
      console.log('id', payload.id)
      
      setState(
        patch<AppErrorStateModel>({
          loading: false,
          records: [...state.records]
        })
      )
      
      throw new NgxsError('This record may not be available anymore.')
    }
    
    let updatedRecords = state.records
      
    try {
      const result = await this.api.update<IApiResourceRecordModel>('app-error', payload, previous)
      
      if (result.success) {
        updatedRecords = state.records.filter( record => record.id != payload.id) // FIXME should not use 'id' here.
        updatedRecords.push(payload)
      } else {
        throw new NgxsError('API did not return success: true.')
      }
    } catch (error) {
      console.info(error.message)
      
      throw new NgxsError('The record could not be updated.')
    } finally {
      setState(
        patch<AppErrorStateModel>({
          loading: false,
          records: [...updatedRecords]
        })
      )
    }
  }
  
  @Action(DisableAppErrorRecord)
  async disable({getState, setState}: StateContext<AppErrorStateModel>, { payload }: DisableAppErrorRecord) {
    const state = getState()
    
    setState(
      patch<AppErrorStateModel>({
        loading: true,
        records: [...state.records]
      })
    )
    
    let updatedRecords = state.records
    
    try {
      const result = await this.api.disable('app-error', `${payload.id}`) // FIXME to not use ID.
      
      if (result.success)
        updatedRecords = state.records.filter( record => record.id !== payload.id) // FIXME
      else
        throw new NgxsError('API did not return success: true.')
    } catch (error) {
      console.info(error.message)
      
      throw new NgxsError('Could not delete the record.')
    } finally {
      setState(
        patch<AppErrorStateModel>({
          loading: false,
          records: [...updatedRecords]
        })
      )
    }
  }
  
  @Action(ClearAppErrorRecords)
  clear({ setState }: StateContext<AppErrorStateModel>) {
    setState(
      patch<AppErrorStateModel>({
        loading: false,
        records: [],
      })
    )
  }

}