import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core'
// import { AmplifyService } from 'aws-amplify-angular'
import { Store } from '@ngxs/store'
import { CognitoForgotPassSubmit, CognitoSetError, CognitoSendCode, CognitoRequireSignIn } from '@app/ngxs'

import { takeUntil, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';

// import Auth from '@aws-amplify/auth'

// import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements AfterViewInit {
  @ViewChild('tandy', { static: false })
  usernameControl: ElementRef
  
  _authState: any; // AuthState
  _show: boolean;

  username: string;
  code: string;
  password: string;

  errorMessage: string;
  
  loadingCode$: Subscription
  error$: Subscription
  
  destroyed$: Subject<boolean> = new Subject<boolean>()
  
  private loadingForgot: boolean = false
  private loadingCode: boolean = false

  constructor(
    private store: Store,
    // private cognitoAuth: CognitoAuthService,
    // private amplifyService: AmplifyService
  ) {
    this.loadingCode$ = this.store.select(state => state.session.auth)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe(authState => {
        this.loadingCode = authState.loadingCode
      })
    
    this.error$ = this.store.select(state => state.session.auth.errorMessage)
      .pipe(
        distinctUntilChanged(),
        filter(s => s),
        takeUntil(this.destroyed$),
      )
      .subscribe(authState => {
        this.errorMessage = authState
      })
    
  }
  
  ngAfterViewInit() {
    this.usernameControl.nativeElement.focus()
  }

  @Input()
  set authState(authState: any) { // AuthState
    this._authState = authState;
    this._show = authState.state === 'forgotPassword'

    this.username = authState.user? authState.user.username || '' : ''
  }

  setUsername(username: string) {
    this.username = username
  }

  setCode(code: string) {
    this.code = code
  }

  setPassword(password: string) {
    this.password = password
  }

  onSend() {
    this.store.dispatch(new CognitoSendCode(this.username))
  }

  onSubmit() {
    this.store.dispatch(new CognitoForgotPassSubmit(this.username, this.code, this.password));
  }

  onSignIn() {
    this.store.dispatch(new CognitoRequireSignIn(null))
  }

  _setError(err) {
    this.store.dispatch(new CognitoSetError(err))
  }
}
