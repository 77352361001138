import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

import Auth from '@aws-amplify/auth'

@Injectable({
  providedIn: 'root'
})
export class CognitoAuthService  {
  plunk
  
  authStateChanges$ = new Subject<any>()

  constructor() {
    // console.log('constructing cognito service')
    
    // FIXME - if the user signs out, this refresh activity is not cancelled
    // FIXME - it is only refreshed once?
    Auth.currentAuthenticatedUser()
      .then( user => {
        // console.log('getting currentauthenticateduser', user)
        
        if (user !== null) {
          this.setAuthState({state: 'signedIn', user: user})
        }
      })
      .catch( (pebble) => {
        console.warn('Signing out')
        this.setAuthState({state: 'signedOut', user: null})
      })
  }
  
  public setAuthState(newState: any): void {
    
    // console.info(`${JSON.stringify(newState, null, 2)}`)
    
    if (newState.state && newState.state !== 'signedIn')
      this.cancelSessionRefresh()
    else
      this.autoSessionRefresh()
    
    
    this.authStateChanges$.next(newState) // FIXME - i don't think this is working
  }
  
  private cancelSessionRefresh() {
    this.plunk && clearTimeout(this.plunk)
  }
  
  private autoSessionRefresh() {
    console.info('Engaging auto session refresh...')
    this.cancelSessionRefresh()
    
    Auth.currentAuthenticatedUser()
      .then( user => {
        if (user !== null) {
          const {auth_time, exp} = user.signInUserSession.idToken.payload
          
          this.plunk = setTimeout(
            () => {
              user.refreshSession( user.signInUserSession.refreshToken, (g) => g && console.log(`System Auth: ${JSON.stringify(g)}`) )
              
              this.autoSessionRefresh()
            },
            3540 * 1000 // (exp - auth_time - 60)*1000 // FIXME
          )
          
          // console.info(`Set to refresh in ${3540} seconds @ ${Date()}`) // FIXME
        } else {
          console.warn('Cognito: User came back as null.')
        }
      })
      .catch( (error) => {
        console.warn('caught something in autoSessionRefresh()', error)
        this.setAuthState({state: 'signedOut', user: null})
      })
  }
}
