import * as tslib_1 from "tslib";
import { State, Action, Selector } from '@ngxs/store';
import { patch, append } from '@ngxs/store/operators';
import { GatewayApiService } from '@app/services/gateway-api';
import { ToastSuccess, ToastError, ToastWarning, ToastInfo } from '@app/ngxs/toaster.ngxs';
import { NgxsError } from '@app/classes/error/ngxs-error';
import { environment } from '../../../environments/environment';
// import { ReloadPage } from '@app/ngxs/session.ngxs'
export class TestToast {
    constructor(message = '') {
        this.message = message;
    }
}
TestToast.type = '[Omni] Test Toast';
export class FetchGwRecords {
    constructor(resource, payload) {
        this.resource = resource;
        this.payload = payload;
    }
}
FetchGwRecords.type = '[Omni] Fetch';
export class FetchGwRecordsPage {
    constructor(resource, page) {
        this.resource = resource;
        this.page = page;
    }
}
FetchGwRecordsPage.type = '[Omni] Fetch Page';
export class CreateGwRecord {
    constructor(resource, payload) {
        this.resource = resource;
        this.payload = payload;
    }
}
CreateGwRecord.type = '[Omni] Create';
export class UpdateGwRecord {
    constructor(resource, payload, previous) {
        this.resource = resource;
        this.payload = payload;
        this.previous = previous;
    }
}
UpdateGwRecord.type = '[Omni] Update';
export class DisableGwRecord {
    constructor(resource, payload) {
        this.resource = resource;
        this.payload = payload;
    }
}
DisableGwRecord.type = '[Omni] Disable';
export class ClearGwRecords {
    constructor(resource) {
        this.resource = resource;
    }
}
ClearGwRecords.type = '[Omni] Clear';
export class RecordLogin {
    constructor(assumedUsername) {
        this.assumedUsername = assumedUsername;
    }
}
RecordLogin.type = '[Omni] Record Login';
export class ReloadPage {
    constructor() { }
}
ReloadPage.type = '[Omni] Reload Page';
export class GwRecordSet {
}
export class GwStateModel {
}
let GwState = class GwState {
    constructor(api) {
        this.api = api;
    }
    static getRecords(resource, state) {
        console.log('GwState @Selector was called');
        return state.index && state.index[resource];
    }
    fetch({ setState, dispatch }, { resource, payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            setState(patch({
                index: patch({ [resource]: { loading: true, expiration: null, records: [] } })
            }));
            let result = [];
            try {
                result = yield this.api.getAll(resource, payload);
            }
            catch (error) {
                console.info(`Could not fetch ${resource}.`);
                dispatch(new ToastInfo(`Could not fetch ${resource}.`));
                // throw new NgxsError('The records could not be retrieved.')
            }
            finally {
                setState(patch({
                    index: patch({ [resource]: { loading: false, expiration: Date.now() + 300000, records: result } })
                }));
            }
        });
    }
    fetchPage({ setState, dispatch }, { resource, page }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            setState(patch({
                index: patch({ [resource]: { loading: true, records: [] } })
            }));
            let result = [];
            try {
                result = yield this.api.getAll(resource);
            }
            catch (error) {
                console.info(`Could not fetch ${resource}.`);
                dispatch(new ToastInfo(`Could not fetch ${resource}.`));
            }
            finally {
                setState(patch({
                    index: patch({ [resource]: { loading: false, expiration: null, records: result } })
                }));
            }
        });
    }
    create({ setState, dispatch }, { resource, payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result;
            try {
                result = yield this.api.create(resource, payload);
                if (!result.success || !result.id) {
                    dispatch(new ToastError('There was a problem creating the record.'));
                    return;
                }
                // throw new NgxsError('API action completed, but result was not successful.')
                // if (!result.id)
                //   throw new NgxsError('API action completed successfully, but no ID was returned.')
            }
            catch (error) {
                console.info(error.message);
                dispatch(new ToastError('API communication error.'));
                throw new NgxsError(`API communication error: ${error.message}.`);
            }
            payload.id = result.id; // FIXME - should use propery Primary Key
            setState(patch({
                index: patch({
                    [resource]: patch({ records: append([payload]) })
                })
            }));
            dispatch(new ToastSuccess('Record created!'));
        });
    }
    update({ getState, setState, dispatch }, { resource, payload, previous }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const state = getState();
            setState(patch({
                index: patch({ [resource]: { loading: true, records: [...state.index[resource].records] } })
            }));
            const index = state.index[resource].records.findIndex(i => i.id == payload['id'].val); // FIXME
            if (index === -1) {
                console.info(`Error: Attempt to update a ${resource} failed because the record could not be located locally.`);
                setState(patch({
                    index: patch({ [resource]: { loading: false, records: [...state.index[resource].records] } })
                }));
                dispatch(new ToastInfo('This record may not be available anymore.'));
                return;
            }
            let updatedRecords = state.index[resource].records;
            try {
                const result = yield this.api.update(resource, payload, previous);
                if (result.success) {
                    updatedRecords = state.index[resource].records.filter(record => record.id != payload['id'].val); // FIXME should not use 'id' here.
                    updatedRecords.push(Object.keys(payload).reduce((acc, key) => {
                        acc[key] = payload[key].val;
                        return acc;
                    }, {}));
                    dispatch(new ToastSuccess('Record saved!'));
                }
                else if (result.clobbered) {
                    dispatch(new ToastWarning('The record you are editing is stale - another user may have edited it.', 'Problem Updating Record'));
                    return;
                }
                else {
                    dispatch(new ToastError('The record could not be updated.'));
                }
            }
            catch (error) {
                console.info(error.message);
                // throw new NgxsError('The record could not be updated.')
                dispatch(new ToastError('API communication error.'));
            }
            finally {
                setState(patch({
                    index: patch({ [resource]: { loading: false, records: [...updatedRecords] } })
                }));
            }
        });
    }
    disable({ getState, setState, dispatch }, { resource, payload }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const state = getState();
            setState(patch({
                index: patch({ [resource]: { loading: true, records: [...state.index[resource].records] } })
            }));
            let updatedRecords = state.index[resource].records;
            try {
                const result = yield this.api.disable(resource, `${payload.id}`); // FIXME not use ID
                if (result.success)
                    updatedRecords = state.index[resource].records.filter(record => record.id !== payload.id);
                else {
                    dispatch(new ToastError('The record could not be disabled.'));
                    return;
                }
                // throw new NgxsError('API did not return success: true.')
            }
            catch (error) {
                console.info(error.message);
                throw new NgxsError('Network error prevented the record from being deleted.');
            }
            finally {
                setState(patch({
                    index: patch({ [resource]: { loading: false, records: [...updatedRecords] } })
                }));
            }
            dispatch(new ToastSuccess('Record disabled!'));
        });
    }
    clear({ setState }, { resource }) {
        setState(patch({
            index: patch({ [resource]: { loading: false, expiration: null, records: [] } })
        }));
    }
    recordLogin({ dispatch }, { assumedUsername }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let loginResponse;
            try {
                loginResponse = this.api.login(assumedUsername);
            }
            catch (error) {
                console.error(error.message);
                dispatch(new ToastError('API Communication error'));
            }
            if (loginResponse && loginResponse.apiVersion && loginResponse.apiVersion !== environment.appVersion) {
                return dispatch(new ReloadPage());
            }
        });
    }
    reloadPage() {
        location.reload();
        return;
    }
};
tslib_1.__decorate([
    Action(FetchGwRecords),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchGwRecords]),
    tslib_1.__metadata("design:returntype", Promise)
], GwState.prototype, "fetch", null);
tslib_1.__decorate([
    Action(FetchGwRecordsPage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchGwRecordsPage]),
    tslib_1.__metadata("design:returntype", Promise)
], GwState.prototype, "fetchPage", null);
tslib_1.__decorate([
    Action(CreateGwRecord),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateGwRecord]),
    tslib_1.__metadata("design:returntype", Promise)
], GwState.prototype, "create", null);
tslib_1.__decorate([
    Action(UpdateGwRecord),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateGwRecord]),
    tslib_1.__metadata("design:returntype", Promise)
], GwState.prototype, "update", null);
tslib_1.__decorate([
    Action(DisableGwRecord),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DisableGwRecord]),
    tslib_1.__metadata("design:returntype", Promise)
], GwState.prototype, "disable", null);
tslib_1.__decorate([
    Action(ClearGwRecords),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ClearGwRecords]),
    tslib_1.__metadata("design:returntype", void 0)
], GwState.prototype, "clear", null);
tslib_1.__decorate([
    Action(RecordLogin),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RecordLogin]),
    tslib_1.__metadata("design:returntype", Promise)
], GwState.prototype, "recordLogin", null);
tslib_1.__decorate([
    Action(ReloadPage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", []),
    tslib_1.__metadata("design:returntype", void 0)
], GwState.prototype, "reloadPage", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [String, GwStateModel]),
    tslib_1.__metadata("design:returntype", Object)
], GwState, "getRecords", null);
GwState = tslib_1.__decorate([
    State({
        name: 'gwState',
        defaults: {
            loading: true,
            index: {}
        }
    }),
    tslib_1.__metadata("design:paramtypes", [GatewayApiService])
], GwState);
export { GwState };
