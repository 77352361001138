/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./forgot-password.component";
import * as i3 from "@ngxs/store";
var styles_ForgotPasswordComponent = [];
var RenderType_ForgotPasswordComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotPasswordComponent, data: {} });
export { RenderType_ForgotPasswordComponent as RenderType_ForgotPasswordComponent };
function View_ForgotPasswordComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "spinner-border spinner-border-sm"], ["role", "status"]], null, null, null, null, null))], null, null); }
function View_ForgotPasswordComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "spinner-border spinner-border-sm"], ["role", "status"]], null, null, null, null, null))], null, null); }
function View_ForgotPasswordComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Forgot Password"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Back to Sign In"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "label", [["class", "input-group-text bg-info text-light border-success w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Username"])), (_l()(), i0.ɵeld(10, 0, [[1, 0], ["tandy", 1]], null, 0, "input", [["class", "form-control"], ["placeholder", "Username"], ["type", "text"]], [[8, "value", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.setUsername($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "d-flex justify-content-center mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["class", "btn btn-outline-secondary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_2)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Send Code "])), (_l()(), i0.ɵeld(16, 0, null, null, 4, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "label", [["class", "input-group-text bg-info text-light border-success w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Code"])), (_l()(), i0.ɵeld(20, 0, [["code", 1]], null, 0, "input", [["class", "form-control"], ["placeholder", "Code"], ["type", "text"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.setCode(i0.ɵnov(_v, 20).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "label", [["class", "input-group-text bg-info text-light border-success w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["New Password"])), (_l()(), i0.ɵeld(25, 0, [["password", 1]], null, 0, "input", [["class", "form-control"], ["placeholder", "New Password"], ["type", "password"]], null, [[null, "keyup"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.setPassword(i0.ɵnov(_v, 25).value) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.onSubmit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 4, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 3, "button", [["class", "btn btn-outline-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_3)), i0.ɵdid(29, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Submit "])), (_l()(), i0.ɵeld(31, 0, null, null, 1, "div", [["class", "alert alert-warning mt-2"]], [[2, "invisible", null]], null, null, null, null)), (_l()(), i0.ɵted(32, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loadingForgot; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.loadingCode; _ck(_v, 29, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.username; _ck(_v, 10, 0, currVal_0); var currVal_3 = !_co.errorMessage; _ck(_v, 31, 0, currVal_3); var currVal_4 = _co.errorMessage; _ck(_v, 32, 0, currVal_4); }); }
export function View_ForgotPasswordComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { usernameControl: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ForgotPasswordComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "auth-forgot-password", [], null, null, null, View_ForgotPasswordComponent_0, RenderType_ForgotPasswordComponent)), i0.ɵdid(1, 4243456, null, 0, i2.ForgotPasswordComponent, [i3.Store], null, null)], null, null); }
var ForgotPasswordComponentNgFactory = i0.ɵccf("auth-forgot-password", i2.ForgotPasswordComponent, View_ForgotPasswordComponent_Host_0, { authState: "authState" }, {}, []);
export { ForgotPasswordComponentNgFactory as ForgotPasswordComponentNgFactory };
