import { BrowserModule }           from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, ErrorHandler }  from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule }        from '@angular/common/http'

import { environment }             from '../environments/environment'

import { AppComponent }            from '@app/app.component'
import { AppRoutingModule }        from '@app/app-routing.module'

import { AppErrorReportingService } from '@app/services/app-error-reporting.service'

import { NgbModule }               from '@ng-bootstrap/ng-bootstrap'
import { NgIdleModule }            from '@ng-idle/core'
import { SidebarModule }           from 'ng-sidebar'
import { NgxSpinnerModule }        from '@app/errata/ngx-spinner/ngx-spinner.module'
import { ToastrModule }            from 'ngx-toastr'
import { NgxsModule }              from '@ngxs/store'

import { OverviewComponent }  from '@app/page/overview/overview.component'
import { ProfileComponent }                   from '@app/page/account/profile/profile.component'
import { ManageResourceComponent }   from '@app/page/manage-resource/manage-resource.component'
import { ViewEditResourceComponent } from '@app/page/manage-resource/view-edit-resource/view-edit-resource.component'
import { OfficesComponent }   from '@app/page/offices/offices.component'
import { MidGroupsComponent } from '@app/page/mid-groups/mid-groups.component'
import { RoutesComponent }    from '@app/page/routes/routes.component'
import { MidsComponent }      from '@app/page/mids/mids.component'
import { PaymentsComponent }  from '@app/page/payments/payments.component'
import { NotFoundComponent }  from '@app/page/not-found/not-found.component'
import { AppErrorComponent }  from '@app/page/app-error/app-error.component'

import { SessionState } from '@app/ngxs'
import { KeepaliveState } from '@app/ngxs'
import { HeartbeatState } from '@app/ngxs'
import { GwState }      from '@app/ngxs/gateway-api'
import { ToasterState } from '@app/ngxs/toaster.ngxs'

import { AuthenticatorComponent }      from '@app/ui/authenticator/authenticator.component'
import { SignInComponent }             from '@app/ui/authenticator/sign-in.component'
import { RequireNewPasswordComponent } from '@app/ui/authenticator/require-new-password.component'
import { ConfirmSignInComponent }      from '@app/ui/authenticator/confirm-sign-in.component'
import { SignUpComponent }             from '@app/ui/authenticator/sign-up.component'
import { ConfirmSignUpComponent }      from '@app/ui/authenticator/confirm-sign-up.component'
import { ForgotPasswordComponent }     from '@app/ui/authenticator/forgot-password.component'

import { DynamicFormModule }       from '@app/ui/dynamic-form/dynamic-form.module'
import { StandardFormModule }      from '@app/ui/s-form/standard-form.module'
import { SectionSpinnerComponent } from '@app/ui/section-spinner/section-spinner.component'

import { GwResourceModule } from '@app/ui/templates/gw-resource/gw-resource.module'

import { MainMenuComponent }        from '@app/ui/widgets/main-menu/main-menu.component'
import { AffiliateMenuComponent }   from '@app/ui/widgets/affiliate-menu/affiliate-menu.component'
import { SuperuserMenuComponent }   from '@app/ui/widgets/superuser-menu/superuser-menu.component'
import { SuperuserConfigMenuComponent } from '@app/ui/widgets/superuser-config-menu/superuser-config-menu.component'
import { MerchantMenuComponent }    from '@app/ui/widgets/merchant-menu/merchant-menu.component'
import { BsReferenceComponent }     from '@app/ui/widgets/bs-reference/bs-reference.component'
import { UniversalFooterComponent } from '@app/ui/widgets/universal-footer/universal-footer.component'

import {
  ManifestComponent,
  ManifestPageComponent
} from '@app/page/manifest'

import { AboutComponent } from '@app/ui/modals/about/about.component'

// import { SelectResourceComponent }    from '@app/page/select-resource/select-resource.component'
// import { MainSearchBoxComponent }     from '@crm/ui/widgets/search/main/main-search-box/main-search-box.component'
// import { MainSearchResultsComponent } from '@crm/ui/widgets/search/main/main-search-results/main-search-results.component'

@NgModule({
  declarations: [
    AppComponent,
    ManifestComponent,
    ManifestPageComponent,
    AuthenticatorComponent,
    ConfirmSignInComponent,
    ConfirmSignUpComponent,
    ForgotPasswordComponent,
    RequireNewPasswordComponent,
    SignInComponent,
    SignUpComponent,
    SectionSpinnerComponent,
    OfficesComponent,
    MidGroupsComponent,
    RoutesComponent,
    MidsComponent,
    PaymentsComponent,
    OverviewComponent,
    AffiliateMenuComponent,
    MainMenuComponent,
    SuperuserMenuComponent,
    MerchantMenuComponent,
    // SelectResourceComponent, // FIXME
    ManageResourceComponent,
    ViewEditResourceComponent,
    BsReferenceComponent,
    ProfileComponent,
    SuperuserConfigMenuComponent,
    AppErrorComponent,
    NotFoundComponent,
    UniversalFooterComponent,
    AboutComponent,
  ],
  imports: [
    DynamicFormModule,
    StandardFormModule,
    GwResourceModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SidebarModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgIdleModule.forRoot(),
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    NgxsModule.forRoot([
      SessionState,
      GwState,
      ToasterState,
      KeepaliveState,
      HeartbeatState,
    ], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    })
  ],
  providers: [
    { provide: 'Window',  useValue: window },
    { provide: ErrorHandler, useClass: AppErrorReportingService },
  ],
  entryComponents: [
    AboutComponent,
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }