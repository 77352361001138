import { Component, Input } from '@angular/core';
// import { AmplifyService } from 'aws-amplify-angular'
import { takeUntil, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';

import { Store } from '@ngxs/store'
import { CognitoRequireSignIn, CognitoSetError } from '@app/ngxs'


import { CognitoAuthService } from '@app/services/cognito-auth/cognito-auth.service'
import Auth from '@aws-amplify/auth'

@Component({
  selector: 'auth-confirm-sign-in',
  templateUrl: './confirm-sign-in.component.html'
})
export class ConfirmSignInComponent {
  _authState: any
  _show: boolean
  code: string
  errorMessage: string
  
  error$: Subscription
  
  destroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private store: Store,
    // private amplifyService: AmplifyService
    // private cognitoAuth: CognitoAuthService
  ) {
    this.error$ = this.store.select(state => state.session.auth)
      .pipe(
        distinctUntilChanged(),
        filter(s => s),
        takeUntil(this.destroyed$),
      )
      .subscribe(authState => {
        this.errorMessage = authState.errorMessage
      })
    
  }

  @Input()
  set authState(authState: any) {
    this._authState = authState
    this._show = authState.state === 'confirmSignIn'
  }

  setCode(code: string) {
    this.code = code
  }

  onConfirm() {
    const { user } = this._authState
    const { challengeName } = user
    const mfaType = challengeName === 'SOFTWARE_TOKEN_MFA' ? challengeName : null
    
    // this.store.dispatch(new CognitoConfirmSignIn(user, this.code, mfaType))
    
    // this.amplifyService.auth()
    //   .confirmSignIn(
    //     user,
    //     this.code,
    //     mfaType
    //   )
    //   .then(() => {
    //     this.amplifyService.setAuthState({ state: 'signedIn', user: user })
    //   })
    //   .catch(err => this._setError(err))
    
    // Auth.confirmSignIn(user, this.code, mfaType)
    //   .then( () => this.cognitoAuth.setAuthState({ state: 'signedIn', user: user}))
    //   .catch( err => this._setError(err) )
  }

  onSignIn() {
    this.store.dispatch(new CognitoRequireSignIn(null))
    // this.amplifyService.setAuthState({ state: 'signIn', user: null })
    // this.cognitoAuth.setAuthState({ state: 'signIn', user: null })
  }

  _setError(err) {
    this.store.dispatch(new CognitoSetError(err))
  }
}
