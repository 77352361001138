<div class="row my-2">
  <div class="col">

    <ul class="list-group">
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_bran">offices</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/office">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_clnt">merchants</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/merchant">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_magt">affiliates</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/affiliate">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_mgrp">mid-groups</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/mid-group">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_mmas">routes</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/route">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_mmid">mids</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/mid">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_user">users</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/user">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_agcy">agencies</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/agency">Manage</a></span>
      </li>
      <!--<li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">-->
      <!--  <a routerLink="/manifest/sbps_srep">sales representatives</a>-->
      <!--  <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/sbps_srep">Manage</a></span>-->
      <!--</li>-->
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_stat">statuses</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/status">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_ptyp">payment types</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/payment-type">Manage</a></span>
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <a routerLink="/manifest/sbps_vndr">processors</a>
        <span class="badge badge-primary badge-pill"><a class="text-reset" routerLink="/select/processor">Manage</a></span>
      </li>
    </ul>

  </div>
</div>