import { Injectable, OnDestroy } from '@angular/core'

import { interval } from 'rxjs'

import { Store } from '@ngxs/store'
import { GatewayApiService } from './gateway-api'
import { PingHeartbeat } from '@app/ngxs/heartbeat.ngxs'

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService implements OnDestroy {
  private pingSub
     
  constructor(
    private store: Store,
  ) { 
    this.start()
  }
  
  ngOnDestroy() {
    this.stop()
  }
  
  start() {
    this.ping()
    this.pingSub = interval(1000*60*4).subscribe(() => {
      this.ping()
    })
  }
  
  stop() {
    this.ping()
    this.pingSub.unsubscribe()
  }
  
  ping(param?: any) {
    if (param) {
      this.store.dispatch(new PingHeartbeat(param))
    } else {
      this.store.dispatch(new PingHeartbeat())
    }
  }
}
