<ng-template [ngIf]="_show">
  <div class="d-flex justify-content-center">
    <h3>Forgot Password</h3>
  </div>
      
  <div class="d-flex justify-content-center">
    <button type="button" (click)="onSignIn()" class="btn">Back to Sign In</button>
  </div>

  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text bg-info text-light border-success w-100">Username</label>
    </div>
    <input #tandy class="form-control" (keyup)="setUsername($event.target.value)" type="text" placeholder="Username" [value]="username"/>
  </div>
  
  <div class="d-flex justify-content-center mb-2">
    <button class="btn btn-outline-secondary mr-2" (click)="onSend()">
      <span *ngIf="loadingForgot" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Send Code
    </button>
  </div>

  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text bg-info text-light border-success w-100">Code</label>
    </div>
    <input #code (keyup)="setCode(code.value)" class="form-control" type="text" placeholder="Code"/>
  </div>
  
  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text bg-info text-light border-success w-100">New Password</label>
    </div>
    <input #password (keyup)="setPassword(password.value)" (keyup.enter)="onSubmit()" class="form-control" type="password" placeholder="New Password"/>
  </div>
  
  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-secondary" (click)="onSubmit()">
      <span *ngIf="loadingCode" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Submit
    </button>
  </div>
  
  <div class="alert alert-warning mt-2" [class.invisible]="!errorMessage">{{ errorMessage }}</div>
</ng-template>