import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, FormArray } from '@angular/forms'

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  config
  group: FormGroup
  
  hovering: boolean = false
  editing: boolean = false
  readOnly: boolean
  
  controlRef: FormControl
  controlArray: FormArray
  controlA: FormControl
  controlB: FormControl

  constructor() { }
  
  ngOnInit() {
    this.group.addControl(this.config.name, this.controlRef = new FormControl(this.config.value || this.config.default))
    
    this.group.addControl(this.config.name + 'Meta', this.controlArray = new FormArray(
      [
        this.controlA = new FormControl(this.config.value || this.config.default, this.config.validators),
        this.controlB = new FormControl(this.config.value || this.config.default, this.config.validators),
      ],
      {
        validators: passwordMatch
      }
    ))
    
    this.controlA.valueChanges.subscribe( c => { console.log('noticed a change', c); this.controlRef.setValue(c) })
    
    this.readOnly = this.config.options && this.config.options.readOnly
  }
  
  hoverOn() {
    this.hovering = !this.editing && true && !this.readOnly
  }
  
  hoverOff() {
    this.hovering = !this.editing && false
  }
  
  makeEditable() {
    this.hovering = false
    this.editing = true && !this.readOnly
  }
  
  markSave() {
    this.editing = false
  }
  
  markReset() {
    this.controlRef.reset(this.config.value || '')
    this.controlA.reset(this.config.value || '')
    this.controlB.reset(this.config.value || '')
    this.editing = false
  }
  
}

function passwordMatch(fa: FormArray) {
  
  if (fa && fa.controls) {
    if (fa.controls[0].value === fa.controls[1].value) {
      return null
    } else {
      return { passwordMismatch: true }
    }
  }
  
  return null
}