import { Component, Input, OnChanges } from '@angular/core'
import { FormGroup, FormArray, FormControl } from '@angular/forms'

@Component({
  selector: 'app-s-password',
  templateUrl: './s-password.component.html',
  styleUrls: ['./s-password.component.css']
})
export class SPasswordComponent { // implements OnChanges {
  // _config: any
  @Input()
  config: any
  
  // set config(c) {
  //   if (c) {
  //     this._config = c
  //     this.group.addControl(this.config.name, this.controlRef = new FormControl(this.config.value || this.config.default, this.config.validators))
      
  //     this.group.addControl(this.config.name + 'Meta', this.controlArray = new FormArray(
  //       [
  //         this.controlA = new FormControl(this.config.value || this.config.default, this.config.validators),
  //         this.controlB = new FormControl(this.config.value || this.config.default, this.config.validators),
  //       ],
  //       {
  //         validators: passwordMatch
  //       }
  //     ))
      
  //     this.controlA.valueChanges.subscribe( c => this.controlRef.setValue(c) )
    
  //     this.readOnly = this.config.options && this.config.options.readOnly
  //   }
  // }
  
  // get config() {
  //   return this._config
  // }
  
  @Input()
  group: FormGroup
  
  hovering: boolean = false
  editing: boolean = false
  readOnly: boolean
  
  controlRef: FormControl
  controlArray: FormArray
  controlA: FormControl
  controlB: FormControl

  constructor() { }
  
  ngOnChanges() {
    if (this.controlRef) {
      this.controlRef.patchValue( undefined )
      this.controlRef.setValidators( this.config.validators )
    } else if (this.config) {
      this.group.addControl(this.config.name, this.controlRef = new FormControl(undefined, this.config.validators))
      
      this.controlRef.patchValue( undefined ) // FIXME shouldn't be necessary, see bug in github
      
      this.group.addControl(this.config.name + 'Meta', this.controlArray = new FormArray(
        [
          this.controlA = new FormControl(this.config.value || this.config.default, this.config.validators),
          this.controlB = new FormControl(this.config.value || this.config.default, this.config.validators),
        ],
        {
          validators: passwordMatch
        }
      ))
      
      this.controlA.valueChanges.subscribe( c => {
        if (c)
          this.controlRef.setValue( c )
        else
          this.controlRef.patchValue( undefined )
        })
    
      this.readOnly = this.config.options && this.config.options.readOnly
    }
  }
  
  public reset() {
    this.controlRef.reset(undefined)
    this.controlA.reset(this.config.value || this.config.default)
    this.controlB.reset(this.config.value || this.config.default)
  }
}

function passwordMatch(fa: FormArray) {
  
  if (fa && fa.controls) {
    if (fa.controls[0].value === fa.controls[1].value) {
      return null
    } else {
      return { passwordMismatch: true }
    }
  }
  
  return null
}