<app-s-hidden #idControl [config]="fields['id']" [group]="formGroup"></app-s-hidden>
<app-s-select #merchantIdControl [config]="fields['merchant_id']" [group]="formGroup"></app-s-select>
<!--<app-s-text #branchIdControl [config]="fields['branch_id']" [group]="formGroup"></app-s-text>-->
<app-s-text #nameControl [config]="fields['name']" [group]="formGroup"></app-s-text>
<app-s-text #dbaControl [config]="fields['dba']" [group]="formGroup"></app-s-text>
<app-s-text #address1Control [config]="fields['address_1']" [group]="formGroup"></app-s-text>
<app-s-text #address2Control [config]="fields['address_2']" [group]="formGroup"></app-s-text>
<app-s-text #cityControl [config]="fields['city']" [group]="formGroup"></app-s-text>
<app-s-text #stateControl [config]="fields['state']" [group]="formGroup"></app-s-text>
<app-s-text #postalCodeControl [config]="fields['postal_code']" [group]="formGroup"></app-s-text>
<app-s-text #phoneLocalControl [config]="fields['phone_local']" [group]="formGroup"></app-s-text>
<app-s-text #phoneTollFreeControl [config]="fields['phone_toll_free']" [group]="formGroup"></app-s-text>
<app-s-text #phoneFaxControl [config]="fields['phone_fax']" [group]="formGroup"></app-s-text>
<app-s-text #hoursOfOperationControl [config]="fields['hours_of_operation']" [group]="formGroup"></app-s-text>
<app-s-text #webUrlControl [config]="fields['web_url']" [group]="formGroup"></app-s-text>
<app-s-text #emailControl [config]="fields['email']" [group]="formGroup"></app-s-text>
<app-s-toggle #ipLockControl [config]="fields['ip_lock']" [group]="formGroup"></app-s-toggle>
<app-s-text #ftpFolderControl [config]="fields['ftp_folder']" [group]="formGroup"></app-s-text>
<app-s-text #uploadFolderControl [config]="fields['upload_folder']" [group]="formGroup"></app-s-text>
<app-s-text #stageFolderControl [config]="fields['stage_folder']" [group]="formGroup"></app-s-text>
<app-s-toggle #allowBatchUploadControl [config]="fields['allow_batch_upload']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #enforceCtControl [config]="fields['enforce_ct']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #requireCvvControl [config]="fields['require_cvv']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #requireAddressControl [config]="fields['require_address']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #requireStateControl [config]="fields['require_state']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #requireZipCodeControl [config]="fields['require_zip_code']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #webPmtNotifyControl [config]="fields['web_pmt_notify']" [group]="formGroup"></app-s-toggle>
<app-s-text #webPmtEmailControl [config]="fields['web_pmt_email']" [group]="formGroup"></app-s-text>
<app-s-toggle #planPmtNotifyControl [config]="fields['plan_pmt_notify']" [group]="formGroup"></app-s-toggle>
<app-s-text #planPmtEmailControl [config]="fields['plan_pmt_email']" [group]="formGroup"></app-s-text>
<app-s-toggle #amountBaseControl [config]="fields['amount_base']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountConvFeeControl [config]="fields['amount_conv_fee']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountLateFeeControl [config]="fields['amount_late_fee']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountNsfFeeControl [config]="fields['amount_nsf_fee']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountShippingControl [config]="fields['amount_shipping']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountInterestControl [config]="fields['amount_interest']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountTaxControl [config]="fields['amount_tax']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountDiscountControl [config]="fields['amount_discount']" [group]="formGroup"></app-s-toggle>
<app-s-toggle #amountCalcGross [config]="fields['calc_gross']" [group]="formGroup"></app-s-toggle>
<app-s-select #statusIdControl [config]="fields['status_id']" [group]="formGroup"></app-s-select>