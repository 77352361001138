import * as tslib_1 from "tslib";
import { State, Action } from '@ngxs/store';
export class ToastSuccess {
    constructor(message, title, options, tType = 'success') {
        this.message = message;
        this.title = title;
        this.options = options;
        this.tType = tType;
    }
}
ToastSuccess.type = '[Toast] Success';
export class ToastError {
    constructor(message, title, options, tType = 'error') {
        this.message = message;
        this.title = title;
        this.options = options;
        this.tType = tType;
    }
}
ToastError.type = '[Toast] Error';
export class ToastWarning {
    constructor(message, title, options, tType = 'warning') {
        this.message = message;
        this.title = title;
        this.options = options;
        this.tType = tType;
    }
}
ToastWarning.type = '[Toast] Warning';
export class ToastInfo {
    constructor(message, title, options, tType = 'info') {
        this.message = message;
        this.title = title;
        this.options = options;
        this.tType = tType;
    }
}
ToastInfo.type = '[Toast] Info';
export class ToastShow {
    constructor(message, title, options, tType = 'success') {
        this.message = message;
        this.title = title;
        this.options = options;
        this.tType = tType;
    }
}
ToastShow.type = '[Toast] Show';
export class ToasterStateModel {
}
let ToasterState = class ToasterState {
    constructor() { }
    success({ setState }, { message, title, options, tType }) {
        setState({
            message,
            title,
            options,
            tType,
        });
    }
    error({ setState }, { message, title, options, tType }) {
        setState({
            message,
            title,
            options,
            tType,
        });
    }
    warning({ setState }, { message, title, options, tType }) {
        setState({
            message,
            title,
            options,
            tType,
        });
    }
    info({ setState }, { message, title, options, tType }) {
        setState({
            message,
            title,
            options,
            tType,
        });
    }
    show({ setState }, { message, title, options, tType }) {
        // this.toaster.show(toast.message)
    }
};
tslib_1.__decorate([
    Action(ToastSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToastSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], ToasterState.prototype, "success", null);
tslib_1.__decorate([
    Action(ToastError),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToastError]),
    tslib_1.__metadata("design:returntype", void 0)
], ToasterState.prototype, "error", null);
tslib_1.__decorate([
    Action(ToastWarning),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToastWarning]),
    tslib_1.__metadata("design:returntype", void 0)
], ToasterState.prototype, "warning", null);
tslib_1.__decorate([
    Action(ToastInfo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToastInfo]),
    tslib_1.__metadata("design:returntype", void 0)
], ToasterState.prototype, "info", null);
tslib_1.__decorate([
    Action(ToastShow),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToastShow]),
    tslib_1.__metadata("design:returntype", void 0)
], ToasterState.prototype, "show", null);
ToasterState = tslib_1.__decorate([
    State({
        name: 'toaster',
        defaults: null,
    }),
    tslib_1.__metadata("design:paramtypes", [])
], ToasterState);
export { ToasterState };
