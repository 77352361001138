import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  config
  group: FormGroup
  
  hovering: boolean = false
  editing: boolean = false
  readOnly: boolean
  
  controlRef: FormControl

  constructor() { }
  
  ngOnInit() {
    this.group.addControl(this.config.name, this.controlRef = new FormControl({ value: this.config.value || this.config.default, disabled: this.config.readOnly }, this.config.validators))
    // this.controlRef = this.group.get(this.config.name) as FormControl
    
    this.readOnly = this.config.options && this.config.options.readOnly
  }
  
  hoverOn() {
    this.hovering = !this.editing && true && !this.readOnly
  }
  
  hoverOff() {
    this.hovering = !this.editing && false
  }
  
  makeEditable() {
    this.hovering = false
    this.editing = true && !this.readOnly
  }
  
  markSave() {
    this.editing = false
  }
  
  markReset() {
    this.controlRef.reset({ value: this.config.value, disabled: this.config.readOnly })
    this.editing = false
  }
}
