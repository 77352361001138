import { Routes } from '@angular/router';
import { AuthGuardService } from '@app/services';
import { OverviewComponent } from '@app/page/overview/overview.component';
// import { SelectResourceComponent } from '@app/page/select-resource/select-resource.component'
import { ManageResourceComponent } from '@app/page/manage-resource/manage-resource.component';
import { ProfileComponent } from '@app/page/account/profile/profile.component';
import { OfficesComponent } from '@app/page/offices/offices.component';
import { PaymentsComponent } from '@app/page/payments/payments.component';
import { MidGroupsComponent } from '@app/page/mid-groups/mid-groups.component';
import { RoutesComponent } from '@app/page/routes/routes.component';
import { MidsComponent } from '@app/page/mids/mids.component';
import { AppErrorComponent } from '@app/page/app-error/app-error.component';
import { NotFoundComponent } from '@app/page/not-found/not-found.component';
import { ManifestComponent, ManifestPageComponent, } from '@app/page/manifest';
const ɵ0 = { animation: 'overview' }, ɵ1 = () => import("./ng-modules/secondary/secondary.module.ngfactory").then(m => m.SecondaryModuleNgFactory), ɵ2 = { animation: 'select resource' }, ɵ3 = { animation: 'manage resource' }, ɵ4 = { animation: 'manifest' }, ɵ5 = { animation: 'manifest page' }, ɵ6 = { animation: 'account profile' }, ɵ7 = () => import("./ng-modules/crm/crm.module.ngfactory").then(m => m.CrmModuleNgFactory), ɵ8 = { animation: 'crm section' }, ɵ9 = () => import("./ng-modules/merchants/merchants.module.ngfactory").then(m => m.MerchantsModuleNgFactory), ɵ10 = { animation: 'merchant section' };
const routes = [
    { path: '', component: OverviewComponent, data: ɵ0 },
    // { path: 'select/:resource', component: SelectResourceComponent },
    { path: 'select/:resource', loadChildren: ɵ1, data: ɵ2 },
    { path: 'manage/:resource/:id', component: ManageResourceComponent, data: ɵ3 },
    // { path: 'merchants', loadChildren: './merchants/merchants.module#MerchantsModule' },
    { path: 'offices', component: OfficesComponent },
    { path: 'payments', component: PaymentsComponent },
    { path: 'mid-groups', component: MidGroupsComponent },
    { path: 'routes', component: RoutesComponent },
    { path: 'mids', component: MidsComponent },
    { path: 'manifest', component: ManifestComponent, data: ɵ4 },
    { path: 'manifest/:page', component: ManifestPageComponent, data: ɵ5 },
    { path: 'account/profile', component: ProfileComponent, data: ɵ6 },
    { path: 'crm', loadChildren: ɵ7, data: ɵ8, canLoad: [AuthGuardService] },
    { path: 'merchant', loadChildren: ɵ9, data: ɵ10, canLoad: [AuthGuardService] },
    { path: 'app-error', component: AppErrorComponent },
    { path: '**', component: NotFoundComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
