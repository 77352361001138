import { Component, Input, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'

import {
  UserFormComponent,
  SuperuserFormComponent,
  AffiliateFormComponent,
  MerchantFormComponent,
  OfficeFormComponent,
  MidGroupFormComponent,
  MidFormComponent,
  RouteFormComponent,
  PaymentTypeFormComponent,
  StatusFormComponent,
  AgencyFormComponent,
  ProcessorFormComponent,
} from '@app/ui/templates/gw-resource/gw-form-container'
  
import { GwResource } from '@app/models/gateway-api/gw-resource.type'

@Component({
  selector: 'app-gw-form-container',
  templateUrl: './gw-form-container.component.html',
  styleUrls: ['./gw-form-container.component.scss']
})
export class GwFormContainerComponent {
  
  @ViewChild(UserFormComponent, { static: false })
  userForm: UserFormComponent
  
  @ViewChild(SuperuserFormComponent, { static: false })
  superuserForm: SuperuserFormComponent
  
  @ViewChild(AffiliateFormComponent, { static: false })
  affiliateForm: AffiliateFormComponent
  
  @ViewChild(MerchantFormComponent, { static: false })
  merchantForm: MerchantFormComponent
  
  @ViewChild(OfficeFormComponent, { static: false })
  officeForm: OfficeFormComponent
  
  @ViewChild(MidGroupFormComponent, { static: false })
  midGroupForm: MidGroupFormComponent
  
  @ViewChild(MidFormComponent, { static: false })
  midForm: MidFormComponent
  
  @ViewChild(RouteFormComponent, { static: false })
  routeForm: RouteFormComponent
  
  @ViewChild(PaymentTypeFormComponent, { static: false })
  paymentTypeForm: RouteFormComponent
  
  @ViewChild(StatusFormComponent, { static: false })
  statusForm: StatusFormComponent
  
  @ViewChild(AgencyFormComponent, { static: false })
  agencyForm: AgencyFormComponent
  
  @ViewChild(ProcessorFormComponent, { static: false })
  processorForm: ProcessorFormComponent
  
  @Input()
  fields: any[]
  
  @Input()
  resourceAlias: GwResource
  
  @Input()
  permissions: any
  
  @Input()
  roles: any
  
  @Input()
  formGroup: FormGroup
  
  public readFormVals() {
    return this.formGroup.getRawValue()
  }
  
  public reset() {
    
    switch (this.resourceAlias) {
      case <GwResource>'user':
        this.userForm.reset()
        break
        
      case <GwResource>'superuser':
        this.superuserForm.reset()
        break
        
      case <GwResource>'affiliate':
        this.affiliateForm.reset()
        break
        
      case <GwResource>'route':
        this.routeForm.reset()
        break
        
      case <GwResource>'merchant':
        this.merchantForm.reset()
        break
        
      case <GwResource>'office':
        this.officeForm.reset()
        break
        
      case <GwResource>'mid-group':
        this.midGroupForm.reset()
        break
        
      case <GwResource>'mid':
        this.midForm.reset()
        break
        
      case <GwResource>'payment-type':
        this.paymentTypeForm.reset()
        break
        
      case <GwResource>'status':
        this.statusForm.reset()
        break
      
      default:
        console.log('unrecognized resource alias: ', this.resourceAlias)
    }
    
  }

  constructor() { }

}
