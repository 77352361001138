<div class="container" style="height: 100vh;">
  <div class="row h-100 align-items-center">
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12 mb-4">
          <img src="assets/img/STEPpay-logo-login.svg" class="img-fluid rounded mx-auto" />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12 align-self-center align-middle">
          <form autocomplete="off">
            <auth-sign-in *ngIf="['signIn', 'signedOut', 'signedUp'].includes(authState.state)" [authState]="authState"></auth-sign-in>
            <auth-require-new-password *ngIf="authState.state === 'requireNewPassword'" [authState]="authState"></auth-require-new-password>
            <auth-confirm-sign-in *ngIf="authState.state === 'confirmSignIn'" [authState]="authState"></auth-confirm-sign-in>
            <auth-forgot-password *ngIf="authState.state === 'forgotPassword'" [authState]="authState"></auth-forgot-password>
          </form>
        </div>
      </div>
      
      <app-universal-footer></app-universal-footer>
    </div>
  </div>
</div>