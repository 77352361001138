<ng-template #loader>
  
  <div class="ph-item">
    <div class="ph-col-12"></div>
  </div>

</ng-template>

<div *ngIf="config; else loader" class="form-group form-row mb-0" [formGroup]="group">
  <div class="col-sm-3 text-sm-right">
    <label class="col-form-label" [for]="config.name">{{ config.label }}</label>
  </div>
  <div class="col-sm-9">
    <div class="d-flex">
      <div class="input-group border rounded" [ngClass]="{'border-info': editing, 'border-primary': controlRef.dirty && !editing, 'border-dark': !controlRef.dirty && !editing}">
        <input
          [ngClass]="(config?.options?.readOnly) ? 'form-control-plaintext' : 'form-control'"
          type="text"
          (focus)="editable(true)"
          (keyup.esc)="doReset()"
          (blur)="editable(false)"
          [id]="config.name"
          [name]="config.name"
          [formControlName]="config.name"
          [placeholder]="config.placeholder"
          [readonly]="config?.options?.readOnly">
        <div *ngIf="controlRef.dirty" class="input-group-append border-0">
          <button type="button" class="btn btn-dark input-group-text" (click)="doReset()"><i class="fas fa-times-circle"></i></button>
        </div>
      </div>
      <button
        type="button"
        class="btn ml-1"
        placement="right"
        ngbPopover="{{ config.helpText }}">
        <i class="fas fa-question-circle"></i>
      </button>
    </div>
    <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="controlRef.status !== 'INVALID'">
      <app-error-messages *ngIf="controlRef.errors" [errors]="controlRef.errors"></app-error-messages>
      &nbsp;
    </small>
  </div>
</div>