import { Component, OnInit, Input } from '@angular/core'

import { SessionStateModel } from '@app/ngxs'

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  @Input()
  session: SessionStateModel = null

  constructor() {}

  ngOnInit() {}

}
