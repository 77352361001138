import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { AmplifyService } from 'aws-amplify-angular'
import Auth from '@aws-amplify/auth';
import { environment } from '../../../environments/environment';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GatewayApiService {
    constructor(
    // private amplify: AmplifyService,
    http) {
        this.http = http;
        this.apiUri = environment.gwApiUri;
        this.legacyUri = environment.legacyUri;
    }
    /**
     * Should return a single item, if it exists.  Our API always returns an array
     * so here we will reduce this to a single item.
     */
    getOne(resource, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result;
            try {
                result = yield this.http.get(`${this.apiUri}/resource/${resource}`, yield this.makeOptions({ id: id })).toPromise();
            }
            catch (err) {
                console.log(err.message);
                return null;
            }
            if (result.length === 1) {
                return result[0];
            }
            // FIXME perhaps throw an error at this point.
            return null;
        });
    }
    getAll(resource, payload = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.post(`${this.apiUri}/resource/${resource}`, { action: 'READ', payload: payload || {} }, yield this.makeOptions()).toPromise();
            }
            catch (error) {
                console.error(error.message);
            }
            return null;
        });
    }
    getPage(resource, payload = null, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.get(`${this.apiUri}/paged/${resource}`, yield this.makeOptions(payload)).toPromise();
            }
            catch (error) {
                console.error(error.message); // FIXME error service
                throw error;
            }
        });
    }
    create(resource, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.post(`${this.apiUri}/resource/${resource}`, { action: 'CREATE', payload: payload }, yield this.makeOptions()).toPromise();
            }
            catch (err) {
                console.log(err.message);
                return null;
            }
        });
    }
    update(resource, payload, previous) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.post(`${this.apiUri}/resource/${resource}`, { action: 'UPDATE', payload, previous }, yield this.makeOptions()).toPromise();
            }
            catch (err) {
                console.log(err.message);
                return { success: false };
            }
        });
    }
    disable(resource, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // FIXME id may be compound
            const payload = {
                id: {
                    op: '=',
                    val: id,
                },
                status: {
                    op: '=',
                    val: 'inactive'
                }
            };
            try {
                return yield this.http.post(`${this.apiUri}/resource/${resource}`, { action: 'DELETE', payload: payload }, yield this.makeOptions()).toPromise();
            }
            catch (err) {
                console.log(err.message);
                return { success: false };
            }
        });
    }
    makeOptions(params = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const options = {
                headers: new HttpHeaders({
                    // 'Accept-Encoding': '*', // FIXME - API Gateway says I must set this header to something but Fetch API doesn't allow it.
                    'Content-Type': 'application/json',
                    // 'Authorization': (await this.amplify.auth().currentSession()).getIdToken().getJwtToken()
                    'Authorization': (yield Auth.currentSession()).getIdToken().getJwtToken()
                })
            };
            if (params) {
                options.params = new HttpParams({
                    fromObject: params
                });
            }
            return options;
        });
    }
    unique(table, field, term) {
        return from(this.makeOptions({ term: term }))
            .pipe(switchMap(opts => this.http.get(`${this.apiUri}/unique/${table}/${field}`, opts))
        // FIXME find out what 'opts' is.
        );
    }
    login(assumedUsername) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.post(`${this.apiUri}/login/${assumedUsername}`, undefined, yield this.makeOptions()).toPromise();
            }
            catch (error) {
                console.error(error.message);
                return null;
            }
        });
    }
    getQuickSightDashboard(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.get(`${this.apiUri}/quicksight/get-dashboard`, yield this.makeOptions(payload)).toPromise();
            }
            catch (error) {
                console.error(error.message);
                throw error;
            }
        });
    }
    legacySystem(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.http.get(this.legacyUri, yield this.makeOptions(payload)).toPromise();
            }
            catch (error) {
                console.log(error.message);
                if (error.status > 0 && error.error)
                    return error.error;
                throw error;
            }
        });
    }
    heartbeat(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const accessToken = (yield Auth.currentSession()).getAccessToken()["jwtToken"];
                return yield this.http.post(`${this.apiUri}/heartbeat/checkin`, { "accessToken": accessToken }, yield this.makeOptions()).toPromise();
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
}
GatewayApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GatewayApiService_Factory() { return new GatewayApiService(i0.ɵɵinject(i1.HttpClient)); }, token: GatewayApiService, providedIn: "root" });
