import { Component, Input, ViewChildren, QueryList, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-mid-form',
  templateUrl: './mid-form.component.html',
  styleUrls: ['./mid-form.component.scss']
})
export class MidFormComponent {
  @ViewChildren('clientIdControl, processorIdControl, merchantTrustAccountControl, merchantTrustLabelControl, merchantTrustNameControl, fileCodeControl, fileCodeFullControl, midControl, processorTidControl, passwordControl, ftpPasswordControl, apiLoginIdControl, secureTransactionKeyControl, aaMidControl, amexMidControl, affiliateIdControl, splitFreeTokenKeyControl, statusIdControl')
  controls: QueryList<any>
  
  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }
  
  ngOnInit() {
    console.log(this.fields)
  }
  
  public reset() {
    this.controls.forEach( control => control.reset() )
  }
}
