<div class="form-group row" [formGroup]="group">
  <label class="col-sm-3 col-form-label text-sm-right" [for]="config.name">{{ config.label }}</label>
  <div class="col-sm-9">
    <div class="d-flex align-items-center">
      <!--<app-better-toggle [(value)]="toggleVal" [id]="config.toggleName" (toggled)="toggle($event)"></app-better-toggle>-->
      <input class="form-control ml-1 flex-grow-1" type="text" [id]="config.textName" [name]="config.textName" [formControlName]="config.textName" [placeholder]="config.placeholder"/>
    </div>
    <div>
      <small id="{{config.textName}}HelpBlock" class="form-text text-danger" [class.invisible]="group.get(config.textName).status !== 'INVALID'">Please enter some data.</small>
    </div>
  </div>
</div>