import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormControl, FormArray } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Store } from '@ngxs/store'

import { GwResource } from '@app/models/gateway-api'
import { UpdateGwRecord, GwRecordSet } from '@app/ngxs/gateway-api'
import { ApiManifestRecordModel } from '@app/models/gateway-api'

@Component({
  selector: 'app-manifest-page',
  templateUrl: './manifest-page.component.html',
  styleUrls: ['./manifest-page.component.css']
})
export class ManifestPageComponent implements OnInit, OnDestroy {
  
  saving: boolean = false
  page: string
  
  stateModel$: Observable<GwRecordSet>
  
  subs: Subscription[] = []
  
  manifest: any
  apiManifestRecord: any // ApiManifestRecordModel
  
  oldManifestRecord: any // ApiManifestRecordModel
  
  tableFormGroupValues: any = {}
  
  tableFormGroup: FormGroup
  
  validationCollapse: any[] = []

  constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {
  }
  
  ngOnInit() {
    // console.log('manifest page init')
    this.page = this.route.snapshot.paramMap.get('page') as GwResource
    this.stateModel$ = this.store.select( s => s['gwState'].index['api-manifest'])
                        .pipe(
                          filter( s => s && !s.loading )
                        )
    
    this.subs.push(
      this.stateModel$.subscribe( (state) => {
        console.log('state model subscription called')
        // console.log(JSON.stringify(state))
        
        if (!state) {
          console.log('state not found')
          return
        }
        
        this.apiManifestRecord = state.records.find(r => r.id == 1) as ApiManifestRecordModel // FIXME
        this.oldManifestRecord = this.apiManifestRecord
        
        if (this.apiManifestRecord) {
          console.log('found the manifest')
          this.manifest = this.apiManifestRecord.manifest || this.apiManifestRecord["manifest{}"]
          
          console.log('manifest is now', this.manifest)
         
          const table = this.manifest[this.page]
          
          if (!table) {
            console.error(`Could not find page ${this.page}.`)
            return
          }
          
          table.columns.forEach( (col, i) => this.validationCollapse[i] = { 'create': true, 'update': true } )
          
          this.tableFormGroup = new FormGroup({
            tableName:       new FormControl( this.page                 ),
            tableAlias:      new FormControl( table['tableAlias'] || this.page),
            keyFields:       new FormControl( table['keyFields']       ),
            softDeleteField: new FormControl( table['softDeleteField'] ),
            softDeleteOn:    new FormControl( table['softDeleteOn']    ),
            softDeleteOff:   new FormControl( table['softDeleteOff']   ),
            className:       new FormControl( table['className']       ),
            uiLabel:         new FormControl( table['uiLabel']         ),
            apiEndpoint:     new FormControl( table['apiEndpoint']     ),
            notes:           new FormControl( table['notes']           ),
            columns:         new FormArray(
              table.columns.map( col => {
                return new FormGroup({
                  fieldName:  new FormControl( col['fieldName'] ),
                  columnDef:  new FormControl( col['columnDef'] ),
                  jsType:     new FormControl( col['jsType'] ),
                  oaType:     new FormControl( col['oaType'] ),
                  oaFormat:   new FormControl( col['oaFormat'] ),
                  apiName:    new FormControl( col['apiName'] ),
                  formLabel:  new FormControl( col['formLabel'] ),
                  formControl: new FormControl( col['formControl'] || ''),
                  validators:  new FormGroup({
                    create: new FormControl( col['validators']['create'] || [] ),
                    update: new FormControl( col['validators']['update'] || [] ),
                  }),
                  nullable:   new FormControl({ value: col['nullable'], disabled: true }),
                  optional:   new FormControl({ value: col['optional'], disabled: true }),
                  foreignKey: new FormGroup({
                    table:        new FormControl( col['foreignKey']['table'] ),
                    field:        new FormControl( col['foreignKey']['field'] ),
                    relationship: new FormControl( col['foreignKey']['relationship'] )
                  }),
                  helpText: new FormControl( col['helpText'] ),
                  notes: new FormControl( col['notes'] )
                })
              })
            )
          })
          
        }
      }),
      
    )
  }
  
  emptyArray(index, controlName) {
    console.log(index, controlName)
    console.log( (((this.tableFormGroup.controls['columns'] as FormArray).controls[index] as FormGroup).controls['validators'] as FormGroup).controls[controlName].value) // .columns[index].controls[controlName].value
    setTimeout( () => (((this.tableFormGroup.controls['columns'] as FormArray).controls[index] as FormGroup).controls['validators'] as FormGroup).controls[controlName].patchValue([]), 250)
  }
  
  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe()
    }
    
    this.subs = []
  }
  
  get columns(): FormArray {
    return this.tableFormGroup.get('columns') as FormArray
  }
  
  sampleOutput(): void {
    this.tableFormGroupValues = this.tableFormGroup.getRawValue() //this.tableFormGroup.value
  }
  
  save(): void {
    this.saving = true
    
    const newRecord = {
    // this.apiManifestRecord = {
      id: this.apiManifestRecord.id,
      name: this.apiManifestRecord.name,
      "manifest": {
        ...this.apiManifestRecord.manifest,
        [this.tableFormGroup.controls['tableName'].value]: this.tableFormGroup.getRawValue() //this.tableFormGroup.value
      }
    }
    
    const previousRecord = {
      id: this.oldManifestRecord.id,
      name: this.oldManifestRecord.name,
      "manifest": this.oldManifestRecord.manifest,
    }
    
    this.store.dispatch( new UpdateGwRecord('api-manifest', newRecord, previousRecord) )
      .toPromise()
      .finally( () => this.saving = false )
  }
}



