import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-office-form',
  templateUrl: './office-form.component.html',
  styleUrls: ['./office-form.component.scss']
})
export class OfficeFormComponent {
  @ViewChildren('idControl, merchantIdControl, branchIdControl, nameControl, dbaControl, address1Control, address2Control, cityControl, stateControl, postalCodeControl, phoneLocalControl, phoneTollFreeControl, phoneFaxControl, hoursOfOperationControl, webUrlControl, emailControl, ipLockControl, ftpFolderControl, uploadFolderControl, stageFolderControl, allowBatchUploadControl, enforceCtControl, requireCvvControl, requireAddressControl, requireStateControl, requireZipCodeControl, webPmtNotifyControl, webPmtEmailControl, planPmtNotifyControl, planPmtEmailControl, amountBaseControl, amountConvFeeControl, amountLateFeeControl, amountNsfFeeControl, amountShippingControl, amountInterestControl, amountTaxControl, amountDiscountControl, amountCalcGross, statusIdControl')
  controls: QueryList<any>
  
  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }

  public reset() {
    this.controls.forEach( control => control.reset() )
  }
}