<div class="row d-flex justify-content-center">
  <div class="col-12 flex-fill px-1">

    <div class="card border-dark">
      <h3 class="card-header text-capitalize text-light font-weight-bold" style="text-shadow: 0px 3px 6px black;">
        {{ title }} <h5 *ngIf="subtitle" class="d-inline">{{ subtitle }}</h5>
      </h3>
      
      <form class="dynamic-form" [formGroup]="formGroup" autocomplete="off">
      
        <div class="card-body pb-0 px-2">
          <div class="card-text">
            <app-gw-form-container #formContainer [resourceAlias]="resourceAlias" [formGroup]="formGroup" [fields]="fields" [roles]="roles"></app-gw-form-container>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <app-buttons-array *ngIf="!noButtons" [fields]="buttonFields['buttons']" [formGroup]="formGroup"></app-buttons-array>
        </div>
        
      </form>
    </div>
    
  </div>
</div>

<ng-template #disableModal let-c="close" let-d="dismiss">

    <div class="modal-header">
      <h4 class="modal-title">Disable {{ resourceAlias }}?</h4>
      <button type="button" class="close" aria-label="Close Disable Confirmation Modal" (click)="d()">
        <span aria-hidden="true"><i class="fas fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p>Are you sure?</p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="button" (click)="d(deleteRecord(formContainer.readFormVals()))"><i class="fas fa-check pr-2"></i>Yes</button>
      <button class="btn btn-secondary" type="button" (click)="d()"><i class="fas fa-times pr-2"></i>No</button>
    </div>
  
</ng-template>