<div class="card mt-2">
  <ul class="list-group list-group-flush">
    <div class="list-group-item list-group-item-warning">WARNING: SUPERUSER</div>

    <!--<a routerLink="/manifest" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <a routerLink="/manifest" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-cogs pr-2"></i>
      Configure Manifest
    </a>
    
    <!--<a routerLink="/select/superuser" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <a routerLink="/select/superuser" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-user-ninja pr-2"></i>
      Manage Superusers
    </a>
    
    <!--<a routerLink="/select/affiliate" closeSidebar class="list-group-item list-group-item-dark list-group-item-action">-->
    <a routerLink="/select/affiliate" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-shopping-cart pr-2"></i>
      Select Affiliate
    </a>
    
    <a routerLink="/crm/reports/daily-job-log" class="list-group-item list-group-item-dark list-group-item-action">
      <i class="fas fa-calendar-alt pr-2"></i>
      Daily Job Log
    </a>
    
  </ul>
  
</div>

<ng-container *ngIf="session.selected['affiliates']">
  <app-affiliate-menu [session]="session"></app-affiliate-menu>  
</ng-container>