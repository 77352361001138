import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-superuser-config-menu',
  templateUrl: './superuser-config-menu.component.html',
  styleUrls: ['./superuser-config-menu.component.scss']
})
export class SuperuserConfigMenuComponent implements OnInit {
  
  @Output()
  bsRefModal = new EventEmitter()
  
  @Output()
  loadingAnimation = new EventEmitter()
  
  @Output()
  idleModal = new EventEmitter()

  constructor(
  ) { }

  ngOnInit() {
  }
  
  openBsRefModal() {
    this.bsRefModal.emit()
  }
  
  showLoadingAnimation() {
    this.loadingAnimation.emit()
  }
  
  openIdleModal() {
    this.idleModal.emit()
  }
  
}
