import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'

import { AuthGuardService } from '@app/services'

import { OverviewComponent } from '@app/page/overview/overview.component'

// import { SelectResourceComponent } from '@app/page/select-resource/select-resource.component'
import { ManageResourceComponent } from '@app/page/manage-resource/manage-resource.component'
import { ProfileComponent } from '@app/page/account/profile/profile.component'

import { OfficesComponent }     from '@app/page/offices/offices.component'
import { PaymentsComponent }    from '@app/page/payments/payments.component'
import { MidGroupsComponent }   from '@app/page/mid-groups/mid-groups.component'
import { RoutesComponent }      from '@app/page/routes/routes.component'
import { MidsComponent }        from '@app/page/mids/mids.component'

import { AppErrorComponent } from '@app/page/app-error/app-error.component'
import { NotFoundComponent } from '@app/page/not-found/not-found.component'

import {
  ManifestComponent,
  ManifestPageComponent,
} from '@app/page/manifest'

const routes: Routes = [
  { path: '', component: OverviewComponent, data: {animation: 'overview'} },
  
  // { path: 'select/:resource', component: SelectResourceComponent },
  { path: 'select/:resource', loadChildren: () => import('@app/ng-modules/secondary/secondary.module').then(m => m.SecondaryModule), data: {animation: 'select resource'} },
  { path: 'manage/:resource/:id', component: ManageResourceComponent, data: {animation: 'manage resource'} },
  
  // { path: 'merchants', loadChildren: './merchants/merchants.module#MerchantsModule' },
  { path: 'offices',    component: OfficesComponent },
  { path: 'payments',   component: PaymentsComponent },
  { path: 'mid-groups', component: MidGroupsComponent },
  { path: 'routes',     component: RoutesComponent },
  { path: 'mids',       component: MidsComponent },
  
  { path: 'manifest',       component: ManifestComponent,     data: {animation: 'manifest'} },
  { path: 'manifest/:page', component: ManifestPageComponent, data: {animation: 'manifest page'} },
  
  { path: 'account/profile', component: ProfileComponent, data: {animation: 'account profile'} },
  
  { path: 'crm', loadChildren: () => import('@app/ng-modules/crm/crm.module').then(m => m.CrmModule), data: {animation: 'crm section'}, canLoad: [AuthGuardService] },
  { path: 'merchant', loadChildren: () => import('@app/ng-modules/merchants/merchants.module').then(m => m.MerchantsModule), data: {animation: 'merchant section'}, canLoad: [AuthGuardService] },

  { path: 'app-error', component: AppErrorComponent },
  { path: '**', component: NotFoundComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules,
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled',
      })],
  exports: [RouterModule]
})
export class AppRoutingModule { }