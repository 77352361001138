import { State, Action, StateContext, Selector } from '@ngxs/store'
import { patch } from '@ngxs/store/operators';

import { GatewayApiService } from '@services/gateway-api'

export class PingHeartbeat {
  static readonly type = '[Heartbeat] Ping Heartbeat Lambda'
  
  constructor(public param?: any) {}
}

export class HeartbeatStateModel {
}

@State<HeartbeatStateModel>({
  name: 'heartbeat',
  defaults: {
  },
})
export class HeartbeatState {
  
  constructor(
  	private gwapi: GatewayApiService,
  ) {}
  
  @Action(PingHeartbeat)
  pingHeartbeat({ getState, setState }: StateContext<HeartbeatStateModel>, {param}: PingHeartbeat) {
		if (param) {
			this.gwapi.heartbeat(param)
		} else {
			this.gwapi.heartbeat()
		}	
  }
}