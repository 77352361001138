import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'

import { DynamicFormComponent } from './dynamic-form.component'
import { DynamicFieldDirective } from './dynamic-field.directive'
import { TextComponent } from './text/text.component'
import { SelectComponent } from './select/select.component'
import { ButtonComponent } from './button/button.component'
import { ToggleComponent } from './toggle/toggle.component'
import { ToggleSectionComponent } from './toggle-section/toggle-section.component'
import { BetterToggleComponent } from '../better-toggle/better-toggle.component'
import { ToggleTextComponent } from './toggle-text/toggle-text.component'
import { PasswordComponent } from './password/password.component'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    BetterToggleComponent,
    DynamicFormComponent,
    DynamicFieldDirective,
    TextComponent,
    SelectComponent,
    ButtonComponent,
    ToggleComponent,
    ToggleSectionComponent,
    ToggleTextComponent,
    PasswordComponent
  ],
  exports: [DynamicFormComponent, BetterToggleComponent],
  entryComponents: [
    TextComponent,
    SelectComponent,
    ButtonComponent,
    ToggleComponent,
    ToggleTextComponent,
    PasswordComponent
  ]
})
export class DynamicFormModule {}