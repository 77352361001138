import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent {
  @Input() config: any;
  group: FormGroup;
  toggleVal: boolean;

  constructor() { }

  ngOnInit() {
    this.toggleVal = this.config.value;
    this.group.addControl(this.config.name, new FormControl(this.toggleVal || this.config.default, this.config.validators))
  }
  
  toggle(event: boolean): void {
    this.group.patchValue({[this.config.name]: event});
  }

  public reset(): void {
    this.toggleVal = this.config.value;
    this.group.patchValue({[this.config.name]: this.toggleVal});
  }

}
