import { Component, Input, OnChanges } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

@Component({
  selector: 'app-s-text',
  templateUrl: './s-text.component.html',
  styleUrls: ['./s-text.component.css']
})
export class STextComponent implements OnChanges {
  
  @Input()
  config: any

  @Input()
  group: FormGroup
  
  editing: boolean = false
  readOnly: boolean
  
  controlRef: FormControl

  constructor() { }
  
  ngOnChanges() {
    if (this.controlRef) {
      this.controlRef.patchValue( this.config.value )
      this.controlRef.setValidators( this.config.validators )
    } else if (this.config) {
      this.group.addControl(this.config.name, this.controlRef = new FormControl(this.config.value, this.config.validators))
      
      this.readOnly = this.config.options && this.config.options.readOnly
    }
  }
  
  doReset() {
    this.controlRef.reset({ value: this.config.value, disabled: this.config.options && this.config.options.readOnly })
    this.editing = false
  }
  
  editable(val: boolean) {
    this.editing = val && !this.readOnly
  }
  
  public reset() {
    this.controlRef.reset({ value: this.config.value || this.config.default, disabled: this.config.options && this.config.options.readOnly })
    this.editing = false
  }
}