import { Component, Input, OnChanges, OnDestroy } from '@angular/core'
import { FormGroup, FormControl, AbstractControl } from '@angular/forms'

import { Subscription } from 'rxjs'

@Component({
  selector: 'app-s-toggle-section',
  templateUrl: './s-toggle-section.component.html',
  styleUrls: ['./s-toggle-section.component.css']
})
export class SToggleSectionComponent { // implements OnChanges {
  @Input()
  config
  
  @Input()
  formGroup: FormGroup
  
  controlRef: FormControl
  
  toggleVal: boolean = false
  subs: Subscription[] = []
  
  previousValue: any = {}

  constructor() { }

  public reset(): void {
    this.toggleVal = this.config.value
    this.formGroup.patchValue({[this.config.name]: this.toggleVal})
  }
  
  ngOnChanges() {
    if (this.controlRef) {
      this.toggleVal = this.config.value
    } else if (this.config) {
      this.formGroup.addControl(this.config.name, this.controlRef = new FormControl(this.toggleVal || this.config.default, this.config.validators))    
      
      this.controlRef.valueChanges.subscribe( (toggleVal) => {

        for (const dependentControl of this.config.options.dependentControls) {
          const control = this.formGroup.get(dependentControl) as AbstractControl
          
          if (control) {
            if (toggleVal) {
              this.previousValue[dependentControl] = control.value
              control.reset({ value: false, disabled: true})
            } else {
              control.reset({ value: this.previousValue[dependentControl], disabled: false})
            }
          }

        }
        
      })
    }
  }
  
  ngOnDestroy() {
    for (const sub of this.subs)
      sub.unsubscribe()
      
    this.subs = []
  }

}
