<ng-template [ngIf]="_show">
  <div class="d-flex justify-content-center">
    <h2>Set A New Password</h2>
  </div>

  <div class="d-flex justify-content-center">
    <button type="button" (click)="onSignIn()" class="btn">Back to Sign In</button>
  </div>
      
  <div class="input-group mb-2">
    <div class="input-group-prepend w-33">
      <label class="input-group-text w-100">Password</label>
    </div>
    <input #password (keyup)="setPassword(password.value)" (keyup.enter)="onSubmit()" class="form-control" type="password" placeholder="Password"/>
  </div>
          
  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-secondary" (click)="onSubmit()">Submit</button>
  </div>
      
  <div *ngIf="errorMessage">{{ errorMessage }}</div>
    
</ng-template>