<app-s-hidden #idControl [config]="fields['id']" [group]="formGroup"></app-s-hidden>
<app-s-select #midIdControl [config]="fields['mid_id']" [group]="formGroup"></app-s-select>
<app-s-select #merchantIdControl [config]="fields['merchant_id']" [group]="formGroup"></app-s-select>
<app-s-select #officeIdControl [config]="fields['branch_id']" [group]="formGroup"></app-s-select>
<app-s-select #paymentTypeIdControl [config]="fields['pmt_type_id']" [group]="formGroup"></app-s-select>
<app-s-text #transactionEnvironmentControl [config]="fields['tran_env']" [group]="formGroup"></app-s-text>
<app-s-text #externalMerchantClientControl [config]="fields['ext_merchant_client']" [group]="formGroup"></app-s-text>
<app-s-select #processorIdControl [config]="fields['processor_id']" [group]="formGroup"></app-s-select>
<app-s-text #processorFileCodeControl [config]="fields['processor_file_code']" [group]="formGroup"></app-s-text>
<app-s-text #processorMidControl [config]="fields['processor_mid']" [group]="formGroup"></app-s-text>
<app-s-password #processorPasswordControl [config]="fields['processor_password']" [group]="formGroup"></app-s-password>