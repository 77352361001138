import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { STextComponent }          from './text/s-text.component'
import { SHiddenComponent }        from './hidden/s-hidden.component'
import { SSelectComponent }        from './select/s-select.component'
import { SButtonComponent }        from './button/s-button.component'
import { SToggleComponent }        from './toggle/s-toggle.component'
import { SToggleSectionComponent } from './toggle-section/s-toggle-section.component'
import { SToggleTextComponent }    from './toggle-text/s-toggle-text.component'
import { SPasswordComponent }      from './password/s-password.component'
import { ErrorMessagesComponent } from './error-messages/error-messages.component'
import { ButtonsArrayComponent } from './buttons-array/buttons-array.component';
import { PhoneArrayComponent } from './phone-array/phone-array.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  declarations: [
    STextComponent,
    SHiddenComponent,
    SSelectComponent,
    SButtonComponent,
    SToggleComponent,
    SToggleSectionComponent,
    SToggleTextComponent,
    SPasswordComponent,
    ErrorMessagesComponent,
    ButtonsArrayComponent,
    PhoneArrayComponent,
  ],
  exports: [
    STextComponent,
    SHiddenComponent,
    SSelectComponent,
    SButtonComponent,
    SToggleComponent,
    SToggleSectionComponent,
    SToggleTextComponent,
    SPasswordComponent,
    ErrorMessagesComponent,
    ButtonsArrayComponent,
    PhoneArrayComponent,
  ],
  entryComponents: [
  ]
})
export class StandardFormModule {}