import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-mid-group-form',
  templateUrl: './mid-group-form.component.html',
  styleUrls: ['./mid-group-form.component.scss']
})
export class MidGroupFormComponent {
  @ViewChildren('idControl, merchantIdControl, nameControl, bankCardMidControl, achArcMidControl, achCbrMidControl, achCcdMidControl, achCieMidControl, achCorMidControl, achCtxMidControl, achMteMidControl, achPbrMidControl, achPopMidControl, achPosMidControl, achPpdMidControl, achRckMidControl, achTelMidControl, achWebMidControl, statusIdControl')
  controls: QueryList<any>
  
  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }

  public reset() {
    this.controls.forEach( control => control.reset() )
  }
}