<div *ngIf="show">
  <div class="overlay" [ngStyle]="{'background-color': spinner.bdColor}">
      <div [class]="spinner.class" [style.color]="spinner.color">
          <div *ngFor="let index of spinner.divArray"></div>
      </div>
    </div>
  <div class="loading-text">
    <ng-content></ng-content>
  </div>
</div>
