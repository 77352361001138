import * as tslib_1 from "tslib";
import { State, Action } from '@ngxs/store';
import { GatewayApiService } from '@services/gateway-api';
export class PingHeartbeat {
    constructor(param) {
        this.param = param;
    }
}
PingHeartbeat.type = '[Heartbeat] Ping Heartbeat Lambda';
export class HeartbeatStateModel {
}
let HeartbeatState = class HeartbeatState {
    constructor(gwapi) {
        this.gwapi = gwapi;
    }
    pingHeartbeat({ getState, setState }, { param }) {
        if (param) {
            this.gwapi.heartbeat(param);
        }
        else {
            this.gwapi.heartbeat();
        }
    }
};
tslib_1.__decorate([
    Action(PingHeartbeat),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, PingHeartbeat]),
    tslib_1.__metadata("design:returntype", void 0)
], HeartbeatState.prototype, "pingHeartbeat", null);
HeartbeatState = tslib_1.__decorate([
    State({
        name: 'heartbeat',
        defaults: {},
    }),
    tslib_1.__metadata("design:paramtypes", [GatewayApiService])
], HeartbeatState);
export { HeartbeatState };
