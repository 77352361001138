import { Component, Input, OnChanges } from '@angular/core'
import { FormGroup, FormControl, FormArray } from '@angular/forms'

import { GwFormContainerComponent } from '@app/ui/templates/gw-resource/gw-form-container/gw-form-container.component'

@Component({
  selector: 'app-phone-array',
  templateUrl: './phone-array.component.html',
  styleUrls: ['./phone-array.component.scss']
})
export class PhoneArrayComponent implements OnChanges {
  @Input()
  config: any
  
  @Input()
  group: FormGroup
  
  @Input()
  formContainer: GwFormContainerComponent
  
  formArr: FormArray;
  
  controlRef: FormControl
  
  constructor() {}

  ngOnChanges() {
    if (this.formArr) {
      
    } else if (this.config) {
      this.formArr = new FormArray([])
      
      if (this.config.value === null && typeof this.config.value === 'object') {
        this.config.value = []
      }
      
      this.config.value.forEach( i => {
        this.formArr.push(
          new FormGroup({
            line: new FormControl(i.line),
            ext: new FormControl(i.ext),
            type: new FormControl(i.type)
          })
        )
      })
      
      // console.log(this.formArr)
      this.group.addControl(this.config.name, this.formArr);
    }
  }
  
  doReset(index: number): void {
    this.formArr.controls[index].reset()
  }
  
  public reset(): void {
    this.formArr.clear()
      
    this.config.value.forEach( i => {
      this.formArr.push(new FormGroup({
        line: new FormControl(i.line),
        ext:  new FormControl(i.ext),
        type: new FormControl(i.type)
      }))
    })
  }
  
  increaseItems() {
    this.formArr.push(
      new FormGroup({
        line: new FormControl(''),
        ext:  new FormControl(''),
        type: new FormControl(''),
      })
    )
  }
  
  removeItem(index: number) {
    this.formArr.removeAt(index)
  }
}
