<div class="modal-header bg-info">
  <h4 class="modal-title text-capitalize text-light"><i class="fas fa-info-circle text-light mr-3"></i>About This Application</h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close About Modal" (click)="activeModal.dismiss()">
    <span aria-hidden="true"><i class="fas fa-times"></i></span>
  </button>
</div>

<div class="modal-body pb-0">
  
  <dl>
    <dt>
      Version
    </dt>
    <dd>
      <a href="assets/CHANGELOG.md" target="_blank" title="view application changelog">{{ version }}</a>
    </dd>
    
    <dt>
      3rd Party Licenses
    </dt>
    <dd>
      <a href="/3rdpartylicenses.txt" target="_blank" title="3rd party licenses">View</a>      
    </dd>
  </dl>
  
  <small>&copy; 2010-2019 Coalesce Holdings, LLC or its subsidiaries. All rights reserved.</small>
  
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()">Continue</button>
</div>