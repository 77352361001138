import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { environment } from '../../environments/environment'

import { Store } from '@ngxs/store'

import { CreateAppErrorRecord } from '@app/ngxs/gateway-api/app-error.ngxs'
import { NgxsError } from '@app/classes/error/ngxs-error'

@Injectable({
  providedIn: 'root'
})
export class AppErrorReportingService implements ErrorHandler {

  constructor(
    private injector: Injector
  ) {}

  handleError(error) {
    const store = this.injector.get(Store)
    
    // Errors show up in the console only during development.
    // if (environment.production) {
    //   console.error(`App Reporting Error: ${error}`)
    console.log(error.message || error)
    
    
    if (environment.production && !(error instanceof NgxsError)) {
      
      const sessionSnapshot = store.selectSnapshot(s => s['session'])
    
      // Sends error to logging table
      store.dispatch(
        new CreateAppErrorRecord(
          {
            user_id: {
              op: '=',
              val: sessionSnapshot.currentUser ? sessionSnapshot.currentUser.id : 0,
            },
            merchant_id: {
              op: '=',
              val: sessionSnapshot.currentUser ? sessionSnapshot.currentUser.merchant_id || 0 : 0,
            },
            affiliate_id: {
              op: '=',
              val: sessionSnapshot.currentAffiliate ? sessionSnapshot.currentUser.affiliate_id || 0 : 0,
            },
            message: {
              op: '=',
              val: error.message,
            },
          }
        )
      )
    }
    
    // Does not rethrow errors if it shouldn't progress further.
    // console.log(`Error is of type ${error.constructor.name}.`)
    
    if ( !(error instanceof NgxsError) )
      throw error
  }
}