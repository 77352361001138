import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { Store } from '@ngxs/store'

import { ProjectorService } from '@app/services/gateway-api'
import { GwResource } from '@app/models/gateway-api/'
import { GwRecordSet } from '@app/ngxs/gateway-api'
import { SetSelectedResource } from '@app/ngxs/session.ngxs'
import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'

@Component({
  selector: 'app-manage-resource',
  templateUrl: './manage-resource.component.html',
  styleUrls: ['./manage-resource.component.css']
})
export class ManageResourceComponent implements OnInit {
  
  record$: Observable<{ loading: boolean, data: IApiResourceRecordModel }>
  
  state: GwRecordSet
  
  id: number = null
  alias: string
  resource: GwResource
  
  subs: Subscription[] = []

  constructor(
    private route: ActivatedRoute,
    private projector: ProjectorService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe( () => {
        this.alias = this.route.snapshot.paramMap.get('resource')
        this.resource = this.projector.tableAlias[this.alias]
        this.id   = +this.route.snapshot.paramMap.get('id')
        
        // console.log('managing', this.alias, this.resource, this.id)
        
        this.record$ = this.store.select(s => s['gwState'].index[this.resource])
          .pipe(
            filter( r => r && !r.loading && r.records ),
            map( r => ({ loading: r.loading, data: r.records.find( r => r.id === this.id) }) )
          )
        
        // console.log(`selecting ${this.resource} #${this.id}`)
        this.store.dispatch(new SetSelectedResource(this.resource, this.id))
      })
    )
  }
  
  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe()
    }
    
    this.subs = []
  }

}
