import * as tslib_1 from "tslib";
import { State, Action, Selector } from '@ngxs/store';
export class UpdateKeepalive {
    constructor(timestamp) {
        this.timestamp = timestamp;
    }
}
UpdateKeepalive.type = '[Keepalive] Update';
export class ClearKeepalive {
    constructor() { }
}
ClearKeepalive.type = '[Keepalive] Clear';
// currentTimestamp (timestamp from keepalive service - the time its function was called)
// previousTimestamp (previous value of currentTimestamp, if any)
// expired (unused)
// currentTheNow (timestamp from inside ngxs, which may be significantly different than from the keepalive service)
export class KeepaliveStateModel {
}
let KeepaliveState = class KeepaliveState {
    constructor() { }
    static getKeepalive(state) {
        return state;
    }
    clearKeepalive({ setState }, event) {
        setState({
            previousTimestamp: null,
            expired: false,
            currentTheNow: null,
            currentTimestamp: null,
        });
    }
    updateKeepalive({ getState, setState }, { timestamp }) {
        const state = getState();
        const theNow = Date.now();
        if (theNow - timestamp > 1000) {
            console.log('component time vs store time', theNow, timestamp, theNow - timestamp);
        }
        if (state.previousTimestamp
            &&
                timestamp - state.previousTimestamp > 900000) {
            console.log('15 minute idle detected');
            console.log('signout + auth clear');
        }
        setState({
            previousTimestamp: state.currentTimestamp,
            currentTimestamp: timestamp,
            currentTheNow: theNow,
            expired: false,
        });
    }
};
tslib_1.__decorate([
    Action(ClearKeepalive),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ClearKeepalive]),
    tslib_1.__metadata("design:returntype", void 0)
], KeepaliveState.prototype, "clearKeepalive", null);
tslib_1.__decorate([
    Action(UpdateKeepalive),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateKeepalive]),
    tslib_1.__metadata("design:returntype", void 0)
], KeepaliveState.prototype, "updateKeepalive", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [KeepaliveStateModel]),
    tslib_1.__metadata("design:returntype", void 0)
], KeepaliveState, "getKeepalive", null);
KeepaliveState = tslib_1.__decorate([
    State({
        name: 'keepalive',
        defaults: {
            previousTimestamp: null,
            expired: false,
            currentTimestamp: null,
            currentTheNow: null,
        },
    }),
    tslib_1.__metadata("design:paramtypes", [])
], KeepaliveState);
export { KeepaliveState };
