<ng-template #loader>

  <div class="ph-item">
    <div class="ph-col-12"></div>
  </div>

</ng-template>

<div *ngIf="config; else loader" class="form-group form-row mb-0" [formGroup]="group">
  <div class="col-sm-3 text-sm-right">
    <label class="col-form-label" [for]="config.name">Phones</label>
  </div>
  <div class="col-sm-9" [formArrayName]="config.name">
    <div *ngFor="let control of formArr.controls; let i = index" formGroupName="{{i}}">
      <div class="d-flex">
        <div class="input-group border rounded" [ngClass]="{'border-info': editing, 'border-primary': control.dirty && !editing, 'border-dark': !control.dirty && !editing}">
          <!--<input-->
          <!--  [ngClass]="(config?.options?.readOnly) ? 'form-control-plaintext' : 'form-control'"-->
          <!--  type="text"-->
          <!--  (focus)="editable(true)"-->
          <!--  (keyup.esc)="doReset()"-->
          <!--  (blur)="editable(false)"-->
          <!--  [id]="config.name"-->
          <!--  [name]="config.name"-->
          <!--  [formControlName]="config.name"-->
          <!--  [placeholder]="config.placeholder"-->
          <!--  [readonly]="config?.options?.readOnly">-->
          <!--{{ control.value.line }} {{ control.value.ext }} {{ control.value.type }} <br>-->
          <!--{{ config.placeholder }}-->
          <input class = "form-control col-sm-6" formControlName="line" type="text" placeholder="Line">
          <input class = "form-control col-sm-3" formControlName="ext" type="text" placeholder="Ext.">
          <select class="form-control col-sm-3" formControlName="type">
            <option value="">Select</option>
            <option value="Direct">Direct</option>
            <option value="Mobile">Mobile</option>
            <option value="General">General</option>
          </select>
          <div *ngIf="control.dirty" class="input-group-append">
            <button type="button" class="btn btn-dark input-group-text" (click)="doReset(i)"><i class="fas fa-times-circle"></i></button>
          </div>
          <button type="button" class="btn btn-outline-secondary btn-sm ml-2" (click)="removeItem(i)" aria-label="remove item"><i class="fas fa-trash-alt"></i></button>
        </div>
        <button
          [class.invisible]="i!==0"
          type="button"
          class="btn ml-1"
          placement="right"
          ngbPopover="{{ config.helpText }}">
          <i class="fas fa-question-circle"></i>
        </button>
      </div>
      <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="control.status !== 'INVALID'">
        <app-error-messages *ngIf="control.errors" [errors]="control.errors"></app-error-messages>
        &nbsp;
      </small>
    </div>
    <div class="input-group rounded mb-4">
      <input class = "form-control col-sm-6" type="text" placeholder="Line" disabled>
      <input class = "form-control col-sm-3" type="text" placeholder="Ext." disabled>
      <select class="form-control col-sm-3" disabled>
        <option value="">Select</option>
        <option value="Direct">Direct</option>
        <option value="Mobile">Mobile</option>
        <option value="General">General</option>
      </select>
      <button type="button" class="btn btn-outline-secondary btn-sm ml-2" (click)="increaseItems()" arira-label="increase items"><i class="fas fa-plus"></i></button>
      <button
          [class.invisible] = "formArr.length !== 0"
          type="button"
          class="btn ml-1"
          placement="right"
          ngbPopover="{{ config.helpText }}">
          <i class="fas fa-question-circle"></i>
        </button>
    </div>
  </div>
</div>  