<ng-template #loader>
  
  <div class="ph-item">
    <div class="ph-col-12"></div>
  </div>
  
</ng-template>

<div *ngIf="config; else loader" class="form-group form-row mb-1" [formGroup]="group">
  <div class="col-sm-3 text-sm-right">
    <label class="col-form-label" [for]="config.name">{{ config.label }}</label>
  </div>
  <div class="col-sm-9">
    <div class="d-flex">
      <select
        class="border rounded"
        [ngClass]="{'border-info': editing, 'border-primary': controlRef.dirty && !editing, 'border-dark': !controlRef.dirty && !editing, 'form-control-plaintext': readOnly, 'form-control': !readOnly}"
        (focus)="editable(true)"
        (blur)="editable(false)"
        (keyup.esc)="reset()"
        [formControlName]="config.name"
        [name]="config.name"
        [id]="config.name">
        <option *ngIf="config.options.nullable" [ngValue]="null">NULL</option>
        <option *ngIf="!config.options.nullable" value="" disabled selected="selected">Choose!</option>
        <option *ngFor="let option of config.options.select | async" [ngValue]="option.value">{{option.display}}</option>
      </select>
      <button
        type="button"
        class="btn ml-1"
        placement="right"
        ngbPopover="{{ config.helpText }}">
        <i class="fas fa-question-circle"></i>
      </button>
    </div>
    <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="controlRef.status !== 'INVALID'">
      <app-error-messages *ngIf="controlRef.errors" [errors]="controlRef.errors"></app-error-messages>
      &nbsp;
    </small>
  </div>
</div>