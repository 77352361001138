<app-s-hidden #idControl [config]="fields['id']" [group]="formGroup"></app-s-hidden>
<app-s-select #merchantIdControl [config]="fields['merchant_id']" [group]="formGroup"></app-s-select>
<app-s-select #processorIdControl [config]="fields['processor_id']" [group]="formGroup"></app-s-select>
<app-s-text #merchantTrustAccountControl [config]="fields['merchant_trust_account']" [group]="formGroup"></app-s-text>
<app-s-text #merchantTrustLabelControl [config]="fields['merchant_trust_label']" [group]="formGroup"></app-s-text>
<app-s-text #merchantTrustNameControl [config]="fields['merchant_trust_name']" [group]="formGroup"></app-s-text>
<app-s-text #fileCodeControl [config]="fields['file_code']" [group]="formGroup"></app-s-text>
<app-s-text #fileCodeFullControl [config]="fields['file_code_full']" [group]="formGroup"></app-s-text>
<app-s-text #midControl [config]="fields['mid']" [group]="formGroup"></app-s-text>
<app-s-text #processorTidControl [config]="fields['processor_tid']" [group]="formGroup"></app-s-text>
<app-s-password #passwordControl [config]="fields['password']" [group]="formGroup"></app-s-password>
<app-s-password #ftpPasswordControl [config]="fields['ftp_password']" [group]="formGroup"></app-s-password>
<app-s-text #apiLoginIdControl [config]="fields['api_login_id']" [group]="formGroup"></app-s-text>
<app-s-text #secureTransactionKeyControl [config]="fields['secure_transaction_key']" [group]="formGroup"></app-s-text>
<app-s-text #aaMidControl [config]="fields['aa_mid']" [group]="formGroup"></app-s-text>
<app-s-text #amexMidControl [config]="fields['amex_mid']" [group]="formGroup"></app-s-text>
<app-s-select #affiliateIdControl [config]="fields['affiliate_id']" [group]="formGroup"></app-s-select>
<app-s-text #splitFreeTokenKeyControl [config]="fields['split_free_token_key']" [group]="formGroup"></app-s-text>
<app-s-select #statusIdControl [config]="fields['status_id']" [group]="formGroup"></app-s-select>