<div class="form-group form-row mb-0" [formGroup]="group">
  <label class="col-sm-3 col-form-label text-sm-right" [for]="config.name">{{ config.label }}</label>
  <div class="col-sm-9">
    <div class="input-group" [ngClass]="{'border border-primary rounded': controlRef.dirty && !editing}" (mouseenter)="hoverOn()" (mouseleave)="hoverOff()">
      <input
        (click)="makeEditable()"
        [ngClass]="(readOnly || !editing) ? 'form-control-plaintext' : 'form-control'"
        type="text"
        [id]="config.name"
        [name]="config.name"
        [formControlName]="config.name"
        [placeholder]="config.placeholder"
        [readonly]="readOnly || !editing">
      <div class="input-group-append">
        <button *ngIf="hovering" class="btn" type="button" (click)="makeEditable()"><i class="fas fa-edit"></i></button>
        <ng-container *ngIf="editing">
          <button class="btn btn-dark" type="button" (click)="markSave()"><i class="fas fa-check-circle text-primary"></i></button>
          <button class="btn btn-dark" type="button" (click)="markReset()"><i class="fas fa-times-circle text-secondary"></i></button>
        </ng-container>
      </div>
    </div>
    <small id="{{config.name}}HelpBlock" class="form-text text-danger" [class.invisible]="group.get(config.name).status !== 'INVALID'">Please enter some data.</small>
  </div>
</div>