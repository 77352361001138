import { Component, OnInit, OnDestroy }   from '@angular/core'
import { Router }                         from '@angular/router'
import { Store }                          from '@ngxs/store';

import { Observable, Subject }              from 'rxjs';
import { distinctUntilChanged, takeUntil }  from 'rxjs/operators';

import { SessionStateModel } from '@app/ngxs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit, OnDestroy {
  
  sessionStateModel$: Observable<SessionStateModel>
  destroyed$: Subject<boolean> = new Subject<boolean>()
  
  session: SessionStateModel = new SessionStateModel()
  
  constructor(
    private router: Router,
    private store: Store
  ) {
    this.sessionStateModel$ = this.store.select(state => state.session)
  }
  
  ngOnInit() {
    this.sessionStateModel$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe(state => {
        this.session = state
        if(state.roles.superuser) {
          this.router.navigate(['/crm'])
        }
        if(state.roles.merchant) {
          this.router.navigate(['/merchant'])
        }
      })
  }
  
  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
  
}
