import { Component, Input, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { GwFormContainerComponent } from '@app/ui/templates/gw-resource/gw-form-container/gw-form-container.component'

@Component({
  selector: 'app-buttons-array',
  templateUrl: './buttons-array.component.html',
  styleUrls: ['./buttons-array.component.scss']
})
export class ButtonsArrayComponent implements OnDestroy {
  @Input()
  formGroup: FormGroup
  
  @Input()
  fields: any
  
  @Input()
  formContainer: GwFormContainerComponent
  
  subs: Subscription[] = []
  processing: boolean = false

  constructor() { }

  handle(fn) {
    this.processing = true
    
    this.subs.push( fn(this.formGroup.getRawValue(), this.formContainer)
      .pipe(
        finalize( () => this.processing = false )
      )
      .subscribe()
    )
  }
  
  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe()
    }
    
    this.subs = []
  }

}
