import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss']
})
export class StatusFormComponent {
  @ViewChildren('idControl, fileControl, progressionControl, labelControl, descriptionControl')
  controls: QueryList<any>

  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }
  
  public reset() {
    this.controls.forEach( control => control.reset() )
  }
}
