import { State, Action, StateContext, Selector } from '@ngxs/store'

type ToastrType = 'success' | 'error' | 'info' | 'warning'

export class ToastSuccess {
  static readonly type = '[Toast] Success'
  
  constructor(public message: string, public title?: string, public options?: any, public tType: ToastrType = 'success') {}
}

export class ToastError {
  static readonly type = '[Toast] Error'
    
  constructor(public message: string, public title?: string, public options?: any, public tType: ToastrType = 'error') {}
}

export class ToastWarning {
  static readonly type = '[Toast] Warning'
  
  constructor(public message: string, public title?: string, public options?: any, public tType: ToastrType = 'warning') {}
}

export class ToastInfo {
  static readonly type = '[Toast] Info'
  
  constructor(public message: string, public title?: string, public options?: any, public tType: ToastrType = 'info') {}
}

export class ToastShow {
  static readonly type = '[Toast] Show'
  
  constructor(public message: string, public title?: string, public options?: any, public tType: ToastrType = 'success') {}
}

export class ToasterStateModel {
  message?: string
  title?: string
  options?: any
  tType?: ToastrType
}

@State<ToasterStateModel>({
  name: 'toaster',
  defaults: null,
})
export class ToasterState {
  
  constructor() {}
  
  @Action(ToastSuccess)
  success({ setState }: StateContext<ToasterStateModel>, { message, title, options, tType }: ToastSuccess) {
    setState({
      message,
      title,
      options,
      tType,
    })
  }
  
  @Action(ToastError)
  error({ setState }: StateContext<ToasterStateModel>, { message, title, options, tType }:  ToastError) {
    setState({
      message,
      title,
      options,
      tType,
    })
  }
  
  @Action(ToastWarning)
  warning({ setState }: StateContext<ToasterStateModel>, { message, title, options, tType }: ToastWarning) {
    setState({
      message,
      title,
      options,
      tType,
    })
  }
  
  @Action(ToastInfo)
  info({ setState }: StateContext<ToasterStateModel>, { message, title, options, tType }: ToastInfo) {
    setState({
      message,
      title,
      options,
      tType,
    })
  }
  
  @Action(ToastShow)
  show({ setState }: StateContext<ToasterStateModel>, { message, title, options, tType }: ToastShow) {
    // this.toaster.show(toast.message)
  }

}