import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'

@Component({
  selector: 'app-s-hidden',
  templateUrl: './s-hidden.component.html',
  styleUrls: ['./s-hidden.component.css']
})
export class SHiddenComponent { // implements OnChanges { 
  
  // _config: any
  @Input()
  config: any
  // set config(c) {
  //   if (c) {
  //     this._config = c
  //     this.group.addControl(this.config.name, this.controlRef = new FormControl(this.config.value || this.config.default, this.config.validators))
  //   }
  // }
  
  // get config() {
  //   return this._config
  // }
  
  @Input()
  group: FormGroup
  
  controlRef: FormControl

  constructor() { }

  ngOnChanges() {
    if (this.controlRef) {
      this.controlRef.patchValue( this.config.value )
      this.controlRef.setValidators( this.config.validators )
    } else if(this.config) {
      this.group.addControl(this.config.name, this.controlRef = new FormControl(this.config.value || this.config.default, this.config.validators))
    }
  }
  
  public reset() {
    this.controlRef.reset({ value: this.config.value || this.config.default, disabled: this.config.options && this.config.options.readOnly })
  }
  
}
