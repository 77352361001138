import { Component, OnDestroy } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators'

import { Store } from '@ngxs/store'

import { IApiResourceRecordModel } from '@app/interfaces/gateway-api'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnDestroy {
  
  destroyed$ = new Subject<boolean>()
  resource$: Observable<{ loading: boolean, data: IApiResourceRecordModel }>
  id: number = null

  constructor(
    private store: Store,
  ) {
    // console.log(this.store.selectSnapshot( state => state['session']) )
  
    this.store.select( state => state['session'] )
      .pipe(
        filter( session => session && session.currentUser ),
        takeUntil(this.destroyed$)
      )
      .subscribe( session => {
        this.resource$ = this.store.select(s => s['gwState'].index['users'] )
          .pipe(
            // tap( r => {
            //   console.log('r', r, '!r.loading', !r.loading, 'r.records', r.records)
            // }),
            filter( r => r && !r.loading && r.records ),
            takeUntil(this.destroyed$),
            map( r =>  {
              // console.log('session is', session)
              // console.log('delivering', ({ loading: r.loading, data: r.records.find(r => r.id === session.currentUser.id) }) )
              return ({ loading: r.loading, data: r.records.find(r => r.id === session.currentUser.id) })
            }),
          )
        })
  }
  
  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }

}
