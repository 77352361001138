<div class="jumbotron-fluid mt-3">
  <h1 class="display-4">
    <i class="fas fa-poo-storm mr-2"></i>
    Oops
  </h1>
  
  <p class="lead">
    Something has gone wrong.  Check out our <a href="http://status.coalescepayments.com/" rel="noopener">status page</a> to see if there's been a service interruption.
  </p>
  
  <div>
    <form>
      <button class="btn btn-primary" type="submit" (click)="signout()">Return to Login Page</button>
    </form>
  </div>
  
</div>