/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./superuser-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../affiliate-menu/affiliate-menu.component.ngfactory";
import * as i3 from "../affiliate-menu/affiliate-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./superuser-menu.component";
var styles_SuperuserMenuComponent = [i0.styles];
var RenderType_SuperuserMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuperuserMenuComponent, data: {} });
export { RenderType_SuperuserMenuComponent as RenderType_SuperuserMenuComponent };
function View_SuperuserMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-affiliate-menu", [], null, null, null, i2.View_AffiliateMenuComponent_0, i2.RenderType_AffiliateMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.AffiliateMenuComponent, [], { session: [0, "session"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.session; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SuperuserMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "card mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "list-group-item list-group-item-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WARNING: SUPERUSER"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-dark list-group-item-action"], ["routerLink", "/manifest"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-cogs pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Configure Manifest "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-dark list-group-item-action"], ["routerLink", "/select/superuser"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fas fa-user-ninja pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Manage Superusers "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-dark list-group-item-action"], ["routerLink", "/select/affiliate"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-shopping-cart pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select Affiliate "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-dark list-group-item-action"], ["routerLink", "/crm/reports/daily-job-log"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-calendar-alt pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Daily Job Log "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuperuserMenuComponent_1)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/manifest"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "/select/superuser"; _ck(_v, 9, 0, currVal_5); var currVal_8 = "/select/affiliate"; _ck(_v, 13, 0, currVal_8); var currVal_11 = "/crm/reports/daily-job-log"; _ck(_v, 17, 0, currVal_11); var currVal_12 = _co.session.selected["affiliates"]; _ck(_v, 21, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9).target; var currVal_4 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 13).target; var currVal_7 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 17).target; var currVal_10 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_9, currVal_10); }); }
export function View_SuperuserMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-superuser-menu", [], null, null, null, View_SuperuserMenuComponent_0, RenderType_SuperuserMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.SuperuserMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuperuserMenuComponentNgFactory = i1.ɵccf("app-superuser-menu", i6.SuperuserMenuComponent, View_SuperuserMenuComponent_Host_0, { session: "session" }, {}, []);
export { SuperuserMenuComponentNgFactory as SuperuserMenuComponentNgFactory };
