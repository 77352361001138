import * as tslib_1 from "tslib";
import { Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GatewayApiService } from '@app/services/gateway-api/api.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "./api.service";
export class ProjectorService {
    constructor(store, api) {
        this.store = store;
        this.api = api;
        this.cognito_username = /^[A-Za-z0-9\-_]+$/;
        this.Validhalla = {
            "required": Validators.required,
            "nonZero": function (a) {
                if (a) {
                    if (a.value === 0) {
                        return { nonZero: true };
                    }
                    else if (a.value == 0) {
                        return { nonZero: true };
                    }
                }
                return null;
            },
            "minlength-8": Validators.minLength(8),
            "maxlength-45": Validators.maxLength(45),
            "maxlength-99": Validators.maxLength(99),
            "lowercase-1": Validators.pattern(/[a-z]+/),
            "uppercase-1": Validators.pattern(/[A-Z]+/),
            "symbol-1": Validators.pattern(/[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]+/),
            "number-1": Validators.pattern(/[0-9]+/),
            "email": Validators.email,
            "unique (users/username)": this.unique('users', 'username'),
            "cognito-username": Validators.pattern(this.cognito_username),
        };
        this.dependentFields = {
            "mid-groups": {
                "bank_card_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_arc_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_cbr_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_ccd_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_cie_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_cor_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_ctx_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_mte_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_pbr_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_pop_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_pos_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_ppd_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_rck_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_tel_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
                "ach_web_mid": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r.client_id === record['merchant_id']) })),
            },
            "users": {
                "branch_id": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r['merchant_id'] === record['merchant_id']) }))
            },
            "superusers": {
                "branch_id": (record) => (state) => (Object.assign({}, state, { records: state.records.filter(r => r['merchant_id'] === record['merchant_id']) }))
            }
        };
        this.columnHeadings = {
            "superuser": [
                { value: 'ID', key: 'id' },
                { value: 'Username', key: 'username' },
                { value: 'Full Name', key: 'full_name' },
                { value: 'Email', key: 'email' }
            ],
            "user": [
                { value: 'ID', key: 'id' },
                { value: 'Username', key: 'username' },
                { value: 'Full Name', key: 'full_name' },
                { value: 'Email', key: 'email' }
            ],
            "merchant": [
                { value: 'ID', key: 'id' },
                { value: 'Name', key: 'name' }
            ],
            "credential": [
                { value: 'ID', key: 'id' },
                { value: 'Login', key: 'login' }
            ],
            "office": [
                { value: 'ID', key: 'id' },
                { value: 'Name', key: 'name' }
            ],
            "affiliate": [
                { value: 'ID', key: 'id' },
                { value: 'Name', key: 'name' }
            ],
            "agency": [
                { value: 'ID', key: 'id' },
                { value: 'Name', key: 'name' }
            ],
            // "sbps_srep": [
            //   { value: 'ID', key: 'SREP_SalesRepID' },
            //   { value: 'Name', key: 'SREP_SalesRepName' }],
            "status": [
                { value: 'ID', key: 'id' },
                { value: 'File', key: 'file' },
                { value: 'Label', key: 'label' }
            ],
            "mid": [
                { value: 'ID', key: 'id' },
                { value: 'MID', key: 'mid' }
            ],
            "mid-group": [
                { value: 'ID', key: 'id' },
                { value: 'Name', key: 'name' }
            ],
            "route": [
                { value: 'ID', key: 'id' },
                { value: 'Processor MID', key: 'processor_mid' }
            ],
            "payment-type": [
                { value: 'ID', key: 'id' },
                { value: 'Code', key: 'code' },
                { value: 'Description', key: 'description' },
            ],
            "processor": [
                { value: 'ID', key: 'id' },
                { value: 'Name', key: 'name' },
            ],
        };
        // Resource names -> api resources that underlie them
        this.tableAlias = {
            "superuser": "users",
            "user": "users",
            "merchant": "merchants",
            "credential": "credentials",
            "affiliate": "affiliates",
            "agency": "agencies",
            "status": "statuses",
            // "sbps_srep": "sbps_srep",
            "office": "offices",
            "route": "routes",
            "mid": "mids",
            "mid-group": "mid-groups",
            "payment-type": "payment-types",
            "processor": "processors",
        };
        /**
         * When a resource has a field that points to another table, this object determines
         * how to display that information.
         */
        this.fkSummary = {
            "sbps_clnt": (r) => ({ display: `${r.id} | ${r.name}`, value: r.id }),
            "sbps_cred": (r) => ({ display: `${r.id} | ${r.login}`, value: r.id }),
            "sbps_bran": (r) => ({ display: `${r.id} | ${r.name}`, value: r.id }),
            "sbps_magt": (r) => ({ display: `${r.id} | ${r.name}`, value: r.id }),
            "sbps_mmid": (r) => ({ display: `${r.id} | ${r.merchant_trust_label} | ${r.merchant_trust_name}`, value: r.id }),
            "sbps_agcy": (r) => ({ display: `${r.id} | ${r.name}`, value: r.id }),
            // "sbps_srep": (r) => ({display: `${r.SREP_SalesRepID} | ${r.SREP_SalesRepName}`,  value: r.SREP_SalesRepID}),
            "sbps_stat": (r) => ({ display: `${r.id} | ${r.label}`, value: r.id }),
            "sbps_ptyp": (r) => ({ display: `${r.id} | ${r.code}`, value: r.id }),
            "sbps_vndr": (r) => ({ display: `${r.id} | ${r.name}`, value: r.id }),
        };
        this.tableTranslate = {
            "sbps_clnt": "merchants",
            "sbps_cred": "credentials",
            "sbps_bran": "offices",
            "sbps_magt": "affiliates",
            "sbps_agcy": "agencies",
            // "sbps_srep": "sbps_srep",
            "sbps_stat": "statuses",
            "sbps_mgrp": "mid-groups",
            "sbps_mmid": "mids",
            "sbps_ptyp": "payment-types",
            "sbps_vndr": "processors",
        };
        this.tableFilter = {
            "superuser": (r) => r.is_admin,
            "user": (r) => !r.is_admin,
            "merchant": (r) => true,
            "credential": (r) => true,
            "affiliate": (r) => true,
            "agency": (r) => true,
            "status": (r) => true,
            // "sbps_srep": (r: any): boolean => true,
            "office": (r) => true,
            "route": (r) => true,
            "mid": (r) => true,
            "mid-group": (r) => true,
            "payment-type": (r) => true,
            "processor": (r) => true,
        };
        this.resourceDefaults = {
            "superuser": { is_admin: true, status_id: 2, merchant_id: 1, branch_id: 1 },
            "user": { status_id: 2, merchant_id: 1, branch_id: 1 },
            "merchant": { status_id: 2 },
            "credential": {},
            "affiliate": {},
            "agency": {},
            "status": {},
            // "sbps_srep": {},
            "office": {},
            "route": {},
            "mid": { status_id: 2 },
            "mid-group": { status_id: 1 },
            "payment-type": {},
            "processor": {},
        };
        this.dependentControls = {
            'users': [
                'allow_plan_terminate',
                'allow_plan',
                'allow_copy',
                'allow_retry',
                'allow_reverse',
                'allow_credit',
                'allow_void',
                'allow_tran',
                'allow_branch',
                'allow_client',
                'allow_user',
                'allow_sbps',
                'all_branch',
                'all_client',
                'is_admin',
                'ip_lock_exempt',
            ]
        };
    }
    projectGwResource(manifest, resource, record) {
        // console.log('projecting ', record)
        const formElements = [];
        const key = Object.keys(manifest).find(k => manifest[k].apiEndpoint === resource);
        if (!key) {
            console.log(manifest);
            throw new Error(`Could not find table ${resource} in manifest.`); // FIXME should redirect
            // return []
        }
        for (let column of manifest[key].columns) {
            // if (column === 'password') continue
            formElements.push({
                type: column.formControl,
                name: column.apiName,
                label: column.formLabel,
                placeholder: column.formLabel,
                value: record[column.apiName],
                "default": (column.nullable ? null : (column.jsType == 'number' ? 0 : (column.jsType == 'string' ? '' : (column.jsType == 'boolean' ? false : {})))),
                options: Object.assign({}, this.readOnly(manifest[key], column.fieldName), this.fkSelect(column, resource, this.dependentFields[resource][column.apiName](record[column.apiName])), { required: !column.optional, nullable: column.nullable }),
                validators: column.validators.map(v => this.Validhalla[v])
            });
        }
        return formElements;
    }
    /***
     * options can take { validations: 'create' | 'update' }
     */
    projectStaticGwResource(manifest, resource, record, options) {
        // console.log('projecting ', resource,  record)
        const formElements = {};
        const key = Object.keys(manifest).find(k => manifest[k].apiEndpoint === resource);
        if (!key) {
            console.log(manifest);
            throw new Error(`Could not find table ${resource} in manifest.`); // FIXME should redirect
            // return []
        }
        for (let column of manifest[key].columns) {
            // console.log('col is', column)
            // console.log('rec is', record)
            formElements[column.apiName] = {
                type: column.formControl,
                name: column.apiName,
                label: column.formLabel,
                placeholder: column.formLabel,
                value: (column.formControl === 'password') ? undefined : record[column.apiName],
                "default": (column.nullable ? null : (column.jsType == 'number' ? 0 : (column.jsType == 'string' ? '' : (column.jsType == 'boolean' ? false : {})))),
                options: Object.assign({}, this.readOnly(manifest[key], column.fieldName), this.fkSelect(column, resource, this.dependentFields[resource] && this.dependentFields[resource][column.apiName] && this.dependentFields[resource][column.apiName](record)), { dependentControls: this.dependentControls[resource], required: !column.optional, nullable: column.nullable }),
                helpText: column.helpText || 'Coming soon...',
                validators: column.validators[options.validations].map(v => this.Validhalla[v])
            };
        }
        return formElements;
    }
    readOnly(column, fieldName) {
        return {
            readOnly: (false
                || column.keyFields.includes(fieldName)
                || column.softDeleteField === fieldName)
                ? true
                : false
        };
    }
    fkSelect(column, resource, mapFn = (state) => (Object.assign({}, state))) {
        return column.foreignKey.table ?
            {
                select: this.store.select((s) => s['gwState'].index[this.tableTranslate[column.foreignKey.table] || resource])
                    .pipe(
                // filter( filterFn ),
                map(s => mapFn(s)), map((s) => s.records.map(this.fkSummary[column.foreignKey.table] || ((r) => ({ display: `${r.id}`, value: r.id })))))
            }
            : {};
    }
    unique(table, field) {
        return (a) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result;
            try {
                result = this.api.unique(table, field, a.value);
            }
            catch (error) {
                console.error(error.message);
                return { unique: 'API Error' }; // FIXME
            }
            return result.unique
                ? null
                : result;
        });
    }
}
ProjectorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectorService_Factory() { return new ProjectorService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.GatewayApiService)); }, token: ProjectorService, providedIn: "root" });
