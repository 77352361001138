import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import Auth from '@aws-amplify/auth'

Auth.configure({
  Auth: {
    mandatorySignIn: true,
    region: environment.region,
    userPoolId: environment.userPoolId, // 'us-east-1_FNR60Qp1n'
    userPoolWebClientId: environment.userPoolWebClientId, // '5o4ffuubs35hthp7hfm7jpi70j'
    authenticationFlowType: environment.authenticationFlowType, // 'USER_PASSWORD_AUTH' = Necessary for gateway-user-migration,
    cookieStorage: {
      domain: environment.domain, // '.steppay.com',
      path: '/',
      expires: 1,
      secure: true,
    }
    // storage: window.sessionStorage // Authentication information is deleted when the window closes; however, a new sign-in is required for every new tab.
  }
})

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))