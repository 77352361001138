/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./sign-in.component";
import * as i4 from "@ngxs/store";
var styles_SignInComponent = [];
var RenderType_SignInComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignInComponent, data: {} });
export { RenderType_SignInComponent as RenderType_SignInComponent };
function View_SignInComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "spinner-border spinner-border-sm"], ["role", "status"]], null, null, null, null, null))], null, null); }
function View_SignInComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [["class", "input-group-text bg-info text-light border-success w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Username"])), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["usernameControl", 1]], null, 5, "input", [["class", "form-control"], ["placeholder", "Username"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.username = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(7, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 9, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "input-group-prepend w-33"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "label", [["class", "input-group-text bg-info text-light border-success w-100"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Password"])), (_l()(), i0.ɵeld(14, 0, null, null, 5, "input", [["autocomplete", "off"], ["class", "form-control"], ["placeholder", "Password"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(17, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(19, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(20, 0, null, null, 4, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 3, "button", [["class", "btn btn-success"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignInComponent_2)), i0.ɵdid(23, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Sign In "])), (_l()(), i0.ɵeld(25, 0, null, null, 1, "div", [["class", "alert alert-warning mt-2"]], [[2, "invisible", null]], null, null, null, null)), (_l()(), i0.ɵted(26, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.username; _ck(_v, 7, 0, currVal_7); var currVal_15 = _co.password; _ck(_v, 17, 0, currVal_15); var currVal_16 = _co.loading; _ck(_v, 23, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i0.ɵnov(_v, 19).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 19).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 19).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 19).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 19).ngClassValid; var currVal_13 = i0.ɵnov(_v, 19).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_17 = !_co.errorMessage; _ck(_v, 25, 0, currVal_17); var currVal_18 = _co.errorMessage; _ck(_v, 26, 0, currVal_18); }); }
export function View_SignInComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { usernameControl: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignInComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SignInComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "auth-sign-in", [], null, null, null, View_SignInComponent_0, RenderType_SignInComponent)), i0.ɵdid(1, 4243456, null, 0, i3.SignInComponent, [i4.Store], null, null)], null, null); }
var SignInComponentNgFactory = i0.ɵccf("auth-sign-in", i3.SignInComponent, View_SignInComponent_Host_0, { authState: "authState" }, {}, []);
export { SignInComponentNgFactory as SignInComponentNgFactory };
