/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./universal-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./universal-footer.component";
var styles_UniversalFooterComponent = [i0.styles];
var RenderType_UniversalFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UniversalFooterComponent, data: {} });
export { RenderType_UniversalFooterComponent as RenderType_UniversalFooterComponent };
export function View_UniversalFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2010-2019 "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "text-success coalesce"], ["href", "https://www.coalesceservices.com/coalesce-holdings"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Coalesce Holdings, LLC"])), (_l()(), i1.ɵted(-1, null, [" or its subsidiaries. All rights reserved. "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "a", [["class", "text-muted"], ["href", "https://agreements.coalescepayments.com/"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms of Service"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["class", "text-muted"], ["href", "http://status.coalescepayments.com/"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["System Status"]))], null, null); }
export function View_UniversalFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-universal-footer", [], null, null, null, View_UniversalFooterComponent_0, RenderType_UniversalFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.UniversalFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UniversalFooterComponentNgFactory = i1.ɵccf("app-universal-footer", i2.UniversalFooterComponent, View_UniversalFooterComponent_Host_0, {}, {}, []);
export { UniversalFooterComponentNgFactory as UniversalFooterComponentNgFactory };
