import { Component, Input, ViewChildren, QueryList } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-route-form',
  templateUrl: './route-form.component.html',
  styleUrls: ['./route-form.component.scss']
})
export class RouteFormComponent {
  @ViewChildren('idControl, midIdControl, merchantIdControl, officeIdControl, paymentTypeIdControl, transactionEnvironmentControl, externalMerchantClientControl, processorIdControl, processorFileCodeControl, processorMidControl, processorPasswordControl')
  controls: QueryList<any>

  @Input()
  fields: any[]
  
  @Input()
  roles: any
  
  @Input()
  permissions: any
  
  @Input()
  formGroup: FormGroup

  constructor() { }
  
  public reset() {
    this.controls.forEach( control => control.reset() )
  }


}
